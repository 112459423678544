import React, {Component} from "react";
import axios from "axios";
import {apiHost, getKey, logout, pageNotFound} from "./Utils";
import authHeader from "./services/auth-header";

export default class OldAgreementsListView extends Component {

    constructor() {
        super();
        this.state = {
            tableRows: [],
            showAddModal: false

        }
    }

    componentDidMount() {
        axios.get(apiHost + '/api/agreement/oldAgreement/list', authHeader())
            .then(result=>{
            if (result.status === 200) {
                let data = result.data.map(row=>{
                    return <tr key={getKey()}>
                        <td>{row.id}</td>
                        <td><a href={"/oaView?id=" + row.id}>{row.agreementNumber}</a></td>
                        <td>{row.agreementDate}</td>
                        <td>{row.organizationName}</td>
                        <td>{row.allAmount}</td>
                    </tr>
                });
                this.setState({
                    tableRows: data
                })
            }
        }).catch(error=>{
            if (error !== undefined && error !== null && error.response.status === 401) {
                logout();
            }
        })
    }

    openAddModal() {
        window.open("/addAgreement2", "_self")
    }

    render() {
        pageNotFound();
        return (
            <div className="container-fluid">
                <div className="card card-body">
                    <div className="form-row">
                        <div className="col-md-1">
                            <button className="btn btn-info" onClick={this.openAddModal.bind(this)}><i className="fa fa-plus-square-o"/></button>
                        </div>
                        <div className="col-md-11 text-center">
                            <h4>2021-yildan avvalgi shartnomalar</h4>
                        </div>
                    </div>
                    <div className="form-row mt-2">
                        <div className="col-md-12">
                            <table className="table table-striped table-bordered shadow">
                                <thead>
                                <tr>
                                    <th style={{width: "5%"}}>№</th>
                                    <th>Raqami</th>
                                    <th>Sanasi</th>
                                    <th>Tashkilot nomi</th>
                                    <th>Summasi</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.tableRows}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}