import React, {Component} from 'react';
import {apiHost, logout, pageNotFound} from "./Utils";
import swal from 'sweetalert';
import axios from 'axios';
import authHeader from "./services/auth-header";

export default class AddTendency2View extends Component {

	constructor(props) {
		super(props);
		this.state = {
			tendency: {
				id: null,
				topic: {
					id: 0,
					name: ''
				},
				name: '',
				price: '',
				certificateContent: ''
			}
		}
	}

	componentDidMount() {
		let urlParams = new URLSearchParams(window.location.search);
		let id = urlParams.get('id');
		if (id !== undefined && id !== null && id !== '' && Number(id) > 0) {
			axios.get(apiHost + '/api/admin/tendency/item?id=' + id, authHeader())
				.then(result => {
					if (result.status === 200) {
						this.setState({
							tendency: result.data
						})
					} else {
						swal(result.data.message, '', 'error');
					}
				}).catch(error=>{
				if (error !== undefined && error !== null && error.response.status === 401) {
					logout();
				}
			})
		}
	}

	saveTendency() {
		let topicId = 3;
		let nameBox = document.getElementById('name');
		let name = nameBox.options[nameBox.selectedIndex].text;
		let data = {
			'name': name,
			'topicId': topicId
		}
		if (this.state.tendency.id === null) {
			axios.post(apiHost + '/api/admin/tendency/add', data, authHeader())
				.then(result => {
					if (result.status === 200) {
						swal(result.data.message, '', 'success');
					} else {
						swal(result.data.message, '', 'error');
					}
				}).catch(error=>{
				if (error !== undefined && error !== null && error.response.status === 401) {
					logout();
				}
			})
		} else {
			data.append('id', this.state.tendency.id);
			axios.post(apiHost + '/api/admin/tendency/edit', data, authHeader())
				.then(result => {
					if (result.status === 200) {
						swal(result.data.message, '', 'success');
					} else {
						swal(result.data.message, '', 'error');
					}
				}).catch(error=>{
				if (error !== undefined && error !== null && error.response.status === 401) {
					logout();
				}
			})
		}
	}

	render() {
		pageNotFound();
		return(
			<div className="container card card-body my-4">
				<div className="row">
					<div className="col-md-12">
						<label htmlFor="name">Yo`nalish nomi</label>
						<select id="name" className="form-control">
							<option value={0}>Tanlang</option>
							<option value={1}>Qoraqalpog`iston Respublikasi Adliya vazirligi, viloyatlar va Toshkent shahar adliya boshqarmalari faoliyati</option>
							<option value={2}>O‘zbekiston Respublikasi Adliya vazirligining tuman (shahar) adliya bo‘limlari faoliyati</option>
							<option value={3}>O‘zbekiston Respublikasi Adliya vazirligi huzuridagi Davlat xizmatlari Agentligi va uning hududiy boshqarmalari faoliyati</option>
							<option value={4}>O‘zbekiston Respublikasi Adliya vazirligi huzuridagi Davlat xizmatlari Agentligining(tuman, shahar) Davlat xizmatlari markazlari xodimlari faoliyati</option>
							<option value={5}>O‘zbekiston Respublikasi Adliya vazirligi huzuridagi Davlat xizmatlari Agentligining(tuman, shahar) FHDYO bo`limlari faoliyati</option>

						</select>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12 text-center mt-3">
						<button className="btn btn-info" onClick={this.saveTendency.bind(this)}>Saqlash</button>
					</div>
				</div>
			</div>
		)
	}
}