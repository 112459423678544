import React, {Component} from "react";
import axios from "axios";
import {apiHost, logout, pageNotFound} from "./Utils";
import swal from 'sweetalert';
import 'react-responsive-modal/styles.css';
import Modal from 'react-responsive-modal';
import authHeader from "./services/auth-header";

export default class PayedAndUnGroupedListenersList extends Component {

    constructor() {
        super();
        this.state = {
            groups: [],
            listeners: [],
            tendencyId: null,
            listenerId: null,
            openAddGroupModal: false
        }
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        axios.get(apiHost + '/api/listener/payedUnGrouped/list', authHeader())
            .then(result => {
                let items = [];
                if (result.data !== null && result.data.length>0) {
                    items = result.data.map((row, index)=>{
                        return <tr key={'pu_' + index}><td>{index+1}</td><td>{row.lastName} {row.firstName} {row.middleName}</td>
                            <td>{row.passportNumber}</td><td>{row.phoneNumber}</td><td>{row.agreement.name}</td><td>{row.tendencyName}</td>
                            <td className="text-center"><button className="btn btn-success" onClick={this.openModal.bind(this, row)}><i className="fa fa-plus-square-o"/></button></td></tr>
                    });
                }
                this.setState({
                    listeners: items
                })
            }).catch(error=>{
            if (error !== undefined && error !== null && error.response.status === 401) {
                logout();
            }
        });
    }

    openModal(listener) {
        this.setState({
            openAddGroupModal: true,
            tendencyId: listener.position,
            listenerId: listener.id
        });
        this.getGroups(listener.position);
    }

    onCloseModal() {
        this.setState({
            openAddGroupModal: false
        })
    }

    getGroups(tendencyId) {
        axios.get(apiHost + '/api/course/unfilled/list?tendencyId=' + tendencyId, authHeader())
            .then(result => {
                let items = [];
                if (result.data !== null && result.data.length>0) {
                    items = result.data.map((row, index)=>{
                        return <option key={'opt_' + index} value={row.id}>{row.name}</option>
                    });
                }
                this.setState({
                    groups: items
                })
            }).catch(error=>{
            if (error !== undefined && error !== null && error.response.status === 401) {
                logout();
            }
        });
    }

    addListenerToGroup() {
        let groupId = document.getElementById('group').value;
        axios.post(apiHost + '/api/listener/addToGroup?listenerId=' + this.state.listenerId + '&groupId=' + groupId, null, authHeader())
            .then(result=>{
            if (result.status === 200) {
                swal(result.data.message, '', 'success');
                this.onCloseModal();
                this.getData();
            } else {
                swal(result.data.message, '', 'error');
            }
        }).catch(error=>{
            if (error !== undefined && error !== null && error.response.status === 401) {
                logout();
            }
        })
    }

    render() {
        pageNotFound();
        return (
            <div className="card card-body mt-1 shadow-sm">
                <div className="form-row">
                    <div className="col-md-12 order-md-1 pt-2"
                         style={{overflowY: "scroll", maxHeight: this.state.maxHeight + "px"}}>
                        <table className="table table-striped table-bordered shadow" style={{tableLayout: "fixed"}}>
                            <thead>
                            <tr>
                                <th style={{width: "5%"}}>№</th>
                                <th>F.I.Sh</th>
                                <th>Pasport raqami</th>
                                <th>Telefon raqami</th>
                                <th>Shartnoma raqami</th>
                                <th>Yo`nalishi</th>
                                <th style={{width: "7%"}}>Amal</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.listeners}
                            </tbody>
                        </table>
                    </div>
                </div>
                <Modal open={this.state.openAddGroupModal} onClose={this.onCloseModal.bind(this)} center>
                    <div style={{padding: "20px", minWidth: "200px"}}>
                        <div className="form-row">
                            <div className="col-md-12">
                                <label htmlFor="group">Guruh</label>
                                <select className="form-control" id="group">
                                    {this.state.groups}
                                </select>
                            </div>
                        </div>
                        <div className="form-row mt-2">
                            <div className="col-md-12 text-center">
                                <button className="btn btn-success" onClick={this.addListenerToGroup.bind(this)}>Saqlash
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}