import React, {Component} from 'react';
import * as _ from './Utils';
import axios from 'axios';
import swal from "sweetalert";
import {apiHost, logout, pageNotFound} from "./Utils";
import authHeader from "./services/auth-header";

export default class AgreementView extends Component {

	constructor(props) {
		super(props);
		this.state = {
			agreement: {
				region: {
					name: ''
				},
				tendency: {
					name: ''
				}
			},
			payedAmount: '',
			agreementId: null,
			requisites: [],
			listeners: [],
			images: []
		}
	}

	componentDidMount() {
		const agreementId = localStorage.getItem('agreementId');
		this.setState({
			agreementId: agreementId
		});
		axios.get(_.apiHost + '/api/agreement/get?id=' + agreementId, authHeader())
			.then(result => {
				if (result.status === 200) {
					if (result.data !== undefined && result.data !== null) {
						let index = 0;
						let lines = result.data.requisites.map(req=>{
							index++;
							return <div key={'req' + req.id} className={index === 1 || index === 2 ? "col-md-12 mb-2" : "col-md-6 mb-2"}>
								<label className="form-control">{req.requisiteValue}</label>
							</div>
						});
						lines.push(<div key={'req' + 100} className="col-md-6 mb-2">
							<label className="form-control">{result.data.phoneNumber}</label>
						</div>);
						index = 0;
						let listeners = result.data.listeners.map(req=>{
							index++;
							return <tr key={'tr' + req.id} className="col-md-6 mb-2">
								<td>{req.id}</td>
								<td>{req.lastName + ' ' + req.firstName + ' ' + req.middleName}</td>
								<td>{req.position}</td>
								<td><button className="btn btn-danger" onClick={this.removeListener.bind(this, req.id)}><i className="fa fa-remove"/></button></td>
							</tr>
						});
						index = 0;
						let images = result.data.files.map(req=>{
							index++;
							return <div className="ml-5 mb-2">
								{req.name.endsWith('.pdf') ? <a href={apiHost + "/files/agreementFiles/" + agreementId + "/" + req.name}>{req.name}</a> : <img style={{width: "200px"}} src={apiHost + "/files/agreementFiles/" + agreementId + "/" + req.name} alt=""/>}
							</div>
						});
						this.setState({
							agreement: result.data,
							requisites: lines,
							listeners: listeners,
							images: images,
							payedAmount: result.data.payedAmount
						});
						localStorage.setItem("agreement", JSON.stringify(result.data));
					}
				}
			}).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		})
	}

    removeListener(listenerId) {
        axios.post(_.apiHost + '/api/listener/removeListener?listenerId=' + listenerId, null, authHeader())
            .then(result => {
                if (result.status === 200) {
                    swal(result.data.message, "", "success");
                    window.location.reload();
                } else if (result.status === 500) {
                    swal(result.data.message, "", "error");
                }
            }).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		})
	}

	save() {
		axios.post(_.apiHost + '/api/agreement/updateAmount',
			{
				"id": this.state.agreementId,
				"payedAmount": this.state.payedAmount
			}, authHeader())
			.then(result => {
				if (result.status === 200) {
					swal(result.data.message, "", "success");
				} else if (result.status === 500) {
					swal(result.data.message, "", "error");
				}
			}).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		})
	}

	saveSolution() {
		let solution = document.getElementById('solution').value;
		let registerNumber = document.getElementById('registerNumber').value;
		axios.post(_.apiHost + '/api/agreement/saveSolution?id=' + this.state.agreementId + '&solution=' + solution +
			'&registerNumber=' + registerNumber, null, authHeader())
			.then(result => {
				if (result.status === 200) {
					swal(result.data.message, "", "success");
				} else if (result.status === 500) {
					swal(result.data.message, "", "error");
				}
			}).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		})
	}

	onChangePercent() {
		let payedAmount = Number(document.getElementById('payedAmount').value);
		this.setState({
			payedAmount: this.state.agreement.allAmount * payedAmount / 100
		})
	}

	onChangeAmount(e) {
		let payedAmount = e.target.value;
		this.setState({
			payedAmount: payedAmount
		})
	}

	render() {
		pageNotFound();
		let user = JSON.parse(localStorage.getItem('user'));
		return (
			<div className="content">
                <div className="container card card-body my-4">
	                <div className="row">
		                <div className="col-md-12 mb-3 text-center">
			                <h3>Shartnoma ma'lumotlari</h3>
		                </div>
	                </div>
	                <div className="row">
		                <div className="col-md-12 order-md-1 mb-2">
			                <div className="form-row">
				                <div className="col-md-2">
					                <label>Vaqti</label>
					                <label className="form-control">{this.state.agreement.agreementDate}</label>
				                </div>
				                <div className="col-md-2">
					                <label>Raqami</label>
					                <label className="form-control">{this.state.agreement.agreementNumber}</label>
				                </div>
				                <div className="col-md-4">
					                <label>Hudud</label>
					                <label className="form-control">{this.state.agreement.region.name}</label>
				                </div>
				                <div className="col-md-4">
					                <label>Tashkilot nomi</label>
					                <label className="form-control">{this.state.agreement.organizationName}</label>
				                </div>
			                </div>
			                <div className="form-row">
				                <div className="col-md-4 mb-3">
					                <label>Tashkilot rahbari</label>
					                <label className="form-control">{this.state.agreement.organizationDirector}</label>
				                </div>
				                <div className="col-md-4 mb-2">
					                <label htmlFor="tendency">Yo`nalishi</label>
					                <label className="form-control">{this.state.agreement.tendency.name}</label>
				                </div>
				                <div className="col-md-4 mb-2">
					                <label htmlFor="tendency">Necha kishi o`qitiladi</label>
					                <label className="form-control">{this.state.agreement.pupilCount}</label>
				                </div>
			                </div>

			                <div className="form-row">
				                <div className="col-md-6 mb-2">
					                <label><b>Rekvizitlar</b></label>
					                <div className="form-row">
						                {this.state.requisites}
				                    </div>
				                </div>
				                {user.roles[0] === 'ACCOUNTANT' &&
				                <div className="col-md-6 mb-2">
					                <div className="form-row mb-2 text-right">
						                <div className="col-md-12 mr-5">
							                <label><b>To`langan summani kiritish</b></label>
						                </div>
					                </div>
					                <div className="form-row mb-2">
						                <div className="col-md-5">

						                </div>
						                <div className="col-md-2">
							                <label htmlFor="payedAmount">%</label>
							                <select id="payedAmount" className="form-control" onChange={this.onChangePercent.bind(this)}>
								                <option value={0}>0%</option>
								                <option value={30}>30%</option>
								                <option value={70}>70%</option>
								                <option value={100}>100%</option>
							                </select>
						                </div>
					                    <div className="col-md-3">
						                    <label htmlFor="payedAmountSum">Summa</label>
						                    <input id="payedAmountSum" className="form-control" value={this.state.payedAmount} onChange={this.onChangeAmount.bind(this)}/>
					                    </div>
						                <div className="col-md-1">
							                <label>&nbsp;</label>
							                <button className="btn btn-success" onClick={this.save.bind(this)}>Saqlash</button>
						                </div>
					                </div>
				                </div>
				                }

				                <div className="col-md-6 mb-2">
				                    <div className="form-row">
						                <div className="col-md-12 mb-2 text-center">
							                <label><b>Tinglovchilar ro`yxati</b></label>
							                <table className="table table-striped table-bordered shadow">
								                <thead>
									                <tr>
										                <td>№</td>
										                <td>F.I.Sh</td>
										                <td>Lavozimi</td>
										                <td>Amal</td>
									                </tr>
								                </thead>
								                <tbody>
								                {this.state.listeners}
								                </tbody>
							                </table>
						                </div>
					                </div>
				                </div>
			                </div>
		                </div>
	                </div>
	                <div className="row">
		                <div className="row">
			                {this.state.images}
		                </div>
	                </div>
                </div>
			</div>
		)
	}
}