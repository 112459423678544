import React, {Component} from "react";
import axios from "axios";
import {apiHost, getKey, logout, pageNotFound} from "./Utils";
import Modal from "react-responsive-modal";
import swal from "sweetalert";
import authHeader from "./services/auth-header";

export default class OldListenersListView extends Component {

    constructor() {
        super();
        this.state = {
            tableRows: [],
            groups: [],
            group: null,
            listener: null,
            addToGroupModal: false
        };
        this.setValue = this.setValue.bind(this);
    }

    componentDidMount() {
        this.getData();
        axios.get(apiHost + '/api/course/list?type=paid', authHeader())
            .then(result => {
            let items = [];
            if (result.data !== null && result.data.length>0) {
                items = result.data.map(item=>{
                    return <option key={'t_' + item.id} value={item.id}>{item.name}</option>
                });
                items.unshift(<option key={'t_' + 0} value="0">Hammasi</option>);
            }
            this.setState({
                groups: items
            })
        }).catch(error=>{
            if (error !== undefined && error !== null && error.response.status === 401) {
                logout();
            }
        });
    }

    getData() {
        axios.get(apiHost + '/api/listener/oldListener/list', authHeader()).then(result=>{
            if (result.status === 200) {
                let rows = result.data.map(row=>{
                    return <tr key={getKey()}>
                        <td className="text-center">{row.id}</td>
                        <td>{row.lastName + ' ' + row.firstName + ' ' + row.middleName}</td>
                        <td>{row.passportNumber}</td>
                        <td>{row.phoneNumber}</td>
                        <td>{row.position}</td>
                        <td>{row.group.name}</td>
                        <td>{row.certificateNumber}</td>
                        <td>{row.group.name === null || row.group.name === '' ?
                            <button className="btn btn-success" title="Guruhga qo`shish"
                                    onClick={this.onOpenModal.bind(this, row)}><i className="fa fa-plus"/></button>
                                : <span>&nbsp;</span>
                            }
                            &nbsp;
                            {(row.certificateNumber === null || row.certificateNumber === '') &&
                                <button className="btn btn-primary" onClick={this.generateCertificate.bind(this, row.id)}>
                                    <i className="fa fa-address-card-o linkStyle ml-1" title="Sertifikat berish"/>
                                </button>
                            }
                            &nbsp;
                            {(row.certificateNumber !== null && row.certificateNumber !== '') &&
                                <button className="btn btn-primary mr-1" title="Sertifikatni tekshirish" onClick={this.checkCertificate.bind(this, row)}>
                                    <i className="fa fa-download"/>
                                </button>
                            }
                        </td>
                    </tr>
                });
                this.setState({
                    tableRows: rows
                })
            }
        }).catch(error=>{
            if (error !== undefined && error !== null && error.response.status === 401) {
                logout();
            }
        });
    }

    generateCertificate(listenerId) {
        axios.post(apiHost + '/api/listener/generateCertificate?listenerId=' + listenerId, null, authHeader()).then(result => {
            if (result.status === 200) {
                this.getData();
                swal(result.data.message, "", "success");
            } else if (result.status === 500) {
                swal(result.data.message, "", "error");
            }
        }).catch(error=>{
            if (error !== undefined && error !== null && error.response.status === 401) {
                logout();
            }
        })
    }

    checkCertificate(row) {
        window.open(apiHost + '/files/certificates/' + row.certificateNumber + '_' + row.id + '.pdf', '_blank');
    }

    onChangeValue() {

    }

    setValue(e) {
        let name = e.target.id;
        let value = e.target.value;
        this.setState({
            [name]: value
        })
    }

    search() {

    }

    addToGroup() {
        axios.post(apiHost + '/api/listener/changeGroup?listenerId=' + this.state.listener.id + '&groupId=' +
            this.state.group, null, authHeader()).then(result => {
            if (result.status === 200) {
                this.getData();
                this.onCloseModal();
                swal(result.data.message, "", "success");
            } else if (result.status === 500) {
                swal(result.data.message, "", "error");
            }
        }).catch(error=>{
            if (error !== undefined && error !== null && error.response.status === 401) {
                logout();
            }
        })
    }

    onOpenModal(listener) {
        this.setState({
            listener: listener,
            addToGroupModal: true
        })
    }

    onCloseModal() {
        this.setState({
            addToGroupModal: false
        })
    }

    render() {
        pageNotFound();
        return (
            <div className="container-fluid">
                <div className="card card-body">
                    <div className="form-row">
                        <div className="col-md-9 text-center">
                            <h4>2021-yildan avvalgi tinglovchilar</h4>
                        </div>
                        <div className="col-md-3">
                            <form className="form-inline float-right">
                                <div className="input-group">
                                    <input className="form-control" id="searchBox" placeholder="Qidiruv so‘zi" aria-label="Qidirish" aria-describedby="basic-addon2" onChange={this.onChangeValue.bind(this)}/>
                                    <div className="input-group-append">
                                        <button className="btn btn-outline-info" type="button" onClick={this.search.bind(this)}><i className="fa fa-search"/></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-12">
                            <table className="table table-striped table-bordered shadow">
                                <thead>
                                <tr>
                                    <th style={{width: "5%"}}>№</th>
                                    <th>F.I.Sh</th>
                                    <th>Pasport raqami</th>
                                    <th>Telefon raqami</th>
                                    <th>Ish joyi, lavozimi</th>
                                    <th>Guruhi</th>
                                    <th>Sertifikat raqami</th>
                                    <th>Amal</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.tableRows}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <Modal open={this.state.addToGroupModal} onClose={this.onCloseModal.bind(this)} center>
                    <div style={{padding: "20px", minWidth: "200px"}}>
                        <div className="form-row">
                            <div className="col-md-12">
                                <label htmlFor="listenerName">Tinglovchi</label>
                                <input className="form-control" disabled={true} id="listenerName"
                                       value={this.state.listener !== null ? this.state.listener.lastName + ' ' + this.state.listener.firstName + ' ' +  this.state.listener.middleName : ''}/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-md-12">
                                <label htmlFor="group">Yangi guruhi</label>
                                <select className="form-control" id="group" onChange={this.setValue}>
                                    {this.state.groups}
                                </select>
                            </div>
                        </div>
                        <div className="form-row mt-2">
                            <div className="col-md-12 text-center">
                                <button className="btn btn-success" onClick={this.addToGroup.bind(this)}>Saqlash</button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}