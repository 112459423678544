import React, {Component} from 'react';
import {logout, pageNotFound} from './Utils';
import GroupListView from "./GroupListView";

export default class MainControllerHomeView extends Component {

	render() {
		pageNotFound();
		let user = JSON.parse(localStorage.getItem('user'));
		if (!user.roles.includes('MAIN_CONTROLLER')) {
			logout();
		}
		return (
			<GroupListView/>
		)
	}
}