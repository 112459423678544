import React, {Component} from 'react';
import * as _ from './Utils';

export default class ListenerItem extends Component {

	render() {
		return (
			<div>
				<table id="listenerTable" width={"100%"}>
					{Number(this.props.rowId.replaceAll('lc_', '')) <= 2 &&
					<thead>
					<tr style={{textAlign: "center"}}>
						<td><b>Familiyasi</b></td>
						<td><b>Ismi</b></td>
						<td><b>Sharifi</b></td>
						<td><b>Lavozimi</b></td>
						<td><b>Telefon raqami</b></td>
						<td><b>Pasport seriyasi va raqami</b></td>
						<td><b>JShShIR</b></td>
						<td>&nbsp;</td>
					</tr>
					</thead>
					}
					<tbody>
						<tr>
							<td><input id={"lastName" + this.props.rowId} className="form-control" placeholder="Familiyasi" onChange={_.validateOnlyTextField}/></td>
							<td><input id={"firstName" + this.props.rowId} className="form-control" placeholder="Ismi" onChange={_.validateOnlyTextField}/></td>
							<td><input id={"middleName" + this.props.rowId} className="form-control" placeholder="Sharifi" onChange={_.validateOnlyTextField}/></td>
							<td><input id={"position" + this.props.rowId}  className="form-control" placeholder="Lavozimi" onChange={_.validateOnlyTextField}/></td>
							<td><input id={"phoneNumber" + this.props.rowId} maxLength="12" placeholder="998970000000" className="form-control" onChange={_.validateOnlyNumberField}/></td>
							<td><input id={"passportNumber" + this.props.rowId} maxLength="10" placeholder="AA0000000" className="form-control" onChange={_.validateOnlyTextNumberField}/></td>
							<td><input id={"pinflNumber" + this.props.rowId} maxLength="14" className="form-control" onChange={_.validateOnlyTextNumberField}/></td>
							<td>
								<div style={{display: "grid"}}>
									<span className="item-btn" onClick={this.props.addRow}>+</span>
									<span className="item-btn" onClick={this.props.removeRow}>-</span>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
				<div className="row mt-1">
					<div className="col-md-12"/>
				</div>
			</div>
		)
	}
}