import React, {Component} from 'react';
import {apiHost, logout, pageNotFound} from "./Utils";
import swal from 'sweetalert';
import axios from 'axios';
import authHeader from "./services/auth-header";

export default class PdfPageSettingView extends Component {

    constructor() {
        super();
        this.state = {
            director: '',
            contentFontSize: 16,
            footerFontSize: 18,
            mediatorHours: 40
        }
    }

    componentDidMount() {
        axios.get(apiHost + '/api/admin/settings', authHeader()).then(result=>{
            if (result.status === 200) {
                this.setState({
                    director: result.data.director,
                    contentFontSize: Number(result.data.contentFontSize),
                    footerFontSize: Number(result.data.footerFontSize),
                    mediatorHours: Number(result.data.mediatorHours)
                })
            }
        }).catch(error=>{
            if (error !== undefined && error !== null && error.response.status === 401) {
                logout();
            }
        })
    }

    save() {
        let contentFontSize = document.getElementById('contentFontSize').value;
        let footerFontSize = document.getElementById('footerFontSize').value;
        axios.post(apiHost + '/api/admin/pdf/setup?contentFontSize=' + contentFontSize + '&footerFontSize=' +
            footerFontSize, null, authHeader()).then(result => {
            if (result.status === 200) {
                swal(result.data.message, '', 'success');
            } else {
                swal(result.data.message, '', 'error');
            }
        }).catch(error=>{
            if (error !== undefined && error !== null && error.response.status === 401) {
                logout();
            }
        })
    }

    saveMediatorHours() {
        let mediatorHours = document.getElementById('mediatorHours').value;
        axios.post(apiHost + '/api/admin/pdf/mediatorHours?hours=' + mediatorHours, null, authHeader()).then(result => {
            if (result.status === 200) {
                swal(result.data.message, '', 'success');
            } else {
                swal(result.data.message, '', 'error');
            }
        }).catch(error=>{
            if (error !== undefined && error !== null && error.response.status === 401) {
                logout();
            }
        })
    }

    saveDirector() {
        let director = document.getElementById('director').value;
        if (director === null || director === '') {
            swal('Markaz direktorining F.I.Shni kiriting', '', 'error');
        }
        axios.post(apiHost + '/api/admin/updateDirector?director=' + director, null, authHeader())
            .then(result => {
                if (result.status === 200) {
                    swal(result.data.message, '', 'success');
                } else {
                    swal(result.data.message, '', 'error');
                }
            }).catch(error=>{
            if (error !== undefined && error !== null && error.response.status === 401) {
                logout();
            }
        })
    }

    setValue(e) {
        let id = e.target.id;
        let value = e.target.value;
        this.setState({
            [id]: [value]
        })
    }

    render() {
        pageNotFound();
        return (
            <div className="container">
                <div className="card card-body">
                    <fieldset>
                        <legend>Sertifikat shrifti o`lchami</legend>
                        <div className="form-row">
                            <div className="col-md-6">
                                <label htmlFor="contentFontSize">Sertifikat matni shrifti o`lchami</label>
                                <input type="number" id="contentFontSize" className="form-control" value={this.state.contentFontSize}
                                       onChange={this.setValue.bind(this)}/>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="footerFontSize">Sertifikat quyi qismi shrifti o`lchami</label>
                                <input type="number" id="footerFontSize" className="form-control" value={this.state.footerFontSize}
                                       onChange={this.setValue.bind(this)}/>
                            </div>
                        </div>

                        <div className="form-row mt-3">
                            <div className="col-md-12 text-center">
                                <button className="btn btn-success" onClick={this.save.bind(this)}><i className="fa fa-save"/>&nbsp;Saqlash</button>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend>Mediator sertifikati soati</legend>
                        <div className="form-row">
                            <div className="col-md-3">
                                <label htmlFor="mediatorHours">Akademik soat</label>
                                <input type="number" id="mediatorHours" className="form-control" value={this.state.mediatorHours}
                                       onChange={this.setValue.bind(this)}/>
                            </div>

                            <div className="col-md-3 text-right">
                                <button className="btn btn-success" onClick={this.saveMediatorHours.bind(this)}>
                                    <i className="fa fa-save"/>&nbsp;Saqlash</button>
                            </div>
                        </div>
                    </fieldset>

                    <fieldset>
                        <legend>Markaz direktori</legend>
                        <div className="form-row">
                            <div className="col-md-12">
                                <label htmlFor="director">Markaz direktorning F.I.Sh</label>
                                <input id="director" className="form-control" value={this.state.director} onChange={this.setValue.bind(this)}/>
                            </div>
                        </div>
                        <div className="form-row mt-3">
                            <div className="col-md-12 text-center">
                                <button className="btn btn-success" onClick={this.saveDirector.bind(this)}><i className="fa fa-save"/>&nbsp;Saqlash</button>
                            </div>
                        </div>
                    </fieldset>
                </div>
            </div>
        )
    }
}