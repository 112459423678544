import React, {Component} from 'react';
import axios from 'axios';
import swal from "sweetalert";
import {apiHost, getKey, logout, pageNotFound} from "./Utils";
import authHeader from "./services/auth-header";

export default class OldAgreementView extends Component {

	constructor(props) {
		super(props);
		this.state = {
			agreement: {
				region: {
					name: ''
				},
				tendency: {
					name: ''
				}
			},
			allAmount: '',
			agreementId: null,
			requisites: [],
			listeners: [],
			images: []
		}
	}

	componentDidMount() {
		let url = new URL(document.location.href);
		let agreementId = url.searchParams.get("id");
		this.setState({
			agreementId: agreementId
		});
		axios.get(apiHost + '/api/agreement/get?id=' + agreementId, authHeader())
			.then(result => {
				if (result.status === 200) {
					if (result.data !== undefined && result.data !== null) {
						let listeners = result.data.listeners.map(req=>{
							return <tr key={'tr' + req.id} className="col-md-6 mb-2">
								<td>{req.id}</td>
								<td>{req.lastName + ' ' + req.firstName + ' ' + req.middleName}</td>
								<td>{req.position}</td>
								<td>{req.passportNumber}</td>
								<td>{req.phoneNumber}</td>
								<td>
									<button className="btn btn-danger" title="O`chirish" onClick={this.removeOldListener.bind(this, req.id)}>
										<i className="fa fa-remove"/>
									</button>
								</td>
							</tr>
						});

						let images = result.data.files.map(req=>{
							return <div className="ml-5 mb-2" key={getKey()}>
								{req.name.endsWith('.pdf') ? <a href={apiHost + "/files/agreementFiles/" + agreementId + "/" + req.name}>{req.name}</a> :
									<img style={{width: "200px"}} src={apiHost + "/files/agreementFiles/" + agreementId + "/" + req.name} alt=""/>}
							</div>
						});
						this.setState({
							agreement: result.data,
							listeners: listeners,
							images: images,
						});
					}
				}
			}).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		})
	}

	removeOldListener(listenerId) {
		axios.post(apiHost + '/api/listener/removeListener?listenerId=' + listenerId, null, authHeader())
			.then(result => {
			if (result.status === 200) {
				swal(result.data.message, "", "success");
				window.location.reload();
			} else if (result.status === 500) {
				swal(result.data.message, "", "error");
			}
		}).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		})
	}

	render() {
		pageNotFound();
		return (
			<div className="content">
                <div className="container card card-body my-4">
	                <div className="row">
		                <div className="col-md-12 mb-3 text-center">
			                <h3>Shartnoma ma'lumotlari</h3>
		                </div>
	                </div>
	                <div className="row">
		                <div className="col-md-12 order-md-1 mb-2">
			                <div className="form-row">
				                <div className="col-md-2">
					                <label>Vaqti</label>
					                <label className="form-control">{this.state.agreement.agreementDate}</label>
				                </div>
				                <div className="col-md-2">
					                <label>Raqami</label>
					                <label className="form-control">{this.state.agreement.agreementNumber}</label>
				                </div>
				                <div className="col-md-4">
					                <label>Hudud</label>
					                <label className="form-control">{this.state.agreement.region.name}</label>
				                </div>
				                <div className="col-md-4">
					                <label>Tashkilot nomi</label>
					                <label className="form-control">{this.state.agreement.organizationName}</label>
				                </div>
			                </div>
			                <div className="form-row">
				                <div className="col-md-2 mb-2">
					                <label htmlFor="tendency">Summasi</label>
					                <label className="form-control">{this.state.agreement.allAmount}</label>
				                </div>
				                <div className="col-md-2 mb-2">
					                <label htmlFor="tendency">Necha kishi o`qitiladi</label>
					                <label className="form-control">{this.state.agreement.pupilCount}</label>
				                </div>
				                <div className="col-md-8 mb-2">
					                <label>Yo`nalishi</label>
					                <label className="form-control">{this.state.agreement.tendency !== null ? this.state.agreement.tendency.name : ''}</label>
				                </div>
			                </div>

			                <div className="form-row">
				                <div className="col-md-12 mb-2">
				                    <div className="form-row">
						                <div className="col-md-12 mb-2 text-center">
							                <label><b>Tinglovchilar ro`yxati</b></label>
							                <table className="table table-striped table-bordered shadow">
								                <thead>
									                <tr>
										                <td>№</td>
										                <td>F.I.Sh</td>
										                <td>Lavozimi</td>
										                <td>Pasport raqami</td>
										                <td>Telefon raqami</td>
										                <td>Amal</td>
									                </tr>
								                </thead>
								                <tbody>
								                {this.state.listeners}
								                </tbody>
							                </table>
						                </div>
					                </div>
				                </div>
			                </div>
		                </div>
	                </div>
	                <div className="row">
		                <div className="row">
			                {this.state.images}
		                </div>
	                </div>
                </div>
			</div>
		)
	}
}