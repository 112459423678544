import React, {Component} from 'react';
import axios from 'axios';
import * as _ from './Utils';
import { Base64 } from 'js-base64';
import authHeader from "./services/auth-header";
import {logout, pageNotFound} from "./Utils";

const EIMZO_MAJOR = 3;
const EIMZO_MINOR = 37;
const errorCAPIWS = 'Ошибка соединения с E-IMZO. Возможно у вас не установлен модуль E-IMZO или Браузер E-IMZO.';
const errorBrowserWS = 'Браузер не поддерживает технологию WebSocket. Установите последнюю версию браузера.';
const errorUpdateApp = 'ВНИМАНИЕ !!! Установите новую версию приложения E-IMZO или Браузера E-IMZO.<br /><a href="https://e-imzo.uz/main/downloads/" role="button">Скачать ПО E-IMZO</a>';
const errorWrongPassword = 'Пароль неверный.';

const CAPIWS = {
	URL: (window.location.protocol.toLowerCase() === "https:" ? "wss://127.0.0.1:64443" : "ws://127.0.0.1:64646") + "/service/cryptapi",
	callFunction: function(funcDef, callback, error){
		if (!window.WebSocket){
			if(error)
				error();
			return;
		}
		var socket;
		try{
			socket = new WebSocket(this.URL);
		} catch (e){
			error(e);
		}
		socket.onerror = function(e){
			if(error)
				error(e);
		};
		socket.onmessage = function(event){
			var data = JSON.parse(event.data);
			socket.close();
			callback(event,data);
		};
		socket.onopen = function(){
			socket.send(JSON.stringify(funcDef));
		};
	},
	version: function(callback, error){
		if (!window.WebSocket){
			if(error)
				error();
			return;
		}
		var socket;
		try{
			socket = new WebSocket(this.URL);
		} catch (e){
			error(e);
		}
		socket.onerror = function(e){
			if(error)
				error(e);
		};
		socket.onmessage = function(event){
			var data = JSON.parse(event.data);
			socket.close();
			callback(event,data);
		};
		socket.onopen = function(){
			var o = {name: 'version'};
			socket.send(JSON.stringify(o));
		};
	},
	apidoc: function(callback, error){
		if (!window.WebSocket){
			if(error)
				error();
			return;
		}
		var socket;
		try{
			socket = new WebSocket(this.URL);
		} catch (e){
			error(e);
		}
		socket.onerror = function(e){
			if(error)
				error(e);
		};
		socket.onmessage = function(event){
			var data = JSON.parse(event.data);
			socket.close();
			callback(event,data);
		};
		socket.onopen = function(){
			var o = {name: 'apidoc'};
			socket.send(JSON.stringify(o));
		};
	},
	apikey: function(domainAndKey, callback, error){
		if (!window.WebSocket){
			if(error)
				error();
			return;
		}
		var socket;
		try{
			socket = new WebSocket(this.URL);
		} catch (e){
			error(e);
		}
		socket.onerror = function(e){
			if(error)
				error(e);
		};
		socket.onmessage = function(event){
			var data = JSON.parse(event.data);
			socket.close();
			callback(event,data);
		};
		socket.onopen = function(){
			var o = {name: 'apikey', arguments: domainAndKey};
			socket.send(JSON.stringify(o));
		};
	}
}

const EIMZOClient = {
	NEW_API: false,
	NEW_API2: false,
	API_KEYS: [
	'localhost', '96D0C1491615C82B9A54D9989779DF825B690748224C2B04F500F370D51827CE2644D8D4A82C18184D73AB8530BB8ED537269603F61DB0D03D2104ABF789970B',
	'127.0.0.1', 'A7BCFA5D490B351BE0754130DF03A068F855DB4333D43921125B9CF2670EF6A40370C646B90401955E1F7BC9CDBF59CE0B2C5467D820BE189C845D0B79CFC96F',
	'elektron.uzmarkaz.uz',	'2F1B22DDEED143F684BEA46D3C69A4BEF5FCF0066978510065148B7C13BAAFB3C0F1CC6421A049F0CC2FBB36ADE402FAB1E3623C4E3F3C5D5E4A1AB3E62C5144'
],
	checkVersion: function(success, fail){
	CAPIWS.version(function (event, data) {
		if(data.success === true){
			if(data.major && data.minor){
				var installedVersion = parseInt(data.major) * 100 + parseInt(data.minor);
				EIMZOClient.NEW_API = installedVersion >= 336;
				EIMZOClient.NEW_API2 = installedVersion >= 412;
				success(data.major, data.minor);
			} else {
				fail(null, 'E-IMZO Version is undefined');
			}
		} else {
			fail(null, data.reason);
		}
	}, function (e) {
		fail(e, null);
	});
},
	installApiKeys: function(success, fail){
		CAPIWS.apikey(EIMZOClient.API_KEYS, function (event, data) {
			if (data.success) {
				success();
			} else {
				fail(null,data.reason);
			}
		}, function (e) {
			fail(e, null);
		});
	},
	listAllUserKeys: function(itemIdGen, itemUiGen, success, fail){
		var items = [];
		var errors = [];
		if(!EIMZOClient.NEW_API){
			fail(null, 'Please install new version of E-IMZO');
		} else {
			if(EIMZOClient.NEW_API2){
				EIMZOClient._findPfxs2(itemIdGen, itemUiGen, items, errors, function (firstItmId2) {
					if(items.length === 0 && errors.length > 0){
						fail(errors[0].e, errors[0].r);
					} else {
						var firstId = null;
						if (items.length === 1) {
							if (firstItmId2) {
								firstId = firstItmId2;
							}
						}
						success(items, firstId);
					}
				});
			} else {
				EIMZOClient._findPfxs2(itemIdGen, itemUiGen, items, errors, function (firstItmId2) {
					EIMZOClient._findTokens2(itemIdGen, itemUiGen, items, errors, function (firstItmId3) {
						if(items.length === 0 && errors.length > 0){
							fail(errors[0].e, errors[0].r);
						} else {
							var firstId = null;
							if (items.length === 1) {
								if (firstItmId2) {
									firstId = firstItmId2;
								} else if (firstItmId3) {
									firstId = firstItmId3;
								}
							}
							success(items, firstId);
						}
					});
				});
			}
		}
	},
	idCardIsPLuggedIn: function(success, fail){
		if(!EIMZOClient.NEW_API2){
			console.log("E-IMZO version should be 4.12 or newer")
		} else {
			CAPIWS.callFunction({plugin: "idcard", name: "list_readers"}, function (event, data) {
				if (data.success) {
					success(data.readers.length>0);
				} else {
					fail(null, data.reason);
				}
			}, function (e) {
				fail(e, null);
			});
		}
	},
	loadKey: function(itemObject, success, fail, verifyPassword){
		if (itemObject) {
			var vo = itemObject;
			if (vo.type === "pfx") {
				CAPIWS.callFunction({plugin: "pfx", name: "load_key", arguments: [vo.disk, vo.path, vo.name, vo.alias]}, function (event, data) {
					if (data.success) {
						var id = data.keyId;
						if(verifyPassword){
							CAPIWS.callFunction({name: "verify_password", plugin: "pfx", arguments: [id]}, function (event, data) {
								if (data.success) {
									success(id);
								} else {
									fail(null, data.reason);
								}
							}, function (e) {
								fail(e, null);
							});
						} else {
							success(id);
						}
					} else {
						fail(null, data.reason);
					}
				}, function (e) {
					fail(e, null);
				});
			} else if (vo.type === "ftjc") {
				CAPIWS.callFunction({plugin: "ftjc", name: "load_key", arguments: [vo.cardUID]}, function (event, data) {
					if (data.success) {
						var id = data.keyId;
						if(verifyPassword){
							CAPIWS.callFunction({plugin: "ftjc", name: "verify_pin", arguments: [id,'1']}, function (event, data) {
								if (data.success) {
									success(id);
								} else {
									fail(null, data.reason);
								}
							}, function (e) {
								fail(e, null);
							});
						} else {
							success(id);
						}
					} else {
						fail(null, data.reason);
					}
				}, function (e) {
					fail(e, null);
				});
			}
		}
	},
	changeKeyPassword: function(itemObject, success, fail){
		if (itemObject) {
			var vo = itemObject;
			if (vo.type === "pfx") {
				CAPIWS.callFunction({plugin: "pfx", name: "load_key", arguments: [vo.disk, vo.path, vo.name, vo.alias]}, function (event, data) {
					if (data.success) {
						var id = data.keyId;
						CAPIWS.callFunction({name: "change_password", plugin: "pfx", arguments: [id]}, function (event, data) {
							if (data.success) {
								success();
							} else {
								fail(null, data.reason);
							}
						}, function (e) {
							fail(e, null);
						});
					} else {
						fail(null, data.reason);
					}
				}, function (e) {
					fail(e, null);
				});
			} else if (vo.type === "ftjc") {
				CAPIWS.callFunction({plugin: "ftjc", name: "load_key", arguments: [vo.cardUID]}, function (event, data) {
					if (data.success) {
						var id = data.keyId;
						CAPIWS.callFunction({name: "change_pin", plugin: "ftjc", arguments: [id, '1']}, function (event, data) {
							if (data.success) {
								success();
							} else {
								fail(null, data.reason);
							}
						}, function (e) {
							fail(e, null);
						});
					} else {
						fail(null, data.reason);
					}
				}, function (e) {
					fail(e, null);
				});
			}
		}
	},
	createPkcs7: function(id, data, timestamper, success, fail, detached, isDataBase64Encoded){
		var data64;
		if(isDataBase64Encoded === true){
			data64 = data
		}else {
			data64 = Base64.encode(data);
		}
		if(detached === true){
			detached = 'yes';
		} else {
			detached = 'no';
		}
		CAPIWS.callFunction({plugin: "pkcs7", name: "create_pkcs7", arguments: [data64, id, detached]}, function (event, data) {
			if (data.success) {
				var pkcs7 = data.pkcs7_64;
				if(timestamper){
					var sn = data.signer_serial_number;
					timestamper(data.signature_hex, function(tst){
						CAPIWS.callFunction({plugin:"pkcs7", name:"attach_timestamp_token_pkcs7", arguments:[pkcs7, sn, tst]},function(event, data){
							if(data.success){
								var pkcs7tst = data.pkcs7_64;
								success(pkcs7tst);
							} else {
								fail(null, data.reason);
							}
						}, function (e) {
							fail(e, null);
						});
					}, fail);
				} else {
					success(pkcs7);
				}
			} else {
				fail(null, data.reason);
			}
		}, function (e) {
			fail(e, null);
		});
	},
	_getX500Val: function (s, f) {
		var res = s.splitKeep(/,[A-Z]+=/g, true);
		for (var i in res) {
			var n = res[i].search((i > 0 ? "," : "") + f + "=");
			if (n !== -1) {
				return res[i].slice(n + f.length + 1 + (i > 0 ? 1 : 0));
			}
		}
		return "";
	},
	_findPfxs2: function (itemIdGen, itemUiGen, items, errors, callback) {
		var itmkey0;
		CAPIWS.callFunction({plugin: "pfx", name: "list_all_certificates"}, function (event, data) {
			if (data.success) {
				for (var rec in data.certificates) {
					var el = data.certificates[rec];
					var x500name_ex = el.alias.toUpperCase();
					x500name_ex = x500name_ex.replace("1.2.860.3.16.1.1=", "INN=");
					x500name_ex = x500name_ex.replace("1.2.860.3.16.1.2=", "PINFL=");
					var vo = {
						disk: el.disk,
						path: el.path,
						name: el.name,
						alias: el.alias,
						serialNumber: EIMZOClient._getX500Val(x500name_ex, "SERIALNUMBER"),
						validFrom: new Date(EIMZOClient._getX500Val(x500name_ex, "VALIDFROM").replace(/\./g, "-").replace(" ", "T")),
						validTo: new Date(EIMZOClient._getX500Val(x500name_ex, "VALIDTO").replace(/\./g, "-").replace(" ", "T")),
						CN: EIMZOClient._getX500Val(x500name_ex, "CN"),
						TIN: (EIMZOClient._getX500Val(x500name_ex, "INN") ? EIMZOClient._getX500Val(x500name_ex, "INN") : EIMZOClient._getX500Val(x500name_ex, "UID")),
						UID: EIMZOClient._getX500Val(x500name_ex, "UID"),
						PINFL: EIMZOClient._getX500Val(x500name_ex, "PINFL"),
						O: EIMZOClient._getX500Val(x500name_ex, "O"),
						T: EIMZOClient._getX500Val(x500name_ex, "T"),
						type: 'pfx'
					};
					if (!vo.TIN && !vo.PINFL)
						continue;
					var itmkey = itemIdGen(vo,rec);
					if (!itmkey0) {
						itmkey0 = itmkey;
					}
					var itm = itemUiGen(itmkey, vo);
					items.push(itm);
				}
			} else {
				errors.push({r: data.reason});
			}
			callback(itmkey0);
		}, function (e) {
			errors.push({e: e});
			callback(itmkey0);
		});
	},
	_findTokens2: function (itemIdGen, itemUiGen, items, errors, callback) {
		var itmkey0;
		CAPIWS.callFunction({plugin: "ftjc", name: "list_all_keys", arguments:['']}, function (event, data) {
			if (data.success) {
				for (var rec in data.tokens) {
					var el = data.tokens[rec];
					var x500name_ex = el.info.toUpperCase();
					x500name_ex = x500name_ex.replace("1.2.860.3.16.1.1=", "INN=");
					x500name_ex = x500name_ex.replace("1.2.860.3.16.1.2=", "PINFL=");
					var vo = {
						cardUID: el.cardUID,
						statusInfo: el.statusInfo,
						ownerName: el.ownerName,
						info: el.info,
						serialNumber: EIMZOClient._getX500Val(x500name_ex, "SERIALNUMBER"),
						validFrom: new Date(EIMZOClient._getX500Val(x500name_ex, "VALIDFROM")),
						validTo: new Date(EIMZOClient._getX500Val(x500name_ex, "VALIDTO")),
						CN: EIMZOClient._getX500Val(x500name_ex, "CN"),
						TIN: (EIMZOClient._getX500Val(x500name_ex, "INN") ? EIMZOClient._getX500Val(x500name_ex, "INN") : EIMZOClient._getX500Val(x500name_ex, "UID")),
						UID: EIMZOClient._getX500Val(x500name_ex, "UID"),
						PINFL: EIMZOClient._getX500Val(x500name_ex, "PINFL"),
						O: EIMZOClient._getX500Val(x500name_ex, "O"),
						T: EIMZOClient._getX500Val(x500name_ex, "T"),
						type: 'ftjc'
					};
					if (!vo.TIN && !vo.PINFL)
						continue;
					var itmkey = itemIdGen(vo,rec);
					if (!itmkey0) {
						itmkey0 = itmkey;
					}
					var itm = itemUiGen(itmkey, vo);
					items.push(itm);
				}
			} else {
				errors.push({r: data.reason});
			}
			callback(itmkey0);
		}, function (e) {
			errors.push({e: e});
			callback(itmkey0);
		});
	}
}

const dates = {
	convert: function (d) {
		return (
			d.constructor === Date ? d :
				d.constructor === Array ? new Date(d[0], d[1], d[2]) :
					d.constructor === Number ? new Date(d) :
						d.constructor === String ? new Date(d) :
							typeof d === "object" ? new Date(d.year, d.month, d.date) :
								NaN
		);
	},
	compare: function (a, b) {
		return (
			isFinite(a = this.convert(a).valueOf()) &&
			isFinite(b = this.convert(b).valueOf()) ?
				(a > b) - (a < b) :
				NaN
		);
	},
	inRange: function (d, start, end) {
		return (
			isFinite(d = this.convert(d).valueOf()) &&
			isFinite(start = this.convert(start).valueOf()) &&
			isFinite(end = this.convert(end).valueOf()) ?
				start <= d && d <= end :
				NaN
		);
	}
}

class SignAgreementView extends Component {

	constructor() {
		super();
		let user = localStorage.getItem('user');
		if (user !== undefined && user !== null) {
			user = JSON.parse(user);
		}
		this.state = {
			user: user,
			ws: null
		};
		this.checkConn = this.checkConn.bind(this);
	}

	componentDidMount() {
		this.AppLoad();
		this.wsConnect();
	}

	wsConnect() {
		let ws = new WebSocket(_.wsHost + '/chat/marketing');
		let that = this;
		let connectInterval;
		ws.onopen = () => {
			console.log('WS connected');
			this.setState({ ws: ws });
			that.timeout = 250;
			clearTimeout(connectInterval);
		};
		ws.onmessage = event => {
			let message = JSON.parse(event.data);
			console.log(message);
			this.addMessage(message);
		};
		ws.onclose = function (event) {
			console.log(
				`Socket is closed. Reconnect will be attempted in ${Math.min(10000 / 1000, (that.timeout + that.timeout) / 1000)} second.`,
				event.reason
			);

			that.timeout = that.timeout + that.timeout;
			connectInterval = setTimeout(this.checkConn, Math.min(10000, that.timeout));
		};
		ws.onerror = err => {
			console.error(
				"Socket encountered error: ",
				err.message,
				"Closing socket"
			);

			ws.close();
		};
		this.setState({ws: ws});
	}

	checkConn() {
		let ws = this.state.ws;
		if (!ws || ws.readyState === WebSocket.CLOSED) this.wsConnect();
	};

	addMessage(message) {
		document.getElementById('signData').innerHTML = message.content;
		console.log(JSON.parse(message.content));
	}

	AppLoad() {
		EIMZOClient.API_KEYS = [
			'localhost', '96D0C1491615C82B9A54D9989779DF825B690748224C2B04F500F370D51827CE2644D8D4A82C18184D73AB8530BB8ED537269603F61DB0D03D2104ABF789970B',
			'127.0.0.1', 'A7BCFA5D490B351BE0754130DF03A068F855DB4333D43921125B9CF2670EF6A40370C646B90401955E1F7BC9CDBF59CE0B2C5467D820BE189C845D0B79CFC96F',
			'null',      'E0A205EC4E7B78BBB56AFF83A733A1BB9FD39D562E67978CC5E7D73B0951DB1954595A20672A63332535E13CC6EC1E1FC8857BB09E0855D7E76E411B6FA16E9D',
			'dls.yt.uz', 'EDC1D4AB5B02066FB3FEB9382DE6A7F8CBD095E46474B07568BC44C8DAE27B3893E75B79280EA82A38AD42D10EA0D600E6CE7E89D1629221E4363E2D78650516'
		];
		this.uiLoading();
		EIMZOClient.checkVersion(function(major, minor){
			let newVersion = EIMZO_MAJOR * 100 + EIMZO_MINOR;
			let installedVersion = parseInt(major) * 100 + parseInt(minor);
			if(installedVersion < newVersion) {
				document.getElementById('message').innerHTML = errorUpdateApp;
			} else {
				EIMZOClient.installApiKeys(()=>{
					let combo = document.getElementById('key');
					combo.length = 0;
					EIMZOClient.listAllUserKeys(function(o, i){
						return "itm-" + o.serialNumber + "-" + i;
					},(itemKey, vo)=>{
						let now = new Date();
						vo.expired = dates.compare(now, vo.validTo) > 0;
						let item = document.createElement("option");
						item.value = itemKey;
						item.text = vo.CN;
						if (vo.expired) {
							item.style.color = 'gray';
							item.text = item.text + ' (срок истек)';
						}
						item.setAttribute('vo',JSON.stringify(vo));
						item.setAttribute('id',itemKey);
						return item;
					},(items, firstId)=>{
						let combo = document.getElementById('key');
						for (let itm in items) {
							combo.append(items[itm]);
						}
						document.getElementById('message').innerHTML = '';
						if(firstId){
							let id = document.getElementById(firstId);
							id.setAttribute('selected','true');
						}
					},(e, r)=>{
						if(e){
							window.alert(errorCAPIWS + " : " + e);
						} else {
							window.alert(r);
						}
					});
				},(e, r)=>{
					if(r){
						if (r) {
							window.alert(errorCAPIWS + " : " + r);
						} else {
							window.alert(errorBrowserWS);
						}
					} else {
						if (e) {
							window.alert(errorCAPIWS + " : " + e);
						} else {
							window.alert(errorBrowserWS);
						}
					}
				});
			}
		}, (e, r)=>{
			if(r){
				window.alert(r);
			} else {
				this.uiNotLoaded(e);
			}
		});
	}

	uiNotLoaded(e){
		let l = document.getElementById('message');
		l.innerHTML = '';
		if (e) {
			if (e) {
				window.alert(errorCAPIWS + " : " + e);
			} else {
				window.alert(errorBrowserWS);
			}
		} else {
			window.alert(errorBrowserWS);
		}
	}

	uiLoading(){
		let l = document.getElementById('message');
		if (l !== null) {
			l.innerHTML = 'Загрузка ...';
			l.style.color = 'red';
		}
	}

	cbChanged(){
		document.getElementById('keyId').innerHTML = '';
	}

	sign() {
		let itm = document.getElementById('key').value;
		if (itm) {
			let id = document.getElementById(itm);
			let vo = JSON.parse(id.getAttribute('vo'));
			let data = document.getElementById('signData').value;
			let keyId = document.getElementById('keyId').innerHTML;
			if(keyId){
				EIMZOClient.createPkcs7(keyId, data, null, (pkcs7)=>{
					document.getElementById('pkcs7').value = pkcs7;
					axios.post(_.apiHost + '/api/agreement/sign?document=' + pkcs7, null, authHeader()).then(result => {
						console.log(result.data.message);
					}).catch(error=>{
						if (error !== undefined && error !== null && error.response.status === 401) {
							logout();
						}
					});
				}, (e, r)=>{
					if(r){
						if (r.indexOf("BadPaddingException") !== -1) {
							window.alert(errorWrongPassword);
						} else {
							window.alert(r);
						}
					} else {
						document.getElementById('keyId').innerHTML = '';
						window.alert(errorBrowserWS);
					}
					if(e) {
						if (e) {
							window.alert(errorCAPIWS + " : " + e);
						} else {
							window.alert(errorBrowserWS);
						}
					}
				});
			} else {
				EIMZOClient.loadKey(vo, function(id){
					document.getElementById('keyId').innerHTML = id;
					EIMZOClient.createPkcs7(id, data, null, (pkcs7)=>{
						document.getElementById('pkcs7').value = pkcs7;
					}, function(e, r){
						if(r){
							if (r.indexOf("BadPaddingException") !== -1) {
								window.alert(errorWrongPassword);
							} else {
								window.alert(r);
							}
						} else {
							document.getElementById('keyId').innerHTML = '';
							window.alert(errorBrowserWS);
						}
						if(e) {
							if (e) {
								window.alert(errorCAPIWS + " : " + e);
							} else {
								window.alert(errorBrowserWS);
							}
						}
					});
				}, function(e, r){
					if(r){
						if (r.indexOf("BadPaddingException") !== -1) {
							window.alert(errorWrongPassword);
						} else {
							window.alert(r);
						}
					} else {
						window.alert(errorBrowserWS);
					}
					if(e) {
						if (e) {
							window.alert(errorCAPIWS + " : " + e);
						} else {
							window.alert(errorBrowserWS);
						}
					}
				});
			}
		}
	};

	render() {
		pageNotFound();
		return (
			<div className="content">
				<div className="card card-body shadow-sm">
					<div style={{padding: "20px"}}>
						<div className="form-row mb-3">
							<div className="col-md-12 text-center"><h4>ERI parolini kiritish</h4></div>
							<label id="message"/>
						</div>
						<div className="form-row">
							<div className="col-md-3">
								<label htmlFor="key">Elektron Raqamli Imzo</label>
								<select className="form-control mr-2" id="key" name="key" onChange={this.cbChanged.bind(this)}/>
							</div>
							<div className="col-md-1">
							</div>
							<div className="col-md-8">
								<label htmlFor="signData">Imzo matni</label>
								<textarea id="signData" className="form-control" name="signData"/>
							</div>
						</div>
						<div className="form-row mt-2">
							<div className="col-md-12 text-center">
								<button className="btn btn-success" onClick={this.sign.bind(this)}>Imzolash</button><br />
							</div>
						</div>
						<div className="form-row">
							<div className="col-md-12">
								ERI IDsi:   <label id="keyId"/><br />
							</div>
						</div>
						<div className="form-row">
							<div className="col-md-12">
								<label htmlFor="pkcs7">ERI PKCS#7 matni</label>
								<textarea id="pkcs7" name="pkcs7" rows={12} className="form-control"/>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default SignAgreementView;