import React, {Component} from 'react';
import swal from 'sweetalert';
import axios from 'axios';
import * as _ from './Utils';
import 'react-responsive-modal/styles.css';
import Modal from 'react-responsive-modal';

import './styles/loading.sass';
import ListenerItem from "./ListenerItem";
import authHeader from "./services/auth-header";
import {apiHost} from "./Utils";

export default class AddAgreementView extends Component {

	constructor() {
		super();

		let topicId = localStorage.getItem('topicId');
		this.state = {
			showLoading: false,
			id: null,
			modalOpen: false,
			showRequisiteInputs: true,
			allAmount: 0,
			agreementDate: '',
			agreementNumber: '',
			downloadLink: '',
			region: 0,
			organizationName: null,
			organizationDirector: null,
			tendency: null,
			phoneNumber: '',
			agreementTypes: [],
			passports: [],
			topicId: topicId,
			requisite1: '',
			requisite2: '',
			requisite3: '',
			requisite4: '',
			requisite5: '',
			requisite6: '',
			requisite7: '',
			requisite8: '',
			paymentType: '',
			listeners: [],
			listenerCount: 1,
			paymentPercent: 100,
			paymentPercents: [],

			paymentType2: 1,
			listenerLastName: '',
			listenerFirstName: '',
			listenerMiddleName: '',
			position: '',
			bhm: 0,
			newCourseId: null,
			passportNumber2: '',
			pinflNumber2: '',
			selectedFiles: []
		};
		this.setValue = this.setValue.bind(this);
		this.convertFiles = this.convertFiles.bind(this);
		this.addRow = this.addRow.bind(this);
	}

	componentDidMount() {
		this.setState({
			showLoading: true
		});
		this.addRow();
		axios.get(_.apiHost + '/api/admin/bhm')
			.then(result => {
				if (result.status === 200) {
					this.setState({
						bhm: result.data
					})
				}
			});

		this.getTendencies();
	}

	getTendencies() {
		axios.get(_.apiHost + '/api/agreement/topicTendencies?id=' + this.state.topicId).then(result => {
			if (result.status === 200) {
				if (result.data !== undefined && result.data !== null) {
					let items = result.data.map(item => {
						return <option key={item.id} value={item.id}>{item.name}</option>
					});
					if (result.data.length > 1) {
						items.unshift(<option key={'t_0'} value={0}>Tanlang</option>);
					}
					this.setState({
						agreementTypes: items,
						showLoading: false
					});
					this.onChangeTendency(this.state.tendency);
				}
			} else {
				this.setState({
					showLoading: false
				});
			}
		});
	}

	setValue(e) {
		let name = e.target.id;
		let value = e.target.value;
		if (name === 'organizationName') {
			document.getElementById('requisite1').innerHTML = value;
			this.setState({
				[name]: value,
				requisite1: value
			})
		} else if (name === 'tendency') {
			this.onChangeTendency(value);
		} else if (name === 'requisite4' || name === 'requisite5' || name === 'requisite6' || name === 'requisite7' || name === 'requisite8') {
			if (!(/^[0-9 ]+$/.test(value))) {
				value = value.substr(0, value.length - 1);
				e.target.value = value;
			}
			this.setState({
				[name]: value
			})
		} else {
			this.setState({
				[name]: value,
			})
		}
	}

	convertFiles(e) {
		let files = [];
		for (let i = 0; i < e.target.files.length; i++) {
			let file = e.target.files[i];
			if (file.type === 'application/pdf' || file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png') {
				this.getBase64(file).then(result => {
					files.push(result);
					this.setState({
						selectedFiles: files
					});
				})
			} else {
				swal('Barcha tinglovchilarning pasportini PDF yoki rasm faylini yuklang', '', 'error');
				return;
			}
		}
	}

	onChangeTendency(tendencyId) {
		let paymentPercents = [];
		paymentPercents.push(<option key="100" value={100}>100%</option>);
		if (this.state.paymentType2 === 1) {
			if (+tendencyId === 3 || +tendencyId === 4) {
				this.setState({
					showRequisiteInputs: false
				})
			} else {
				this.setState({
					showRequisiteInputs: true
				})
			}
			if (+tendencyId === 1 || +tendencyId === 6 || +tendencyId === 45 || +tendencyId > 45) {
				paymentPercents.push(<option key="30" value={30}>30%</option>);
			}
		}
		this.setState({
			tendency: tendencyId,
			paymentPercents: paymentPercents
		})
	}

	save() {
		if (this.state.region === null || this.state.region === '') {
			swal("Hududni tanlang", "", "error");
			return;
		}
		let tendency = document.getElementById('tendency').value;
		if (tendency === null || tendency === '') {
			swal("Shartnoma turini tanlang", "", "error");
			return;
		}
		if (this.state.paymentPercent === null || this.state.paymentPercent === '') {
			swal("To`lov miqdorini tanlang", "", "error");
			return;
		}
		if (this.state.phoneNumber === null || this.state.phoneNumber === '') {
			swal("Telefon raqamini kiriting", "", "error");
			return;
		}

		if (this.state.paymentType2 === 1) {
			if (this.state.organizationName === null || this.state.organizationName === '') {
				swal("Tashkilot nomini kiriting", "", "error");
				return;
			}
			if (this.state.organizationDirector === null || this.state.organizationDirector === '') {
				swal("Tashkilot rahbari F.I.Sh ni kiriting", "", "error");
				return;
			}

			if (this.state.requisite2 === '') {
				swal("Tashkilot manzilini kiriting", "", "error");
				return;
			}
			if (this.state.requisite3 === '') {
				swal("Bank nomini kiriting", "", "error");
				return;
			}
			if (this.state.requisite4 === '') {
				swal("Tashkilot hisob raqamini kiriting", "", "error");
				return;
			}
			if (this.state.requisite6 === '') {
				swal("MFO ni kiriting", "", "error");
				return;
			}
			if (this.state.requisite7 === '') {
				swal("INN ni kiriting", "", "error");
				return;
			}
		} else if (this.state.paymentType2 === 2) {
			if (this.state.listenerLastName === null || this.state.listenerLastName === '' || this.state.listenerFirstName === null || this.state.listenerFirstName === '' || this.state.listenerMiddleName === null || this.state.listenerMiddleName === '') {
				swal("Tinglovchining F.I.Sh ni kiriting", "", "error");
				return;
			}
			if (this.state.position === null || this.state.position === '') {
				swal("Lavozimini kiriting", "", "error");
				return;
			}
			if (this.state.passportNumber2 === null || this.state.passportNumber2 === '') {
				swal("Pasport seriyasi va raqamini kiriting", "", "error");
				return;
			}
			if (this.state.pinflNumber2 === null || this.state.pinflNumber2 === '') {
				swal("JShShIR raqamini kiriting", "", "error");
				return;
			}
		}

		let data = {};

		let listenersJson = [];
		if (this.state.paymentType2 === 1) {
			data['requisites'] = [
				{"requisiteType": "ORGANIZATION_NAME", "requisiteValue": this.state.requisite1},
				{"requisiteType": "ADDRESS", "requisiteValue": this.state.requisite2},
				{"requisiteType": "BANK_NAME", "requisiteValue": this.state.requisite3},
				{"requisiteType": "ACCOUNT_NUMBER1", "requisiteValue": this.state.requisite4},
				{"requisiteType": "ACCOUNT_NUMBER2", "requisiteValue": this.state.requisite5},
				{"requisiteType": "MFO", "requisiteValue": this.state.requisite6},
				{"requisiteType": "INN1", "requisiteValue": this.state.requisite7},
				{"requisiteType": "INN2", "requisiteValue": this.state.requisite8}
			];
			data['organizationName'] = this.state.organizationName;
			data['organizationDirector'] = this.state.organizationDirector;

			let listeners = this.state.listeners;
			for (let i = 0; i < listeners.length; i++) {
				let item = listeners[i];
				let lastName = document.getElementById('lastName' + item.key).value;
				if (lastName === null || lastName.length === 0) {
					swal('Tinglovchining familiyasini kiriting', '', 'error');
					return;
				}
				let firstName = document.getElementById('firstName' + item.key).value;
				if (firstName === null || firstName.length === 0) {
					swal('Tinglovchining ismini kiriting', '', 'error');
					return;
				}
				let middleName = document.getElementById('middleName' + item.key).value;
				if (middleName === null || middleName.length === 0) {
					swal('Tinglovchining sharifini kiriting', '', 'error');
					return;
				}
				let position = document.getElementById('position' + item.key).value;
				if (position === null || position.length === 0) {
					swal('Tinglovchining lavozimini kiriting', '', 'error');
					return;
				}
				let phoneNumber = document.getElementById('phoneNumber' + item.key).value;
				if (phoneNumber === null || phoneNumber.length === 0) {
					swal('Tinglovchining telefon raqamini kiriting', '', 'error');
					return;
				}
				let passportNumber = document.getElementById('passportNumber' + item.key).value;
				if (passportNumber === null || passportNumber.length === 0) {
					swal('Tinglovchining pasport seriyasi va raqamini kiriting', '', 'error');
					return;
				} else if (passportNumber.length < 9 || passportNumber.length > 10) {
					swal('Tinglovchining pasport seriyasi va raqamini to`g`ri kiriting', '', 'error');
				}
				let pinflNumber = document.getElementById('pinflNumber' + item.key).value;
				if (pinflNumber === null || pinflNumber.length === 0) {
					swal('Tinglovchining JShShIR raqamini kiriting', '', 'error');
					return;
				} else if (pinflNumber.length < 14 || pinflNumber.length > 14) {
					swal('Tinglovchining JShShIR raqamini to`g`ri kiriting', '', 'error');
				}

				listenersJson.push(
					{
						"lastName": lastName,
						"firstName": firstName,
						"middleName": middleName,
						"position": position,
						"phoneNumber": phoneNumber,
						"passportNumber": passportNumber,
						"pinflNumber": pinflNumber
					}
				);
			}

		} else if (this.state.paymentType2 === 2) {
			data['position'] = this.state.position;
			data['passportNumber'] = this.state.passportNumber2;

			listenersJson.push(
				{
					"lastName": this.state.listenerLastName,
					"firstName": this.state.listenerFirstName,
					"middleName": this.state.listenerMiddleName,
					"position": this.state.position,
					"phoneNumber": this.state.phoneNumber,
					"passportNumber": this.state.passportNumber2,
					"pinflNumber": this.state.pinflNumber2
				}
			);
		}

		data['listeners'] = listenersJson;
		data['categoryId'] = this.state.topicId;
		data['regionId'] = this.state.region;
		data['tendencyId'] = tendency;
		data['phoneNumber'] = this.state.phoneNumber;
		data['paymentType'] = this.state.paymentType2;
		data['paymentPercent'] = this.state.paymentPercent;
		data['passportContents'] = this.state.selectedFiles;

		this.setState({
			showLoading: true
		});
		axios.post(apiHost + '/api/agreement/add', data, authHeader())
			.then(result => {
			this.setState({
				showLoading: false
			});
			if (result.status === 200) {
				this.setState({
					id: result.data.id,
					allAmount: result.data.allAmount,
					agreementDate: result.data.agreementDate,
					agreementNumber: result.data.agreementNumber,
					downloadLink: result.data.downloadLink
				});
			} else {
				swal("Shartnomani yuborishda xatolik", "", 'error');
			}
		})
	}

	getBase64 = file => {
		return new Promise(resolve => {
			let baseURL = "";
			let reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => {
				baseURL = reader.result;
				resolve(baseURL);
			};
		});
	};

	payment() {
		this.setState({
			modalOpen: true
		})
	}

	onCloseModal(modalName) {
		this.setState({
			[modalName]: false
		})
	}

	openPaymentPage(paymentType) {
		this.setState({
			paymentType: paymentType
		});
		if (paymentType === 'click') {
			document.getElementById('clickForm').submit();
		} else if (paymentType === 'payme') {
			document.getElementById('paymeForm').submit();
		}
	}

	addRow() {
		let listeners = this.state.listeners;
		let listenerCount = this.state.listenerCount + 1;
		listeners.push(<ListenerItem key={'lc_' + listenerCount} rowId={'lc_' + listenerCount} addRow={this.addRow}
									 removeRow={this.removeRow.bind(this, 'lc_' + listenerCount)}/>);
		this.setState({
			listeners: listeners,
			listenerCount: listenerCount
		})
	}

	removeRow(key) {
		let listeners = this.state.listeners;
		this.state.listeners.forEach((l, index) => {
			if (l.key === key) {
				listeners.splice(index, 1);
			}
		});
		this.setState({
			listeners: listeners
		})
	}

	onChangePaymentType(paymentType) {
		if (paymentType === 1 && (+this.state.tendency === 1 || +this.state.tendency === 6 || +this.state.tendency === 45)) {
			let paymentPercents = [];
			paymentPercents.push(<option key="100" value={100}>100%</option>);
			paymentPercents.push(<option key="30" value={30}>30%</option>);
			this.setState({
				paymentPercents: paymentPercents
			})
		} else if (paymentType === 2 && (+this.state.tendency === 1 || +this.state.tendency === 6 || +this.state.tendency === 45)) {
			let paymentPercents = [];
			paymentPercents.push(<option key="100" value={100}>100%</option>);
			this.setState({
				paymentPercents: paymentPercents
			})
		}
		this.setState({
			paymentType2: paymentType
		})
	}

	downloadAgreement() {
		axios({
			url: apiHost + '/api/agreement/download/' + this.state.downloadLink,
			method: "GET",
			headers: authHeader().headers,
			responseType: "blob"
		})
			.then(result => {
				const link = document.createElement("a");
				link.href = window.URL.createObjectURL(new Blob([result.data]));
				link.setAttribute(
					'download',
					this.state.downloadLink + '.pdf'
				);

				document.body.appendChild(link);

				link.click();
				link.parentNode.removeChild(link);
			})
	}

	render() {
		return (
			<div>
				{this.state.showLoading ?
					<div className="loading-container">
						<div className="loader">
							<div className="loader--dot"/>
							<div className="loader--dot"/>
							<div className="loader--dot"/>
							<div className="loader--dot"/>
							<div className="loader--dot"/>
							<div className="loader--dot"/>
							<div className="loader--text"/>
						</div>
					</div>
					:
					<div className="container-fluid card card-body">
						<div className="form-row">
							<div className="col-md-12 mb-3 text-center">
							<span style={{
								fontSize: "20px",
								fontWeight: "bold"
							}}>{_.topics[this.state.topicId - 1] + ' bo`yicha shartnoma tuzish'}</span>
							</div>
						</div>

						{Number(this.state.topicId) === 1 &&
						<div className="form-row">
							<div className="col-md-12 mb-2" style={{color: "red"}}>
								<p><b>Quyidagilar mediator bo‘lishi mumkin emas:</b></p>
								<ul>
									<li>davlat vazifalarini bajarish uchun vakolat berilgan yoki unga tenglashtirilgan
										shaxs, bundan notariuslar mustasno;
									</li>
									<li>muomala layoqati cheklangan yoki muomalaga layoqatsiz deb topilganligi
										to‘g‘risida sudning qonuniy kuchga kirgan hal qiluv qarori mavjud bo‘lgan shaxs;
									</li>
									<li>sudlanganlik holati tugallanmagan yoki sudlanganligi olib tashlanmagan shaxs;
									</li>
									<li>o‘ziga nisbatan jinoiy ta’qib amalga oshirilayotgan shaxs.</li>
								</ul>
							</div>
						</div>
						}
						<div className="form-row">
							<div className="col-md-6 mb-2">
								<label htmlFor="tendency">Shartnoma turi</label>
								<select className="form-control" id="tendency" defaultValue={this.state.tendencyId}
										onChange={this.setValue}>
									{this.state.agreementTypes}
								</select>
							</div>

							<div className="col-md-6 mt-3">
								<div className="form-check ml-5">
									<input className="form-check-input" type="radio" name="paymentType"
										   id="paymentType1" defaultChecked={this.state.paymentType2 === 1}
										   onClick={this.onChangePaymentType.bind(this, 1)}/>
									<label className="form-check-label" htmlFor="paymentType1">
										Tashkilot orqali
									</label>
								</div>

								<div className="form-check ml-5">
									<input className="form-check-input" type="radio" name="paymentType"
										   id="paymentType2" defaultChecked={this.state.paymentType2 === 2}
										   onClick={this.onChangePaymentType.bind(this, 2)}/>
									<label className="form-check-label" htmlFor="paymentType2">
										O`zi tomonidan
									</label>
								</div>
							</div>
						</div>
						<div className="form-row mb-3">
							<div className="col-md-6">
								<label htmlFor="region">Hudud</label>
								<select className="form-control" id="region" value={this.state.region} onChange={this.setValue}>
									{_.getRegions()}
								</select>
							</div>

							{this.state.paymentType2 === 1 &&
							<div className="col-md-6">
								<label htmlFor="organizationName">Tashkilot nomi</label>
								<input className="form-control" id="organizationName" onChange={this.setValue}/>
							</div>
							}
							{this.state.paymentType2 === 2 &&
							<div className="col-md-6">
								<div className="form-row">
									<div className="col-md-4">
										<label htmlFor="listenerLastName">Familiyasi</label>
										<input className="form-control" id="listenerLastName" onChange={this.setValue}/>
									</div>
									<div className="col-md-4">
										<label htmlFor="listenerFirstName">Ismi</label>
										<input className="form-control" id="listenerFirstName"
											   onChange={this.setValue}/>
									</div>
									<div className="col-md-4">
										<label htmlFor="listenerMiddleName">Sharifi</label>
										<input className="form-control" id="listenerMiddleName"
											   onChange={this.setValue}/>
									</div>
								</div>
							</div>
							}
						</div>

						<div className="form-row mb-3">
							{this.state.paymentType2 === 1 &&
							<div className="col-md-4">
								<label htmlFor="organizationDirector">Tashkilot rahbari</label>
								<input className="form-control" id="organizationDirector" onChange={this.setValue}/>
							</div>
							}
							<div className="col-md-4">
								<label htmlFor="tendency">To`lov miqdori</label>
								<select className="form-control" id="paymentPercent" onChange={this.setValue}>
									{this.state.paymentPercents}
								</select>
							</div>
							<div className="col-md-4">
								<label htmlFor="phoneNumber">Telefon raqami</label>
								<input type="number" className="form-control" id="phoneNumber"
									   onChange={this.setValue}/>
							</div>
							{this.state.paymentType2 === 2 &&
							<>
								<div className="col-md-4 mb-3">
									<label htmlFor="passportNumber2">Pasport seriyasi va raqami</label>
									<input className="form-control" id="passportNumber2" onChange={this.setValue}/>
								</div>
								<div className="col-md-4 mb-3">
									<label htmlFor="pinflNumber2">JShShIR raqami</label>
									<input className="form-control" id="pinflNumber2" onChange={this.setValue}/>
								</div>
								<div className="col-md-4 mb-3">
									<label htmlFor="phoneNumber">Ish joyi va lavozimi (<i style={{color: "red"}}>To`liq
										kiritilsin</i>)</label>
									<input className="form-control" id="position" type="text"
										   placeholder="Toshkent shahri Yunusobod tumani Moliya bo‘limi bosh yuriskonsulti"
										   onChange={this.setValue}/>
								</div>
							</>
							}
						</div>
						{this.state.paymentType2 === 1 &&
						<div className="form-row">
							<fieldset id="listenerFS">
								<legend>Tinglovchilar ma'lumotlarini kiriting</legend>
								<div>
									{this.state.listeners}
								</div>
							</fieldset>
						</div>
						}
						<div className="form-row mt-3">
							<div className="col-md-5 mb-2">
								<label htmlFor="passports"><b>Tinglovchilar pasportlarini nusxasini yuklang</b></label>
							</div>
							<div className="col-md-7 mb-2">
								<input type="file" id="passports" multiple onChange={this.convertFiles}/>
							</div>
						</div>
						{this.state.paymentType2 === 1 &&
						<fieldset>
							<legend>Rekvizitlar</legend>

							<div className="form-row">
								<div className="col-md-4">
									<label htmlFor="requisite1">Tashkilot nomi</label>
									<label id="requisite1" className="form-control"/>
								</div>
								<div className="col-md-4">
									<label htmlFor="requisite2">Manzili</label>
									<input type="text" id="requisite2" onChange={this.setValue}
										   className="form-control mb-1" placeholder="Manzili"/>
								</div>
								<div className="col-md-4">
									<label htmlFor="requisite3">Bank nomi</label>
									<input type="text" id="requisite3" onChange={this.setValue}
										   className="form-control mb-1" placeholder="Bank nomi"/>
								</div>
							</div>
							<div className="form-row">
								<div className="col-md-4">
									<label htmlFor="requisite4">Hisob raqami</label>
									<input type="text" id="requisite4" maxLength={20} onChange={this.setValue}
										   className="form-control mb-1" placeholder="Hisob raqami"/>
								</div>
								{this.state.showRequisiteInputs &&
								<div className="col-md-4">
									<label htmlFor="requisite5">Shaxsiy hisob varag`i raqami</label>
									<input type="text" id="requisite5" maxLength={27} onChange={this.setValue}
										   className="form-control mb-1" placeholder="Shaxsiy hisob varag`i raqami"/>
								</div>
								}
								<div className="col-md-4">
									<label htmlFor="requisite6">MFO</label>
									<input type="text" id="requisite6" maxLength="5" onChange={this.setValue}
										   className="form-control mb-1" placeholder="MFO"/>
								</div>
								<div className="col-md-4">
									<label htmlFor="requisite7">INN (Tashkilot)</label>
									<input type="text" id="requisite7" maxLength={9} onChange={this.setValue}
										   className="form-control mb-1" placeholder="INN"/>
								</div>
								{this.state.showRequisiteInputs &&
								<div className="col-md-4">
									<label htmlFor="requisite8">INN (g`aznachilik)</label>
									<input type="text" id="requisite8" maxLength={9} onChange={this.setValue}
										   className="form-control mb-1" placeholder="INN"/>
								</div>
								}
							</div>
						</fieldset>
						}
						<div className="form-row mt-3">
							<div className="col-md-12 mb-2 text-center">
								{this.state.id === null &&
								<button className="btn btn-primary btn-lg" onClick={this.save.bind(this)}>
									Saqlash
								</button>
								}
								{this.state.id !== null &&
									<button className="btn btn-primary btn-lg ml-1" onClick={this.downloadAgreement.bind(this)}>Shartnomani yuklab olish</button>
								}
								{this.state.id !== null &&
								<button className="btn btn-primary btn-lg ml-1" type="button"
										onClick={this.payment.bind(this)}>Pul to`lash</button>
								}
							</div>
						</div>
						<Modal open={this.state.modalOpen} onClose={this.onCloseModal.bind(this, 'modalOpen')} center>
							<div style={{padding: "20px"}}>
								<div className="form-row">
									<div className="col-md-12">
										<h5>{this.state.agreementDate + ' dagi ' + this.state.agreementNumber + ' raqamli shartnoma bo`yicha to`lov'}</h5>
									</div>
								</div>
								<div className="form-row">
									<div className="col-md-12 mt-3 text-center">
										<button className="btn btn-success mr-1"
												onClick={this.openPaymentPage.bind(this, 'click')}>Click bilan to`lov
										</button>
										<button className="btn btn-success ml-1"
												onClick={this.openPaymentPage.bind(this, 'payme')}>Payme bilan to`lov
										</button>
									</div>
								</div>
								<div className="form-row">
									<div className="col-md-12 mt-3 text-center">
										<form id="clickForm" action="https://my.click.uz/services/pay" method="get"
											  target="_blank">
											<input type="hidden" name="service_id" value="16843"/>
											<input type="hidden" name="merchant_id" value="12166"/>
											<input type="hidden" name="merchant_user_id" value="17996"/>
											<input type="hidden" name="amount" value={this.state.allAmount * 100}/>
											<input type="hidden" name="transaction_param"
												   value={this.state.phoneNumber}/>
											<input type="hidden" name="return_url"
												   value="http://elektron.uzmarkaz.uz/payment/click/receive"/>
										</form>

										<form id="paymeForm" method="POST" action="https://checkout.paycom.uz"
											  target="_blank">
											<input type="hidden" name="merchant" value="5f92c407740f35d3638a85c3"/>
											<input type="hidden" name="amount" value={this.state.allAmount * 100}/>
											<input type="hidden" name="account[Full_name]"
												   value={this.state.organizationDirector}/>
											<input type="hidden" name="account[Contract_number]"
												   value={this.state.agreementNumber}/>
											<input type="hidden" name="account[Phone_number]"
												   value={this.state.phoneNumber}/>
										</form>
									</div>
								</div>
							</div>
						</Modal>
					</div>
				}
			</div>
		)
	}
}