import React, {Component} from 'react';
import * as _ from './Utils';
import {apiHost, logout, pageNotFound} from './Utils';
import axios from 'axios';
import swal from "sweetalert";
import authHeader from "./services/auth-header";
import getTime from "date-fns/getTime";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {registerLocale, setDefaultLocale} from "react-datepicker";
import uz from 'date-fns/locale/uz';

registerLocale('uz', uz);
setDefaultLocale('uz');

export default class AgreementEditView extends Component {

	constructor(props) {
		super(props);
		this.state = {
			agreement: {
				agreementDate: new Date().getTime(),
				region: {
					id: 0,
					name: ''
				},
				tendency: {
					id: '',
					name: ''
				}
			},
            organizationName: '',
			payedAmount: '',
			agreementId: null,
			requisites: [],
			listeners: [],
			tendencyList: [],
			images: [],
			ADDRESS: 0,
			BANK_NAME: 0,
			ACCOUNT_NUMBER1: 0,
			ACCOUNT_NUMBER2: 0,
			MFO: 0,
			INN1: 0,
			INN2: 0
		};
		this.setValue = this.setValue.bind(this);
	}

	componentDidMount() {
		const agreementId = localStorage.getItem('agreementId');
		this.setState({
			agreementId: agreementId
		});
		axios.get(_.apiHost + '/api/course/tendency/list', authHeader())
			.then(result => {
				let tendencyList = [];
				if (result.status === 200) {
					tendencyList = result.data.map(item=>{
						return <option key={item.id} value={item.id}>{item.name}</option>
					});
					this.setState({
						tendencyList: tendencyList
					})
				}
			}).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		});

		axios.get(_.apiHost + '/api/agreement/get?id=' + agreementId, authHeader())
			.then(result => {
				if (result.status === 200) {
					if (result.data !== undefined && result.data !== null) {
						let index = 0;
						let lines = result.data.requisites.map(req=>{
							if (req.requisiteType !== null) {
								this.setState({
									[req.requisiteType.code]: req.id
								});
							}
							index++;
							return <div key={'req' + req.id} className={index === 1 || index === 2 ? "col-md-12 mb-2" : "col-md-6 mb-2"}>
								{req.requisiteType !== null &&
									<>
										<label className="col-form-label"
											   htmlFor={req.requisiteType.code}>{req.requisiteType.name}</label>
										<input className="form-control" id={req.requisiteType.code}
											   defaultValue={req.requisiteValue}/>
									</>
								}
							</div>
						});
						let listeners = result.data.listeners.map(req=>{
							return <tr key={'tr' + req.id} className="col-md-6 mb-2">
								<td>{req.id}</td>
								<td>{req.lastName + ' ' + req.firstName + ' ' + req.middleName}</td>
								<td>{req.position}</td>
								<td><button className="btn btn-danger" onClick={this.removeListener.bind(this, req.id)}><i className="fa fa-remove"/></button></td>
							</tr>
						});
						let images = result.data.files.map(req=>{
							return <div className="ml-5 mb-2">
								<img style={{width: "200px"}} src={apiHost + "/files/agreementFiles/" + agreementId + "/" + req.name} alt=""/>
							</div>
						});
						this.setState({
							agreement: {
								...result.data,
								agreementDate: getTime(result.data.agreementDate2)
							},
							requisites: lines,
							listeners: listeners,
							images: images,
							payedAmount: result.data.payedAmount
						});
						localStorage.setItem("agreement", result.data);
					}
				}
			}).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		})
	}

	removeListener(listener) {
		axios.post(_.apiHost + '/api/listener/removeListener?listenerId=' + listener.id, null, authHeader())
			.then(result => {
				if (result.status === 200) {
					swal(result.data.message, "", "success");
				} else if (result.status === 500) {
					swal(result.data.message, "", "error");
				}
			}).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		})
	}

	setStartDate(agreementDate) {
		this.setState({
			agreement: {
				...this.state.agreement,
				agreementDate: agreementDate
			}
		})
	}

	updateAgreement() {
		let address = "";
		let ADDRESS = document.getElementById('ADDRESS');
		if (ADDRESS !== undefined && ADDRESS !== null) {
			address = ADDRESS.value;
		}
		let bankName = "";
		let BANK_NAME = document.getElementById('BANK_NAME');
		if (BANK_NAME !== undefined && BANK_NAME !== null) {
			bankName = BANK_NAME.value;
		}
		let accountNumber1 = "";
		let ACCOUNT_NUMBER1 = document.getElementById('ACCOUNT_NUMBER1');
		if (ACCOUNT_NUMBER1 !== undefined && ACCOUNT_NUMBER1 !== null) {
			accountNumber1 = ACCOUNT_NUMBER1.value;
		}
		let accountNumber2 = "";
		let ACCOUNT_NUMBER2 = document.getElementById('ACCOUNT_NUMBER2');
		if (ACCOUNT_NUMBER2 !== undefined && ACCOUNT_NUMBER2 !== null) {
			accountNumber2 = ACCOUNT_NUMBER2.value;
		}
		let mfo = "";
		let MFO = document.getElementById('MFO');
		if (MFO !== undefined && MFO !== null) {
			mfo = MFO.value;
		}
		let inn1 = "";
		let INN1 = document.getElementById('INN1');
		if (INN1 !== undefined && INN1 !== null) {
			inn1 = INN1.value;
		}
		let inn2 = "";
		let INN2 = document.getElementById('INN1');
		if (INN2 !== undefined && INN2 !== null) {
			inn2 = INN2.value;
		}
		let data = {
			"id": Number(localStorage.getItem('agreementId')),
			"agreementDate": getTime(this.state.agreement.agreementDate),
			"regionId": Number(this.state.agreement.region.id),
			"tendencyId": Number(document.getElementById('tendency').value),
			"organizationName": document.getElementById('organizationName').value,
			"organizationDirector": document.getElementById('organizationDirector').value,
			"pupilCount": Number(document.getElementById('pupilCount').value),
			"allAmount": Number(document.getElementById('allAmount').value),
			"section1Content": document.getElementById('section1Content').value,
			"section2Content": document.getElementById('section2Content').value,
			"passports": [],

			"address": {
				"id": this.state.ADDRESS,
				"name": address
			},
			"bankName": {
				"id": this.state.BANK_NAME,
				"name": bankName
			},
			"accountNumber1": {
				"id": this.state.ACCOUNT_NUMBER1,
				"name": accountNumber1
			},
			"accountNumber2": {
				"id": this.state.ACCOUNT_NUMBER2,
				"name": accountNumber2
			},
			"mfo": {
				"id": this.state.MFO,
				"name": mfo
			},
			"inn1": {
				"id": this.state.INN1,
				"name": inn1
			},
			"inn2": {
				"id": this.state.INN2,
				"name": inn2
			}
		}
		axios.post(_.apiHost + '/api/agreement/update', data, authHeader())
			.then(result => {
			if (result.status === 200) {
				swal(result.data.message, "", "success");
			} else if (result.status === 500) {
				swal(result.data.message, "", "error");
			}
		}).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		})
	}

	setValue(e) {
		let name = e.target.id;
		let value = e.target.value;
		this.setState({
			[name]: value
		});
	}

	setRegion(e) {
		let value = e.target.value;
		this.setState({
			agreement: {
				...this.state.agreement,
				region: {
					id: value
				}
			}
		})
	}

	onChangePercent() {
		let payedAmount = Number(document.getElementById('payedAmount').value);
		this.setState({
			payedAmount: this.state.agreement.allAmount * payedAmount / 100
		})
	}

	onChangeAmount(e) {
		let payedAmount = e.target.value;
		this.setState({
			payedAmount: payedAmount
		})
	}

	render() {
		pageNotFound();
		return (
			<div className="content">
                <div className="container card card-body my-4">
	                <div className="row">
		                <div className="col-md-12 mb-3 text-center">
			                <h3>Shartnoma ma'lumotlari</h3>
		                </div>
	                </div>
	                <div className="row">
		                <div className="col-md-12 order-md-1 mb-2">
			                <div className="form-row">
				                <div className="col-md-2">
					                <label htmlFor="agreementDate">Vaqti</label>
									<DatePicker className="form-control" id="agreementDate" locale="uz" dateFormat="dd.MM.yyyy"
												selected={this.state.agreement.agreementDate} onChange={this.setStartDate.bind(this)}/>
				                </div>
				                <div className="col-md-2">
					                <label>Raqami</label>
					                <label className="form-control">{this.state.agreement.agreementNumber}</label>
				                </div>
				                <div className="col-md-4">
					                <label htmlFor="region">Hudud</label>
					                <select className="form-control" id="region" value={this.state.agreement.region.id} onChange={this.setRegion.bind(this)}>
										{_.getRegions()}
					                </select>
				                </div>
				                <div className="col-md-4">
					                <label htmlFor="organizationName">Tashkilot nomi</label>
					                <input id="organizationName" className="form-control" defaultValue={this.state.agreement.organizationName} onChange={this.setValue}/>
				                </div>
			                </div>
			                <div className="form-row">
				                <div className="col-md-4 mb-3">
					                <label htmlFor="organizationDirector">Tashkilot rahbari</label>
					                <input id="organizationDirector" className="form-control" defaultValue={this.state.agreement.organizationDirector}/>
				                </div>
				                <div className="col-md-6 mb-2">
					                <label htmlFor="tendency">Yo`nalishi</label>
					                <select id="tendency" className="form-control">
						                {this.state.tendencyList}
					                </select>
				                </div>
				                <div className="col-md-2 mb-2">
					                <label htmlFor="tendency">Necha kishi o`qitiladi</label>
									<input id="pupilCount" className="form-control" defaultValue={this.state.agreement.pupilCount}/>
				                </div>
			                </div>

							<div className="form-row">
								<div className="col-md-2 mb-2">
									<label htmlFor="allAmount">Jami summasi</label>
									<input id="allAmount" className="form-control" defaultValue={this.state.agreement.allAmount}/>
								</div>
							</div>

							<div className="form-row">
								<div className="col-md-12">
									<fieldset>
										<legend><b>Rekvizitlar</b></legend>
										<div className="form-row">
											{this.state.requisites}
										</div>
									</fieldset>
								</div>
							</div>

							<div className="form-row">
								<div className="col-md-12 mb-2">
									<fieldset>
										<legend><b>Shartnoma ma'lumotlari</b></legend>
										<div className="form-row">
											<div className="col-md-12 mb-2">
												<label htmlFor="section1Content">Shartnoma predmeti</label>
												<textarea id="section1Content" rows={4} className="form-control" defaultValue={this.state.agreement.section1Content}/>
											</div>
										</div>
										<div className="form-row">
											<div className="col-md-12 mb-2">
												<label htmlFor="section2Content">Hisob-kitob tartibi</label>
												<textarea id="section2Content" rows={4} className="form-control" defaultValue={this.state.agreement.section2Content}/>
											</div>
										</div>
									</fieldset>
								</div>
							</div>
		                </div>
	                </div>
	                <div className="row">
		                <div className="row">
			                {this.state.images}
		                </div>
	                </div>
					<div className="form-row">
						<div className="col-md-12 mb-2 text-center">
							<button className="btn btn-success" onClick={this.updateAgreement.bind(this)}>Saqlash</button>
						</div>
					</div>
                </div>
			</div>
		)
	}
}