import React, {Component} from 'react';
import {apiHost, logout, pageNotFound} from "./Utils";
import swal from 'sweetalert';
import axios from "axios";
import {Tab, Tabs} from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {registerLocale, setDefaultLocale} from "react-datepicker";
import uz from 'date-fns/locale/uz';
import getTime from 'date-fns/getTime';
import CertificateListView from "./CertificateListView";
import CertificatesByTendencyReportView from "./CertificatesByTendencyReportView";
import authHeader from "./services/auth-header";

registerLocale('uz', uz);
setDefaultLocale('uz');

export default class CertificateReportView extends Component {

    constructor() {
        super();
        this.state = {
            tabListKey: 'tendencyCerts',
            startDate: new Date(2024, 0, 1, 0, 0, 0, 0),
            endDate: new Date(),
            tableRows: []
        }
    }

    componentDidMount() {
        this.getData(this.state.startDate, this.state.endDate);
    }

    setStartDate(startDate) {
        this.setState({
            startDate: startDate
        });
        this.getData(startDate, this.state.endDate);
    }

    setEndDate(endDate) {
        this.setState({
            endDate: endDate
        });
        this.getData(this.state.startDate, endDate);
    }

    getData(startDate, endDate) {
        let rows = [];
        axios.get(apiHost + '/api/reports/byCertificate?startDate=' + getTime(startDate) + '&endDate=' + getTime(endDate),
            authHeader()).then(result => {
            if (result.status === 200) {
                if (result.data !== undefined && result.data !== null) {
                    let count = 0;
                    rows = result.data.map((row, index) => {
                        count = count + row.listenerCount;
                        return <tr key={'cerrep_' + index}>
                            <td className="text-center">{index + 1}</td>
                            <td>{row.region}</td>
                            <td className="text-center">{row.listenerCount}</td>
                        </tr>
                    });
                    rows.push(<tr key="cerrep_last">
                        <td colSpan={2} className="text-center"><b>JAMI:</b></td>
                        <td className="text-center"><b>{count}</b></td>
                    </tr>);
                    this.setState({
                        tableRows: rows
                    })
                }
            } else {
                swal(result.data.message, '', 'error');
                this.setState({
                    tableRows: rows
                })
            }
        }).catch(error=>{
            if (error !== undefined && error !== null && error.response.status === 401) {
                logout();
            }
        })
    }

    render() {
        pageNotFound();
        return (
            <div className="content card card-body shadow-sm">
                <Tabs id="controlled-tab-example" activeKey={this.state.tabListKey} onSelect={key => this.setState({tabListKey: key})}>
                    <Tab eventKey="report" title="Sertifikatlar soni">
                        <div className="card card-body shadow-sm">
                            <div className="form-row mt-2">
                                <div className="col-md-6 text-right">
                                    <h3>Sertifikatlar hisoboti</h3>
                                </div>
                            </div>
                            <div className="form-row mt-2">
                                <div className="col-md-12">
                                    <DatePicker className="form-control" id="startDate" locale="uz" dateFormat="dd.MM.yyyy"
                                                selected={this.state.startDate} onChange={this.setStartDate.bind(this)}/>
                                    <span> dan </span>
                                    <DatePicker className="form-control" id="endDate" locale="uz" dateFormat="dd.MM.yyyy"
                                                selected={this.state.endDate} onChange={this.setEndDate.bind(this)}/>
                                    <span> gacha</span>
                                </div>
                            </div>
                            <div className="form-row mt-2">
                                <div className="col-md-12">
                                    <table className="table table-striped table-bordered shadow"
                                           style={{tableLayout: "fixed"}}>
                                        <thead>
                                        <tr>
                                            <th scope="col" className="align-middle" style={{width: "5%"}}>№</th>
                                            <th scope="col" className="align-middle">Yo`nalish nomi</th>
                                            <th scope="col" className="align-middle" style={{width: "10%"}}>Sertifikat
                                                soni
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.tableRows}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="certList" title="Sertifikatlar ro`yxati">
                        <CertificateListView/>
                    </Tab>
                    <Tab eventKey="tendencyCerts" title="Yo`nalishlar sertifikatlari">
                        <CertificatesByTendencyReportView/>
                    </Tab>
                </Tabs>
            </div>
        )
    }
}