import React, {Component} from 'react';
import {apiHost, logout, pageNotFound} from "./Utils";
import axios from 'axios';
import swal from 'sweetalert';
import 'react-responsive-modal/styles.css';
import Modal from 'react-responsive-modal';
import Moment from "react-moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import uz from 'date-fns/locale/uz';
import authHeader from "./services/auth-header";
registerLocale('uz', uz);
setDefaultLocale('uz');

export default class DuplicateCertificateListView extends Component {

    constructor() {
        super();
        this.state = {
            certificateDate: new Date(),
            modalOpen: false,
            tableRows: []
        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        axios.get(apiHost + '/api/listener/duplicateCertificate/list', authHeader())
            .then(result => {
            if (result.status === 200) {
                if (result.data !== null) {
                    let rows = [];
                    rows = result.data.map((row, index) => {
                        return <tr>
                            <td>{index + 1}</td>
                            <td>{row.listenerName}</td>
                            <td>{row.certificateNumber}</td>
                            <td>{row.registrationNumber}</td>
                            <td><Moment format="DD.MM.YYYY">{row.createdDate}</Moment></td>
                            <td className="text-center d-flex">
                                <button className="btn btn-info" onClick={this.downloadCertificate.bind(this, row.certificateNumber)}><i className="fa fa-download"/></button>
                                <button className="btn btn-danger ml-1" onClick={this.removeCertificate.bind(this, row.id)}><i className="fa fa-trash"/></button>
                            </td>
                        </tr>
                    });
                    this.setState({
                        tableRows: rows
                    })
                }
            }
        });
    }

    downloadCertificate(certificateNumber) {
        window.open(apiHost + "/files/duplicateCertificates/" + certificateNumber + ".pdf", "_blank");
    }

    openCloseModal(value) {
        this.setState({
            modalOpen: value
        })
    }

    generateCertificate() {
        let listener = document.getElementById('listener').value;
        let certificateNumber = document.getElementById('certificateNumber').value;
        let registrationNumber = document.getElementById('registrationNumber').value;
        let certificateDate = this.state.certificateDate;
        axios.post(apiHost + '/api/listener/duplicateCertificate/add?listener=' + listener + '&certificateNumber=' + certificateNumber +
        '&registrationNumber=' + registrationNumber + '&certificateDate=' + certificateDate.getTime(),
            null, authHeader()).then(result => {
            if (result.status === 200) {
                this.loadData();
                swal(result.data.message, "", "success");
                this.openCloseModal(false);
            } else if (result.status === 500) {
                swal(result.data.message, "", "error");
            }
        }).catch(error=>{
            if (error !== undefined && error !== null && error.response.status === 401) {
                logout();
            }
        })
    }

    removeCertificate(id) {
        axios.post(apiHost + '/api/listener/duplicateCertificate/remove/' + id, null, authHeader()).then(result=>{
            if (result.status === 200) {
                this.loadData();
                swal(result.data.message, "", "success");
            } else if (result.status === 500) {
                swal(result.data.message, "", "error");
            }
        }).catch(error=>{
            if (error !== undefined && error !== null && error.response.status === 401) {
                logout();
            }
        })
    }

    setDate(certificateDate) {
        this.setState({
            certificateDate: certificateDate
        })
    }

    render() {
        pageNotFound();
        return (
            <div className="content">
                <div className="container card card-body mt-1 shadow-sm">
                    <div className="form-row">
                        <div className="col-md-12">
                            <button className="btn btn-success" onClick={this.openCloseModal.bind(this, true)}><i className="fa fa-plus-square"/></button>
                        </div>
                    </div>
                    <div className="form-row mt-2">
                        <div className="col-md-12">
                            <table className="table table-striped table-bordered shadow" style={{tableLayout: "fixed"}}>
                                <thead>
                                <tr>
                                    <th style={{width: "5%"}}>№</th>
                                    <th>Tinglovchi</th>
                                    <th>Sertifikat raqami</th>
                                    <th>Qayd raqami</th>
                                    <th>Berilgan vaqti</th>
                                    <th width="10%">Amal</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.tableRows}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <Modal open={this.state.modalOpen} onClose={this.openCloseModal.bind(this, false)} center>
                    <div style={{padding: "20px", minWidth: "200px"}}>
                        <div className="form-row">
                            <div className="col-md-12">
                                <label htmlFor="listener">Tinglovchining F.I.Sh</label>
                                <input className="form-control" id="listener"/>
                            </div>
                        </div>
                        <div className="form-row mt-2">
                            <div className="col-md-12">
                                <label htmlFor="certificateNumber">Sertifikat raqami</label>
                                <input className="form-control" id="certificateNumber"/>
                            </div>
                        </div>
                        <div className="form-row mt-2">
                            <div className="col-md-12">
                                <label htmlFor="registrationNumber">Qayd raqami</label>
                                <input className="form-control" id="registrationNumber"/>
                            </div>
                        </div>
                        <div className="form-row mt-2">
                            <div className="col-md-12">
                                <label htmlFor="certificateDate">Berilgan vaqti: </label>
                                <DatePicker className="form-control" id="startDate" locale="uz" dateFormat="dd.MM.yyyy"
                                            selected={this.state.certificateDate} onChange={this.setDate.bind(this)}/>
                            </div>
                        </div>
                        <div className="form-row mt-2">
                            <div className="col-md-12 text-center">
                                <button className="btn btn-success" onClick={this.generateCertificate.bind(this)}>Saqlash</button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}