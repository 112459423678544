import React, {Component} from 'react';
import {apiHost, topics} from "./Utils";
import axios from "axios";
import 'react-responsive-modal/styles.css';
import Modal from 'react-responsive-modal';
import authHeader from "./services/auth-header";

export default class ListenerHomeView extends Component {

	constructor() {
		super();
		this.state = {
            tendencies: [],
            modalOpen: false,
            topicId: 1
		}
	}

	onSelectTendency(topicId) {
		localStorage.setItem('topicId', topicId);
		window.open("/addAgreement", "_self");
	}

    onOpenModal(topicId) {
		axios.get(apiHost + '/api/agreement/topicTendencies?id=' +  topicId, authHeader()).then(result => {
			if (result.status === 200) {
				if (result.data !== undefined && result.data !== null) {
					let items = result.data.map(item=>{
						return <li key={item.id}><span className="linkStyle" onClick={this.onSelectTendency.bind(this, item.id)}>{item.name}</span></li>
					});
					this.setState({
						tendencies: items,
					});
				}
			} else {
				this.setState({
					tendencies: []
				});
			}
		});
		this.setState({
            topicId: topicId,
            modalOpen: true
        })
    }

    onCloseModal(modalName) {
        this.setState({
            [modalName]: false
        })
    }

	render() {
		return (
			<div className="container">
				<div className="form-row" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
					<div className="col-md-6">
						<div className="form-row">
							<div className="col-md-12 mt-3 mb-3 text-center">
								<span style={{fontSize: "20px", fontWeight: "bold"}}>Shartnoma tuzish</span>
							</div>
						</div>
						<div className="form-row">
							<div className="col-md-6 text-center p-1" onClick={this.onSelectTendency.bind(this, 1)}>
								<div className="topic-name box1 boxName">
									<span>{topics[0]}</span>
								</div>
							</div>

							<div className="col-md-6 text-center p-1" onClick={this.onSelectTendency.bind(this, 2)}>
								<div className="topic-name box2 boxName">
									<span>{topics[1]}</span>
								</div>
							</div>
						</div>
						<div className="form-row mt-3">
							<div className="col-md-6 text-center p-1" onClick={this.onSelectTendency.bind(this, 3)}>
								<div className="topic-name box3 boxName">
									<span>{topics[2]}</span>
								</div>
							</div>

							<div className="col-md-6 text-center p-1" onClick={this.onSelectTendency.bind(this, 4)}>
								<div className="topic-name box4 boxName">
									<span>{topics[3]}</span>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-6 text-center">
						<div className="ml-3">
							<h5 style={{color: "#ff0000"}}><b>Onlayn shartnoma olish bo‘yicha yo‘riqnoma</b></h5>
							<video width="100%" height="100%" controls>
								<source src="/files/guide.mp4" type="video/mp4"/>
							</video>
						</div>
					</div>
				</div>
				<Modal open={this.state.modalOpen} onClose={this.onCloseModal.bind(this, 'modalOpen')} center>
					<div style={{padding: "20px", minWidth: "200px"}}>
						<div className="form-row">
							<div className="col-md-12">
								<h4>{topics[this.state.topicId-1]}</h4>
							</div>
						</div>
						<div className="form-row">
							<div className="col-md-12">
								<ul>
								{this.state.tendencies}
								</ul>
							</div>
						</div>
					</div>
				</Modal>
			</div>
		)
	}
}