import React, {Component} from 'react';
import swal from 'sweetalert';
import axios from 'axios';
import {apiHost, logout, pageNotFound} from "./Utils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import uz from 'date-fns/locale/uz';
import authHeader from "./services/auth-header";
registerLocale('uz', uz);
setDefaultLocale('uz');

export default class AddGroupView extends Component {

	constructor() {
		super();
        let urlParams = new URLSearchParams(window.location.search);
        let groupType = urlParams.get('type');
		this.state = {
            groupType: groupType,
			tendencies: [],
			startDate: new Date(),
			endDate: new Date(),
			examDate: new Date(),
		}
	}

	componentDidMount() {
		axios.get(apiHost + '/api/course/' + this.state.groupType + '/list', authHeader())
			.then(result=>{
				if (result.status === 200) {
					let list = result.data.map(row=>{
						return <option key={row.id} value={row.id}>{row.name}</option>
					});
					this.setState({
						tendencies: list
					})
				}
			}).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		});
	}

	setStartDate(startDate) {
		this.setState({
			startDate: startDate
		})
	}

	setEndDate(endDate) {
		this.setState({
			endDate: endDate
		})
	}

	setExamDate(examDate) {
		this.setState({
			examDate: examDate
		})
	}

	saveCourse() {
		let courseName = document.getElementById('courseName').value;
		let listenerCount = document.getElementById('listenerCount').value;
		if (courseName === null || courseName === '') {
			swal('Guruh nomini kiriting', 'error');
			return;
		}
		if (listenerCount === null || listenerCount === '') {
			swal('Tinglovchi sonini kiriting', 'error');
			return;
		}
		let data = {};
		data.name = courseName;
		data.listenerCount = listenerCount;
		data.startDate = this.state.startDate;
		data.endDate = this.state.endDate;
		data.examDate = this.state.examDate;
		data.tendency = {
			"id": document.getElementById('tendency').value
		};
		axios.post(apiHost + '/api/course/group/add', data, authHeader())
			.then(result=>{
				if (result.status === 200) {
					swal("Guruh qo`shildi", '', 'success');
					window.open(window.location.protocol + '//' + window.location.host + '/groups', '_self');
				} else {
					swal("Guruh qo`shishda xatolik", '', 'error');
				}
			}).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		});
	}

	render() {
		pageNotFound();
		return (
			<div className="content">
				<div className="container card card-body mt-1 shadow-sm">
					<div className="form-row">
						<div className="col-md-12 text-center">
							<h3>Yangi guruh qo`shish</h3>
						</div>
					</div>
					<div className="form-row">
						<div className="col-md-6">
							<label htmlFor="courseName">Yo`nalish nomi</label>
							<select id="tendency" className="form-control">
								{this.state.tendencies}
							</select>
						</div>
						<div className="col-md-6">
							<label htmlFor="courseName">Guruh nomi</label>
							<input id="courseName" className="form-control"/>
						</div>
					</div>
					<div className="form-row mt-4">
						<div className="col-md-2">
							<label htmlFor="listenerCount">Tinglovchi soni</label>
							<input id="listenerCount" type="number" className="form-control"/>
						</div>
						<div className="col-md-3">
							<label htmlFor="startDate">Boshlanish vaqti</label>
							<DatePicker className="form-control" id="startDate" locale="uz" dateFormat="dd.MM.yyyy" selected={this.state.startDate !== undefined && this.state.startDate !== null ? this.state.startDate : new Date()} onChange={this.setStartDate.bind(this)}/>
						</div>
						<div className="col-md-3">
							<label htmlFor="endDate">Yakunlanish vaqti</label>
							<DatePicker className="form-control" id="endDate" locale="uz" dateFormat="dd.MM.yyyy" selected={this.state.endDate !== undefined && this.state.endDate !== null ? this.state.endDate : new Date()} onChange={this.setEndDate.bind(this)}/>
						</div>
						<div className="col-md-3">
							<label htmlFor="examDate">Imtihon vaqti</label>
							<DatePicker className="form-control" id="examDate" locale="uz" dateFormat="dd.MM.yyyy" selected={this.state.examDate !== undefined && this.state.examDate !== null ? this.state.examDate : new Date()} onChange={this.setExamDate.bind(this)}/>
						</div>
					</div>
					<div className="form-row">
						<div className="col-md-12 text-center mt-3">
							<button className="btn btn-success" onClick={this.saveCourse.bind(this)}>Saqlash</button>
						</div>
					</div>
				</div>
			</div>
		)
	}
}