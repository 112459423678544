import React, {Component} from 'react';
import {apiHost, logout, pageNotFound, topics} from "./Utils";
import swal from 'sweetalert';
import axios from 'axios';
import authHeader from "./services/auth-header";

export default class AddTendencyView extends Component {

	constructor(props) {
		super(props);
		this.state = {
			tendency: {
				id: null,
				topic: {
					id: '',
					name: ''
				},
				name: '',
				price: '',
				hours: '',
			}
		};
	}

	componentDidMount() {
		let urlParams = new URLSearchParams(window.location.search);
		let id = urlParams.get('id');
		if (id !== undefined && id !== null && id !== '' && Number(id) > 0) {
			axios.get(apiHost + '/api/admin/tendency/item?id=' + id, authHeader())
				.then(result => {
					if (result.status === 200) {
						this.setState({
							tendency: result.data
						})
					} else {
						swal(result.data.message, '', 'error');
					}
				}).catch(error=>{
				if (error !== undefined && error !== null && error.response.status === 401) {
					logout();
				}
			})
		}
	}

	saveTendency() {
		let topicId = document.getElementById('topic').value;
		if (topicId === null || topicId === 0) {
			swal('Turini tanlang', '', 'error');
			return;
		}
		let name = document.getElementById('name').value;
		if (name === null || name === '') {
			swal('Nomini kiriting', '', 'error');
			return;
		}
		let price = document.getElementById('price').value;
		let hours = document.getElementById('hours').value;
		if (hours === null || hours === '') {
			swal('Soatini kiriting', '', 'error');
			return;
		}
		let payable = document.getElementById('payable').value;
		let data = {
			'name': name,
			'price': price,
			'hours': hours,
			'payable': payable
		}
		if (this.state.tendency.id === null) {
			data.topicId = topicId;
			axios.post(apiHost + '/api/admin/tendency/add', data, authHeader())
				.then(result => {
					if (result.status === 200) {
						swal(result.data.message, '', 'success');
					} else {
						swal(result.data.message, '', 'error');
					}
				}).catch(error=>{
				if (error !== undefined && error !== null && error.response.status === 401) {
					logout();
				}
			})
		} else {
			data.append('id', this.state.tendency.id);
			axios.post(apiHost + '/api/admin/tendency/edit', data, authHeader())
				.then(result => {
					if (result.status === 200) {
						swal(result.data.message, '', 'success');
					} else {
						swal(result.data.message, '', 'error');
					}
				}).catch(error=>{
				if (error !== undefined && error !== null && error.response.status === 401) {
					logout();
				}
			})
		}
	}

	render() {
		pageNotFound();
		return(
			<div className="container card card-body my-4">
				<div className="row">
					<div className="col-md-3">
						<label htmlFor="topic">Turi</label>
						<select id="topic" className="form-control" value={this.state.tendency.topic.id} onChange={e => this.setState({
							tendency: {
								...this.state.tendency,
								topic: {
									id: e.target.value
								}
							}
						})}>
							<option value={0}>Tanlang</option>
							<option value={1}>{topics[0]}</option>
							<option value={2}>{topics[1]}</option>
							<option value={3}>{topics[2]}</option>
							<option value={4}>{topics[3]}</option>
						</select>
					</div>
					<div className="col-md-3">
						<label htmlFor="name">Nomi</label>
						<input id="name" className="form-control" defaultValue={this.state.tendency.name}/>
					</div>
					<div className="col-md-2">
						<label htmlFor="price">Akademik soat</label>
						<input id="hours" type="number" className="form-control" defaultValue={this.state.tendency.hours}/>
					</div>
					<div className="col-md-2">
						<label htmlFor="price">Narxi(1 kishiga)</label>
						<input id="price" type="number" className="form-control" defaultValue={this.state.tendency.price}/>
					</div>
					<div className="col-md-2">
						<label htmlFor="payable">O`qitish tartibi</label>
						<select id="payable" className="form-control">
							<option value={1}>Pulli</option>
							<option value={0}>Tekin</option>
						</select>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12 text-center mt-3">
						<button className="btn btn-info" onClick={this.saveTendency.bind(this)}>Saqlash</button>
					</div>
				</div>
			</div>
		)
	}
}