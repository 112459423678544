import React, {Component} from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import {apiHost, pageNotFound} from "./Utils";
import Modal from 'react-responsive-modal';

export default class AnswerToQuestionView extends Component {

	constructor() {
		super();
		this.state = {
			tableRows: [],
			questionId: null,
			modalOpen: false
		}
	}

	componentDidMount() {
		this.loadData();
	}

	loadData() {
		axios.get(apiHost + '/chat/list')
			.then(result=>{
				if (result.status === 200) {
					if (result.data !== undefined && result.data !== null && result.data.length > 0) {
						let rows = result.data.map(row=>{
							return <tr>
								<td><span>{row.id}</span></td>
								<td><span>{row.questionDate}</span></td>
								<td><span>{row.question}</span></td>
								<td>
									{(row.answer === undefined || row.answer === null || row.answer === '') &&
									<button className="btn btn-info" onClick={this.openModal.bind(this, row.id, row.question)}>Javob berish</button>
									}
									{(row.answer !== '') &&
									<span>{row.answer}</span>
									}
								</td>
							</tr>
						});
						this.setState({
							tableRows: rows
						})
					}
				}
			})
	}

	openModal(questionId, question) {
		this.setState({
			questionId: questionId,
			question: question,
			modalOpen: true
		})
	}

	closeModal() {
		this.setState({
			questionId: null,
			question: null,
			modalOpen: false
		})
	}

	answerToQuestion() {
		let answer = document.getElementById('answer').value;
		let data = new FormData();
		data.append('questionId', this.state.questionId);
		data.append('answer', answer);
		data.append('faq', 2);
		axios.post(apiHost + '/chat/submitAnswer', data)
			.then(result=> {
				if (result.status === 200) {
					this.closeModal();
					this.loadData();
					swal(result.data.message, '', 'success');
				} else {
					swal(result.data.message, '', 'error');
				}
			})
	}

	render() {
		pageNotFound();
		return(
			<div className="container card card-body">
				<div className="row">
					<div className="col-md-12">
						<table id="listenerTable" className="table table-striped">
							<thead>
								<tr>
									<td>№</td>
									<td>Vaqti</td>
									<td>Savol</td>
									<td>Javob</td>
								</tr>
							</thead>
							<tbody>
							{this.state.tableRows}
							</tbody>
						</table>
					</div>
				</div>
				<Modal open={this.state.modalOpen} onClose={this.closeModal.bind(this)} center>
					<div style={{padding: "20px"}}>
						<div className="form-row">
							<div className="col-md-12"><h5>{this.state.question}</h5></div>
						</div>
						<div className="form-row">
							<div className="col-md-12 mt-3">
								<label htmlFor="answer">Javob:</label>
								<textarea id="answer" className="form-control"/>
							</div>
						</div>
						<div className="form-row">
							<div className="col-md-12 mt-3">
								<button className="btn btn-success ml-1" onClick={this.answerToQuestion.bind(this)}>Javob berish</button>
							</div>
						</div>
					</div>
				</Modal>
			</div>
		)
	}
}