import React, {Component} from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import * as _ from './Utils';
import Cookies from 'universal-cookie';
import './styles/loading.sass';
import DatePicker, {registerLocale, setDefaultLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Modal} from "react-responsive-modal";
import ReactPaginate from 'react-paginate';
import uz from 'date-fns/locale/uz';
import getTime from 'date-fns/getTime';
import authHeader from "./services/auth-header";
import {apiHost, logout, pageNotFound} from "./Utils";
registerLocale('uz', uz);
setDefaultLocale('uz');

const cookies = new Cookies();

class AgreementList extends Component {

	constructor() {
		super();
		let user = localStorage.getItem('user');
		if (user !== undefined && user !== null) {
			user = JSON.parse(user);
		}
        let height = window.screen.height - 370;
		this.state = {
			openAddModal: false,
			showLoading: true,
			user: user,
			tableRows: [],
			categories: [],
			tendencies: [],
            agreementTypes: [],
			searchKeyword: '',
            startDate: new Date(2020, 10, 1, 0, 0, 0, 0),
			endDate: new Date(),
			regionId: null,
			statusId: null,
			categoryId: null,
			agreementDate: new Date(),

            pageCount: 1,
            perPage: 50,
            data: [],
            offset: 50,
            start: 0,
            maxHeight: height
		};
		this.search = this.search.bind(this);
		this.onChangeValue = this.onChangeValue.bind(this);
	}

	setAgreementDate(agreementDate) {
		this.setState({
			agreementDate: agreementDate
		});
	}

	setStartDate(startDate) {
		this.setState({
			startDate: startDate
		});
		this.getData(startDate, this.state.endDate, this.state.start, this.state.offset);
	}

	setEndDate(endDate) {
		this.setState({
			endDate: endDate
		});
		this.getData(this.state.startDate, endDate, this.state.start, this.state.offset);
	}

	openPage(agreementId) {
		localStorage.setItem('agreementId', agreementId);
		window.open(window.location.protocol + '//' + window.location.host + '/agreementView', '_blank');
	}

	addListener(agreementId) {
		localStorage.setItem('agreementId', agreementId);
		window.open(window.location.protocol + '//' + window.location.host + '/addListener', '_self');
	}

	downloadAgreement(downloadLink) {
		axios({
			url: apiHost + '/api/agreement/download/' + downloadLink,
			method: "GET",
			headers: authHeader().headers,
			responseType: "blob"
		})
			.then(result => {
				const link = document.createElement("a");
				link.href = window.URL.createObjectURL(new Blob([result.data]));
				link.setAttribute(
					'download',
					downloadLink + '.pdf'
				);

				document.body.appendChild(link);

				link.click();
				link.parentNode.removeChild(link);
			})
	}

	componentDidMount() {
		axios.get(_.apiHost + '/api/agreement/list?start=' + this.state.start + '&offset=' + this.state.offset, authHeader())
			.then(result => {
				this.populateData(result);
			});
		axios.get(_.apiHost + '/api/agreement/topicTendencies', authHeader())
			.then(result => {
				if (result.data !== null && result.data.length>0) {
					let items = result.data.map(item=>{
						return <option key={'i' + item.id} value={item.id}>{item.name}</option>
					});
					items.unshift(<option key="i0" value="0">Hammasi</option>);
					this.setState({
						categories: items
					})
				} else {
					this.setState({
						categories: []
					})
				}
			}).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		});
		axios.get(_.apiHost + '/api/course/tendency/list',authHeader())
			.then(result=>{
				if (result.status === 200) {
					let list = result.data.map(row=>{
						return <option key={row.id} value={row.id}>{row.name}</option>
					});
					this.setState({
						tendencies: list
					})
				}
			}).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		})
	}

    handlePageClick = (data) => {
        let selected = data.selected;
        let offset = Math.ceil((selected + 1) * this.state.perPage);
        let start = selected === 0 ? 0 : selected * this.state.perPage + 1;

        this.setState({
            offset: offset,
            start: start
        });
        this.getData(this.state.startDate, this.state.endDate, start, offset);
    };

	onChangeValue(e) {
		this.setState({
			searchKeyword: e.target.value
		})
	}

	editAgreement(agreementId) {
		localStorage.setItem('agreementId', agreementId);
		window.open(window.location.protocol + '//' + window.location.host + '/agreementEdit', '_self');
	}

	removeAgreement(agreement) {
        Swal.fire({
            title: 'Siz haqiqatdan ham ' + agreement.agreementNumber + ' raqamli shartnomani o`chirmoqchimisiz?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'O`chirish',
            cancelButtonText: 'Bekor qilish'
        }).then((result1) => {
            if (result1.value) {
                axios.get(_.apiHost + '/api/agreement/remove?agreementId=' + agreement.id, authHeader())
                    .then(result => {
                        if (result.status === 200) {
                            Swal.fire(
                                'O`chirildi!',
                                result.data.message,
                                'success'
                            );
                            this.getData(null, null, this.state.start, this.state.offset);
                        } else {
                            Swal.fire(
                                'Xatolik',
                                result.data.message,
                                'error'
                            )
                        }
                    }).catch(error=>{
					if (error !== undefined && error !== null && error.response.status === 401) {
						logout();
					}
				});
            } else if (result1.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Bekor qilindi',
                    '',
                    'error'
                )
            }
        });
	}

	populateData(result) {
        let options = [];
        if (result.status === 200) {
			if (result.data.data !== undefined && result.data.data !== null) {
				options = result.data.data.map(row=> {
					return <tr role="row" key={row.id}>
						<td className="align-middle">{row.id}</td>
						<td className="align-middle">
							<span className="linkStyle" onClick={this.openPage.bind(this, row.id)}>{row.organizationName}</span>
						</td>
						<td className="align-middle">
							<span className="linkStyle" onClick={this.openPage.bind(this, row.id)}>{row.agreementNumber}</span>
						</td>
						<td className="align-middle">{row.agreementDate}</td>
						<td className="align-middle">{row.solution}</td>
						<td className="align-middle">{row.phoneNumber}</td>
						<td className="align-middle">{row.tendency !== undefined && row.tendency !== null ? row.tendency.name : ''}</td>
						{this.state.user.roles[0] === 'ACCOUNTANT' &&
						<td className="align-middle">{row.allAmount}</td>
						}
						{this.state.user.roles[0] === 'ACCOUNTANT' &&
						<td className="align-middle">{row.payedAmount}</td>
						}

						<td className="align-middle">{row.payedAmountPercent + '%'}</td>

						{(this.state.user.roles[0] === 'MARKETING' || this.state.user.roles[0] === 'ACCOUNTANT') &&
						<td className="align-middle text-center">
							<i className="linkStyle fa fa-plus-square-o fa-fw" onClick={this.addListener.bind(this, row.id)} title="Tinglovchi qo`shish"/>
							<i className="linkStyle fa fa-download fa-fw" onClick={this.downloadAgreement.bind(this, row.downloadLink)} title="Ko`chirib olish"/>
							<i className="linkStyle fa fa-edit fa-fw" onClick={this.editAgreement.bind(this, row.id)} title="Tahrirlash"/>
							<i className="linkStyle fa fa-remove fa-fw" onClick={this.removeAgreement.bind(this, row)} title="O`chirish"/>
						</td>
						}
					</tr>
				});
			}
            this.setState({
                tableRows: options,
                pageCount: result.data.pageCount,
                showLoading: false
            })
		} else {
			this.setState({
                pageCount: 1,
				tableRows: []
			})
		}
	}

	search() {
		if (this.state.searchKeyword !== '') {
			this.setState({
				showLoading: true
			});
			axios.get(_.apiHost + '/api/agreement/search?keyword=' + this.state.searchKeyword, authHeader())
				.then(result => {
					this.populateData(result);
				}).catch(error=>{
				if (error !== undefined && error !== null && error.response.status === 401) {
					logout();
				}
			})
		} else {
            this.getData(this.state.startDate, this.state.endDate, 0, 1000000);
		}
	}

	getData(sDate, eDate, start, offset) {
		this.setState({
			showLoading: true
		});
		let regionId = document.getElementById('regions').value;
		let statusId = document.getElementById('paymentStatus').value;
		let categoryId = document.getElementById('category').value;
		let startDate = sDate !== null && sDate !== '' ? getTime(sDate) : '';
		let endDate = eDate !== null && eDate !== '' ? getTime(eDate) : '';

		this.setState({
			regionId: regionId,
			statusId: statusId,
			categoryId: categoryId,
			startDate: startDate,
			endDate: endDate
		})

		axios.get(_.apiHost + '/api/agreement/list?startDate=' + startDate + '&endDate=' + endDate + '&regionId=' + regionId +
			'&statusId=' + statusId + '&categoryId=' + categoryId + '&start=' + start + '&offset=' + offset, authHeader())
			.then(result => {
				this.populateData(result);
			}).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		})
	}

	openSignPage() {
		window.open(window.location.protocol + '//' + window.location.host + '/saView', '_blank');
	}
	openAddModal(modalName) {
		this.setState({
			[modalName]: true
		});
	}

	onCloseModal(modalName) {
		this.setState({
			[modalName]: false
		});
	}

	exportToExcel() {
		let regionId = document.getElementById('regions').value;
		cookies.set('regionId', regionId, {path: '/'});
		let statusId = document.getElementById('paymentStatus').value;
		cookies.set('statusId', statusId, {path: '/'});
		let categoryId = document.getElementById('category').value;
		cookies.set('categoryId', categoryId, {path: '/'});
		cookies.set('startDate', getTime(this.state.startDate), {path: '/'});
		cookies.set('endDate', getTime(this.state.endDate), {path: '/'});
		let form = document.getElementById('excelForm');
		form.submit();
	}

	addAgreement() {
		let tendencyType = document.getElementById('tendencyType').value;
        let tendencyId = document.getElementById('tendency').value;
        if (tendencyId === null || Number(tendencyId) === 0) {
            swal('Yo`nalishni tanlang', '', 'error');
            return;
        }
        let regionId = document.getElementById('region').value;
        if (regionId === null || Number(regionId) === 0) {
            swal('Hududni tanlang', '', 'error');
            return;
        }
        let paymentPercent = document.getElementById('paymentPercent').value;

		let agreementDate = this.state.agreementDate;
		if (agreementDate === null || agreementDate === '') {
			swal('Shartnoma vaqtini kiriting', '', 'error');
			return;
		}
		let organizationName = document.getElementById('organizationName').value;
		if (organizationName === null || organizationName === '') {
			swal('Tashkilot nomini kiriting', '', 'error');
			return;
		}
		let pupilCount = document.getElementById('listenerCount').value;
		if (pupilCount === null || pupilCount === '' || Number(pupilCount) <= 0) {
			swal('Tinglovchi soni kiriting', '', 'error');
			return;
		}
		let organizationDirector = document.getElementById('organizationDirector').value;
        if (organizationDirector === null || organizationDirector === '') {
            swal('Tashkilot rahbarini FISHni kiriting', '', 'error');
            return;
        }
		let address = document.getElementById('address').value;
        if (address === null || address === '') {
            swal('Tashkilot manzilini kiriting', '', 'error');
            return;
        }
		let bankName = document.getElementById('bankName').value;
        if (bankName === null || bankName === '') {
            swal('Bank nomini kiriting', '', 'error');
            return;
        }
		let bankAccount = document.getElementById('bankAccount').value;
        if (bankAccount === null || bankAccount === '') {
            swal('Hisob raqamini kiriting', '', 'error');
            return;
        }
		let inn = document.getElementById('inn').value;
		if (inn === null || inn === '') {
			swal('INN raqamini kiriting', '', 'error');
			return;
		}
		let mfo = document.getElementById('mfo').value;
		if (mfo === null || mfo === '') {
			swal('MFO raqamini kiriting', '', 'error');
			return;
		}
		let phoneNumber = document.getElementById('phoneNumber').value;
        if (phoneNumber === null || phoneNumber === '') {
            swal('Telefon raqamini kiriting', '', 'error');
            return;
        }
		let data = new FormData();
		data.append('categoryId', tendencyType);
		data.append('tendencyId', tendencyId);
        data.append('regionId', regionId);
        data.append('paymentPercent', paymentPercent);
        data.append('agreementDate', agreementDate);
        data.append('organizationName', organizationName);
        data.append('organizationDirector', organizationDirector);
        data.append('pupilCount', pupilCount);
        data.append('address', address);
        data.append('bankName', bankName);
        data.append('bankAccount', bankAccount);
        data.append('inn', inn);
        data.append('mfo', mfo);
        data.append('phoneNumber', phoneNumber);

		axios.post(_.apiHost + '/api/agreement/addCustomAgreement', data, authHeader())
			.then(result => {
				if (result.status === 200) {
					swal(result.data.message, '', 'success');
				} else {
					swal(result.data.message, '', 'error');
				}
			}).catch(error => {
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		})
	}

    getTendencies() {
	    let tendencyType = document.getElementById('tendencyType').value;
        axios.get(_.apiHost + '/api/agreement/topicTendencies?id=' + tendencyType, authHeader())
			.then(result => {
            if (result.status === 200) {
                if (result.data !== undefined && result.data !== null) {
                    let items = result.data.map(item=>{
                        return <option key={item.id} value={item.id}>{item.name}</option>
                    });
                    this.setState({
                        agreementTypes: items,
                    });
                    // if (items.length > 0) {
                    //     document.getElementById('tendency').value = items[0];
                    // }
                }
            }
        }).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		});
    }

	render() {
		pageNotFound();
		return (
			<div className="content">
				{this.state.showLoading &&
				<div className="container">
					<div className="loader">
						<div className="loader--dot"/>
						<div className="loader--dot"/>
						<div className="loader--dot"/>
						<div className="loader--dot"/>
						<div className="loader--dot"/>
						<div className="loader--dot"/>
						<div className="loader--text"/>
					</div>
				</div>
				}
				<div className="card card-body shadow-sm" style={{paddingBottom: "0"}}>
					<div className="form-row">
						<div className="col-md-1 p-0">
							<button className="btn btn-primary" onClick={this.openSignPage.bind(this)}>
								<i className="fa fa-address-card-o"/></button>
						</div>
						<div className="col-md-5 text-right">
							<h3>Shartnomalar ro`yxati</h3>
						</div>
						<div className="col-md-6">
							<form className="form-inline float-right">
								<div className="input-group">
									<input className="form-control" id="searchBox" placeholder="Qidiruv so`zi"
									       aria-label="Qidirish" aria-describedby="basic-addon2" type="text"
									       onChange={this.onChangeValue}/>
									<div className="input-group-append">
										<button className="btn btn-outline-info" type="button"
										        onClick={this.search}><i className="fa fa-search"/></button>
									</div>
								</div>
							</form>
						</div>
					</div>
					<div className="form-row mt-1">
						<div className="col-md-12 text-right">
							<div className="input-group shadow-sm">
								{this.state.user.roles[0] === 'MARKETING' &&
								<button className="mr-1 btn btn-info" onClick={this.openAddModal.bind(this, 'openAddModal')} title="Shartnoma qo`shish"><i
									className="fa fa-plus-square-o"/></button>
								}
								<DatePicker className="form-control" id="startDate" locale="uz" dateFormat="dd.MM.yyyy" selected={this.state.startDate} onChange={this.setStartDate.bind(this)}/>
								<DatePicker className="form-control" id="endDate" locale="uz" dateFormat="dd.MM.yyyy" selected={this.state.endDate} onChange={this.setEndDate.bind(this)}/>
								<select id="regions" className="form-control" defaultValue={this.state.regionId} onChange={this.getData.bind(this, this.state.startDate, this.state.endDate, this.state.start, this.state.offset)}>
									{_.getRegions()}
								</select>
								<select id="paymentStatus" className="form-control" defaultValue={this.state.statusId} onChange={this.getData.bind(this, this.state.startDate, this.state.endDate, this.state.start, this.state.offset)}>
									<option value="0">Hammasi</option>
									<option value="1">To`lagan</option>
									<option value="2">Qisman to`lagan</option>
									<option value="3">To`lamagan</option>
								</select>
								<select id="category" className="form-control" defaultValue={this.state.categoryId} onChange={this.getData.bind(this, this.state.startDate, this.state.endDate, this.state.start, this.state.offset)}>
									{this.state.categories}
								</select>
								<form id="excelForm" method="post" action={_.apiHost + "/common/agreementListReportExcel"}>
									<button className="ml-1 btn btn-info" onClick={this.exportToExcel.bind(this)}><i className="fa fa-file-excel-o"/></button>
								</form>
							</div>
						</div>
					</div>
					<div className="form-row mt-1">
						<div className="col-md-12 order-md-1 pt-2" style={{overflowY: "scroll", maxHeight: this.state.maxHeight + "px"}}>
							<table className="table table-striped table-bordered shadow" style={{tableLayout:"fixed"}}>
								<thead>
								<tr>
									<th scope="col" className="align-middle" style={{width: "5%"}}>№</th>
									<th scope="col" className="align-middle">Tashkilot nomi</th>
									<th scope="col" className="align-middle">Shartnoma raqami</th>
									<th scope="col" className="align-middle">Shartnoma vaqti</th>
									<th scope="col" className="align-middle" style={{width: "9%"}}>INN</th>
									<th scope="col" className="align-middle">Telefon raqami</th>
									<th scope="col" className="align-middle">Yo`nalishi</th>
									{this.state.user.roles[0] === 'ACCOUNTANT' &&
									<th scope="col" className="align-middle">Jami summa</th>
									}
									{this.state.user.roles[0] === 'ACCOUNTANT' &&
									<th scope="col" className="align-middle">To`langan summa</th>
									}
									<th scope="col" className="align-middle" style={{width: "9%"}}>To`langan summa, %</th>
									{(this.state.user.roles[0] === 'ACCOUNTANT' || this.state.user.roles[0] === 'MARKETING') &&
									<th scope="col" className="align-middle" style={{width: "9%"}}>Amal</th>
									}
								</tr>
								</thead>
								<tbody>
								{this.state.tableRows}
								</tbody>
							</table>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12 order-md-1 text-center">
							<ReactPaginate
								previousLabel={'Oldingi'}
								nextLabel={'Keyingi'}
								breakLabel={'...'}
								breakClassName={'break-me'}
								pageCount={this.state.pageCount}
								marginPagesDisplayed={1}
								pageRangeDisplayed={5}
								onPageChange={this.handlePageClick}
								containerClassName={'pagination'}
								subContainerClassName={'pagination'}
								activeClassName={'active'}
							/>
						</div>
					</div>
				</div>
				<Modal open={this.state.openAddModal} onClose={this.onCloseModal.bind(this, 'openAddModal')} center>
					<div style={{padding: "20px"}}>
						<div className="form-row mb-3">
                            <div className="col-md-12 text-center"><h4>Shartnoma qo`shish</h4></div>
                        </div>
						<div className="form-row">
                            <div className="col-md-4">
                                <label htmlFor="tendencyType">Yo`nalish turi</label>
                                <select className="form-control" id="tendencyType" onChange={this.getTendencies.bind(this)}>
                                    <option value={2}>Qayta tayyorlash kurslari</option>
                                    <option value={3}>Malaka oshirish kurslari</option>
                                    <option value={4}>Qisqa muddatli kurslar</option>
                                </select>
                            </div>
                            <div className="col-md-8">
                                <label htmlFor="tendency">Yo`nalish nomi</label>
                                <select className="form-control" id="tendency">
                                    {this.state.agreementTypes}
                                </select>
                            </div>
                        </div>
						<div className="form-row mt-2">
							<div className="col-md-5">
								<label htmlFor="region">Hudud</label>
								<select id="region" className="form-control">
									{_.getRegions()}
								</select>
							</div>
							<div className="col-md-2">
								<label htmlFor="paymentPercent">To`lov foizi</label>
								<select className="form-control" id="paymentPercent">
									<option value={0}>0%</option>
									<option value={30}>30%</option>
									<option value={100}>100%</option>
								</select>
							</div>
                            <div className="col-md-3">
                                <label htmlFor="agreementDate">Shartnoma sanasi</label>
                                <br/>
                                <DatePicker className="form-control" id="agreementDate" locale="uz" dateFormat="dd.MM.yyyy" selected={this.state.agreementDate} onChange={this.setAgreementDate.bind(this)}/>
                            </div>
                            <div className="col-md-2">
                                <label htmlFor="listenerCount">Tinglovchi soni</label>
                                <input type="number" className="form-control" id="listenerCount"/>
                            </div>
						</div>
						<div className="form-row mt-2">
							<div className="col-md-12">
								<label htmlFor="organizationName">Tashkilot nomi</label>
								<input id="organizationName" className="form-control"/>
							</div>
						</div>
						<div className="form-row mt-2">
							<div className="col-md-6">
								<label htmlFor="organizationDirector">Tashkilot rahbari</label>
								<input id="organizationDirector" className="form-control"/>
							</div>
							<div className="col-md-6">
								<label htmlFor="address">Tashkilot manzili</label>
								<input id="address" className="form-control"/>
							</div>
						</div>
						<div className="form-row mt-2">
							<div className="col-md-6">
								<label htmlFor="bankName">Bank nomi</label>
								<input id="bankName" className="form-control"/>
							</div>
							<div className="col-md-4">
								<label htmlFor="bankAccount">Hisob raqami</label>
								<input id="bankAccount" maxLength={27} className="form-control"/>
							</div>
                            <div className="col-md-2">
                                <label htmlFor="inn">INN</label>
                                <input id="inn" maxLength="9" className="form-control"/>
                            </div>
                        </div>
                        <div className="form-row mt-2">
                            <div className="col-md-6">
                                <label htmlFor="mfo">MFO</label>
                                <input id="mfo" maxLength="5" className="form-control"/>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="phoneNumber">Telefon raqami</label>
                                <input id="phoneNumber" maxLength={15} className="form-control"/>
                            </div>
						</div>
						<div className="form-row mt-2 text-center">
							<div className="col-md-12">
								<button className="btn btn-success" onClick={this.addAgreement.bind(this)}>Saqlash</button>
							</div>
						</div>
					</div>
				</Modal>
			</div>
		)
	}
}

export default AgreementList;