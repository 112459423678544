import React, {Component} from "react";
import axios from "axios";
import {apiHost, logout, pageNotFound} from "./Utils";
import ReactPaginate from 'react-paginate';
import swal from 'sweetalert';
import Moment from "react-moment";
import authHeader from "./services/auth-header";

export default class DeletedObjectsList extends Component {

    constructor() {
        super();
        this.state = {
            objectType: '',
            pageCount: 1,
            offset: 50,
            start: 0,
            showLoading: false
        };
    }

    setObjectType(objectType) {
        this.setState({
            objectType: objectType
        });
        this.getData(objectType);
    }

    getData(objectType) {
        this.setState({
            showLoading: true
        });
        let path = '';
        if (objectType === 'listeners') {
            path = '/api/listener/deleted/list';
        } else if (objectType === 'groups') {
            path = '/api/course/deleted/list';
        } else if (objectType === 'tendency') {
            path = '/api/course/deleted/tendency/list';
        } else if (objectType === 'agreement') {
            path = '/api/agreement/deleted/list';
        }
        axios.get(apiHost + path + '?start=' + this.state.start + '&offset=' + this.state.offset, authHeader()).then(result => {
            let items = [];
            if (result.data.data !== null && result.data.data.length>0) {
                if (objectType === 'listeners') {
                    items = result.data.data.map((row, index) => {
                        return <tr key={'pu_' + index}>
                            <td>{index + 1}</td>
                            <td>{row.lastName} {row.firstName} {row.middleName}</td>
                            <td>{row.passportNumber}</td>
                            <td>{row.phoneNumber}</td>
                            <td>{row.agreement !== undefined && row.agreement !== null ? row.agreement.name : ''}</td>
                            <td>{row.tendencyName}</td>
                            <td>{row.deletedBy !== undefined && row.deletedBy !== null ? row.deletedBy.name : ''}</td>
                            <td className="text-center">
                                <button className="btn btn-success" title="Qayta tiklash" onClick={this.restoreObject.bind(this, 'listeners', row)}><i
                                    className="fa fa-reply"/></button>
                            </td>
                        </tr>
                    });
                } else if (objectType === 'groups') {
                    items = result.data.map((row, index) => {
                        return <tr key={'pu_' + index}>
                            <td>{index + 1}</td>
                            <td>{row.name}</td>
                            <td>{row.listenerCount}</td>
                            <td>{row.status !== undefined && row.status !== null ? row.status.name : ''}</td>
                            <td>{row.startDate !== null ? <Moment format="DD.MM.YYYY">{row.startDate}</Moment> : ''}</td>
                            <td>{row.endDate !== null ? <Moment format="DD.MM.YYYY">{row.endDate}</Moment> : ''}</td>
                            <td>{row.deletedBy !== undefined && row.deletedBy !== null ? row.deletedBy.name : ''}</td>
                            <td className="text-center">
                                <button className="btn btn-success" title="Qayta tiklash" onClick={this.restoreObject.bind(this, 'groups', row)}><i
                                    className="fa fa-reply"/></button>
                            </td>
                        </tr>
                    });
                } else if (objectType === 'tendency') {
                    items = result.data.map((row, index) => {
                        return <tr key={'pu_' + index}>
                            <td>{index + 1}</td>
                            <td>{row.name}</td>
                            <td>{row.topic !== undefined && row.topic !== null ? row.topic.name : ''}</td>
                            <td>{row.deletedBy !== undefined && row.deletedBy !== null ? row.deletedBy.name : ''}</td>
                            <td className="text-center">
                                <button className="btn btn-success" title="Qayta tiklash" onClick={this.restoreObject.bind(this, 'tendency', row)}><i
                                    className="fa fa-reply"/></button>
                            </td>
                        </tr>
                    });
                } else if (objectType === 'agreement') {
                    items = result.data.map((row, index) => {
                        return <tr key={'pu_' + index}>
                            <td>{index + 1}</td>
                            <td>{row.organizationName}</td>
                            <td>{row.agreementNumber}</td>
                            <td>{row.agreementDate}</td>
                            <td>{row.phoneNumber}</td>
                            <td>{row.tendency !== undefined && row.tendency !== null ? row.tendency.name : ''}</td>
                            <td>{row.deletedBy !== undefined && row.deletedBy !== null ? row.deletedBy.name : ''}</td>
                            <td className="text-center">
                                <button className="btn btn-success" title="Qayta tiklash" onClick={this.restoreObject.bind(this, 'agreement', row)}><i
                                    className="fa fa-reply"/></button>
                            </td>
                        </tr>
                    });
                }
            }
            this.setState({
                listeners: items,
                pageCount: result.data.pageCount,
                showLoading: false
            })
        }).catch(error=>{
            if (error !== undefined && error !== null && error.response !== undefined && error.response !== null && error.response.status === 401) {
                logout();
            }
        });
    }

    restoreObject(objectType, listener) {
        let path = '';
        if (objectType === 'listeners') {
            path = '/api/listener/restore?listenerId=' + listener.id;
        } else if (objectType === 'groups') {
            path = '/api/course/restore?groupId=' + listener.id;
        } else if (objectType === 'tendency') {
            path = '/api/course/tendency/restore?tendencyId=' + listener.id;
        } else if (objectType === 'agreement') {
            path = '/api/agreement/restore?agreementId=' + listener.id;
        }
        axios.post(apiHost + path, null, authHeader())
            .then(result => {
                if(result.status === 200) {
                    swal(result.data.message, '', 'success');
                } else  {
                    swal(result.data.message, '', 'error');
                }
            }).catch(error=>{
            if (error !== undefined && error !== null && error.response.status === 401) {
                logout();
            }
        });
    }

    handlePageClick = (data) => {
        let selected = data.selected;
        let offset = Math.ceil((selected + 1) * this.state.perPage);
        let start = selected === 0 ? 0 : selected * this.state.perPage + 1;

        this.setState({
            offset: offset,
            start: start
        });
        this.getData(this.state.startDate, this.state.endDate, start, offset);
    };

    render() {
        pageNotFound();
        return (
            <div className="card card-body mt-1 shadow-sm">
                {this.state.showLoading &&
                    <div className="container">
                        <div className="loader">
                            <div className="loader--dot"/>
                            <div className="loader--dot"/>
                            <div className="loader--dot"/>
                            <div className="loader--dot"/>
                            <div className="loader--dot"/>
                            <div className="loader--dot"/>
                            <div className="loader--text"/>
                        </div>
                    </div>
                }
                <div className="form-row">
                    <div className="col-md-3">
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="listenersRB" onClick={this.setObjectType.bind(this, 'listeners')}/>
                            <label className="form-check-label" htmlFor="listenersRB">
                                Tinglovchilar
                            </label>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="groupsRB" onClick={this.setObjectType.bind(this, 'groups')}/>
                            <label className="form-check-label" htmlFor="groupsRB">
                                Guruhlar
                            </label>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="tendencyRB" onClick={this.setObjectType.bind(this, 'tendency')}/>
                            <label className="form-check-label" htmlFor="tendencyRB">
                                Yo`nalishlar
                            </label>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="agreementRB" onClick={this.setObjectType.bind(this, 'agreement')}/>
                            <label className="form-check-label" htmlFor="agreementRB">
                                Shartnomalar
                            </label>
                        </div>
                    </div>
                </div>

                <div className="form-row">
                    <div className="col-md-12 order-md-1 pt-2"
                         style={{overflowY: "scroll", maxHeight: this.state.maxHeight + "px"}}>
                        <table className="table table-striped table-bordered shadow" style={{tableLayout: "fixed"}}>
                            <thead>
                            {this.state.objectType === 'listeners' &&
                                <tr>
                                    <th style={{width: "5%"}}>№</th>
                                    <th>F.I.Sh</th>
                                    <th>Pasport raqami</th>
                                    <th>Telefon raqami</th>
                                    <th>Shartnoma raqami</th>
                                    <th>Yo`nalishi</th>
                                    <th>Kim o`chirgan</th>
                                    <th style={{width: "7%"}}>Amal</th>
                                </tr>
                            }
                            {this.state.objectType === 'groups' &&
                                <tr>
                                    <th style={{width: "5%"}}>№</th>
                                    <th>Guruh nomi</th>
                                    <th>Tinglovchi soni</th>
                                    <th>Statusi</th>
                                    <th>Boshlanish vaqti</th>
                                    <th>Tugash vaqti</th>
                                    <th>Kim o`chirgan</th>
                                    <th style={{width: "7%"}}>Amal</th>
                                </tr>
                            }
                            {this.state.objectType === 'tendency' &&
                                <tr>
                                    <th style={{width: "5%"}}>№</th>
                                    <th>Yo`nalish nomi</th>
                                    <th>Turi</th>
                                    <th>Kim o`chirgan</th>
                                    <th style={{width: "7%"}}>Amal</th>
                                </tr>
                            }
                            {this.state.objectType === 'agreement' &&
                                <tr>
                                    <th style={{width: "5%"}}>№</th>
                                    <th>Tashkilot nomi</th>
                                    <th>Shartnoma raqami</th>
                                    <th>Shartnoma vaqti</th>
                                    <th>Telefon raqami</th>
                                    <th>Yo`nalishi</th>
                                    <th>Kim o`chirgan</th>
                                    <th style={{width: "7%"}}>Amal</th>
                                </tr>
                            }
                            </thead>
                            <tbody>
                            {this.state.listeners}
                            </tbody>
                        </table>
                        <div className="row">
                            <div className="col-md-12 order-md-1 text-center">
                                <ReactPaginate
                                    previousLabel={'Oldingi'}
                                    nextLabel={'Keyingi'}
                                    breakLabel={'...'}
                                    breakClassName={'break-me'}
                                    pageCount={this.state.pageCount}
                                    marginPagesDisplayed={1}
                                    pageRangeDisplayed={5}
                                    onPageChange={this.handlePageClick}
                                    containerClassName={'pagination'}
                                    subContainerClassName={'pagination'}
                                    activeClassName={'active'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}