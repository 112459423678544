import React, {Component} from 'react';
import axios from 'axios';
import AgreementList from "./AgreementList";
import {apiHost, logout, pageNotFound} from "./Utils";
import authHeader from "./services/auth-header";

export default class AccountantHomeView extends Component {

    constructor() {
        super();
        this.state = {
            tableRows: []
        };
    }

    openView(patientId) {
        localStorage.setItem('patientId', patientId);
        window.open(window.location.protocol + '//' + window.location.host + "/agreementView", '_self');
    }

    componentDidMount() {
        pageNotFound();
        axios.get(apiHost + '/api/agreement/list', authHeader())
            .then(result => {
                if (result.status === 200) {
                    let options = [];
                    for (let i = 0; i < result.data.length; i++) {
                        let row = result.data[i];
                        options.push(<tr>
                            <th scope="row">{row.id}</th>
                            <td><span className="linkStyle"
                                      onClick={this.openView.bind(this, row.id)}>{row.lastName}</span></td>
                            <td>{row.firstName}</td>
                            <td>{row.phoneNumber}</td>
                            <td>{row.passportNumber}</td>
                            <td>{row.address}</td>
                        </tr>);
                    }
                    this.setState({
                        tableRows: options
                    })
                } else {
                    this.setState({
                        tableRows: []
                    })
                }
            }).catch(error => {
            if (error !== undefined && error !== null && error.response.status === 401) {
                logout();
            }
        });
    }

    render() {
        let user = JSON.parse(localStorage.getItem('user'));
        if (user === undefined || user === null || !user.roles.includes('ACCOUNTANT')) {
            logout();
        }
        return (
            <AgreementList/>
        )
    }
}