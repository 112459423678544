import React, {Component} from 'react';
import logo from './images/logo.png';
import axios from 'axios';
import swal from "sweetalert";
import {apiHost} from "./Utils";

export default class LoginPage extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showAlert: false,
			modalOpen: false,
			pageName: ''
		}
	}

	login() {
		let userName = document.getElementById('userName').value;
		let password = document.getElementById('password').value;
		let data = {
			"userName": userName,
			"password": password
		}
		axios.post(apiHost + '/api/user/login', data)
			.then(result => {
				if (result.status === 200) {
					localStorage.setItem('user', JSON.stringify(result.data));
					if (result.data.roles[0] === 'ACCOUNTANT') {
						window.open("/accountant", "_self");
					} else if (result.data.roles[0] === 'CONTROLLER') {
						window.open("/control", "_self");
					} else if (result.data.roles[0] === 'MAIN_CONTROLLER') {
						window.open("/maincontrol", "_self");
					} else if (result.data.roles[0] === 'MARKETING') {
						window.open("/marketing", "_self");
					} else if (result.data.roles[0] === 'ADMIN') {
						window.open("/admin", "_self");
					}
				} else if (result.status === 500) {
					localStorage.removeItem('user');
					swal('Login yoki parol xato kiritilgan', '', 'error');
				}
			})
	}

	closeAlert() {
		this.setState({
			showAlert: false
		})
	}

	render() {
		return (
			<div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100vh"}}>
				<div className="form-row">
					<div className="col-md-12">
						<div className="text-center">
							<form className="form-signin" method="post">
								<img className="mb-4" src={logo} alt="" style={{borderRadius: "100px", border: "1px solid #ababab"}}/>
								<h1 className="h3 mb-3 font-weight-normal">Tizimga kirish</h1>
								<label htmlFor="userName" className="sr-only">Login</label>
								<input id="userName" name="userName" className="form-control" placeholder="Login" required="" autoFocus=""/>
								<label htmlFor="password" className="sr-only">Parol</label>
								<input type="password" id="password" name="password" className="form-control" placeholder="Parol" required=""/>
								<button className="btn btn-lg btn-primary btn-block" type="button" onClick={this.login.bind(this)}>Kirish</button>
							</form>
						</div>
					</div>
				</div>
				<footer className="footer container-lg">
					<p className="mt-2 mb-2 text-muted">Yuristlar malakasini oshirish markazi © 2020</p>
				</footer>
			</div>
		)
	}
}