import React, {Component} from 'react';
import axios from 'axios';
import * as _ from './Utils';
import {Link} from "react-router-dom";
import authHeader from "./services/auth-header";
import {logout, pageNotFound} from "./Utils";

class PaidTendencyList extends Component {

	constructor() {
		super();
		let user = localStorage.getItem('user');
		if (user !== undefined && user !== null) {
			user = JSON.parse(user);
		}
		this.state = {
			user: user,
			tableRows: [],
			searchKeyword: ''
		};
	}

	componentDidMount() {
		let user = this.state.user;
		axios.get(_.apiHost + '/api/admin/paidTendency/list', authHeader())
			.then(result => {
				if (result.status === 200) {
					if (result.data !== undefined && result.data !== null) {
						let options = result.data.map(row=> {
							return <tr key={row.id}>
								<td className="align-middle">{row.id}</td>
								<td className="align-middle">
									<Link to={"/tendencyView?id=" + row.id} className="linkStyle">{row.name}</Link>
								</td>
								<td className="align-middle">{row.price}</td>
								<td className="align-middle">{row.topic.name}</td>
								<td className="align-middle">
									{user.roles[0] === 'MARKETING' &&
									<Link to={"/editAgreement?id=" + row.id} className="linkStyle"
									      title="Shartnoma qo‘shish"><span><i className="fa fa-plus-square-o mr-1"/></span></Link>
									}
									{user.roles[0] === 'ACCOUNTANT' &&
									<Link to={"/editAgreementPrice?id=" + row.id} className="linkStyle"
									      title="Tahrirlash"><span><i className="fa fa-edit"/></span></Link>
									}
									{(user.roles[0] === 'MARKETING' || user.roles[0] === 'CONTROLLER' || user.roles[0] === 'MAIN_CONTROLLER') &&
									<Link to={"/addTendency?id=" + row.id} className="linkStyle"
									      title="Tahrirlash"><span><i className="fa fa-edit"/></span></Link>
									}
								</td>
							</tr>;
						});
						this.setState({
							tableRows: options
						})
					}
				} else {
					this.setState({
						tableRows: []
					})
				}
			}).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		})
	}

	render() {
		pageNotFound();
		return (
			<div className="content">
				<div className="card card-body shadow-sm">
					<div className="row">
						<div className="col-md-6 text-right">
							<h3>Yo‘nalishlar ro‘yxati</h3>
						</div>
						<div className="col-md-6 text-right">
							<Link className="btn btn-info" to="/addTendency">Yo‘nalish qo‘shish</Link>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12 pt-2">
							<table className="table table-striped table-bordered shadow">
								<thead>
								<tr>
									<th scope="col" className="align-middle">№</th>
									<th scope="col" className="align-middle">Yo‘nalish nomi</th>
									<th scope="col" className="align-middle">Narxi</th>
									<th scope="col" className="align-middle">Turi</th>
									<th scope="col" className="align-middle">Amal</th>
								</tr>
								</thead>
								<tbody>
								{this.state.tableRows}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default PaidTendencyList;