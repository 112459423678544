import React, {Component} from 'react';
import {apiHost, logout, pageNotFound} from "./Utils";
import swal from 'sweetalert';
import axios from "axios";
import authHeader from "./services/auth-header";

export default class ChangeBHMView extends Component {

    componentDidMount() {
        // axios.get(apiHost)
    }

    saveBHM(){
        let bhm = document.getElementById('bhm').value;
        if (bhm === null || bhm === '') {
            swal('BHM miqdorini kiriting', '', 'error');
        }
        axios.post(apiHost + '/api/admin/updateBHM?bhm=' + bhm, null, authHeader())
            .then(result=>{
                if (result.status === 200) {
                    swal(result.data.message, '', 'success');
                } else {
                    swal(result.data.message, '', 'error');
                }
            }).catch(error=>{
            if (error !== undefined && error !== null && error.response.status === 401) {
                logout();
            }
        })
    }

    render() {
        pageNotFound();
        return (
            <div>
                <div className="form-row">
                    <div className="col-md-12">
                        <label htmlFor="bhm">BHM miqdori</label>
                        <input type="number" id="bhm" className="form-control"/>
                    </div>
                </div>
                <div className="form-row mt-2">
                    <div className="col-md-12 text-center">
                        <button className="btn btn-success" onClick={this.saveBHM.bind(this)}>Saqlash</button>
                    </div>
                </div>
            </div>
        )
    }
}