import React, {Component} from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import {apiHost, logout, pageNotFound} from "./Utils";
import authHeader from "./services/auth-header";

export default class EditAgreementView extends Component {

	constructor() {
		super();
		this.state = {
			tendencyList: [],
			template: {
				agreementTitle: '',
				tendency: {
					id: 0,
					name: ''
				},
				percent: 100,
				hours: '',
				header: '',
				section1Content: '',
				section2Content: '',
				section3Content: '',
				section4Content: '',
				section5Content: '',
				section6Content: '',
				section7Content: '',
				section8Content: '',
				section9Content: ''
			}
		};
		this.setValue = this.setValue.bind(this);
	}

	componentDidMount() {
		this.getData(this.state.template.percent);
	}

	getData(percent) {
		let urlParams = new URLSearchParams(window.location.search);
		let id = urlParams.get('id');
		if (id !== undefined && id !== null && id !== '' && Number(id) > 0) {
			this.setState({
				tendencyId: id
			});
			axios.get(apiHost + '/api/agreement/template/item?tendencyId=' + id + '&percent=' + percent, authHeader())
				.then(result => {
					if (result.status === 200) {
						this.setState({
							template: result.data
						})
					}
				}).catch(error=>{
				if (error !== undefined && error !== null && error.response.status === 401) {
					logout();
				}
			})
		}
	}

	setValue(e) {
		let name = e.target.id;
		let value = e.target.value;
		this.setState({
			[name]: value
		})
	}

	save() {
		if (this.state.template.hours === '') {
			swal('Dars soatini kiriting', '', 'error');
		}
		if (this.state.template.header === '') {
			swal('Shartnoma kirish qismi matnini kiriting', '', 'error');
		}
		if (this.state.template.section1Content === '' || this.state.template.section2Content === '' || this.state.template.section3Content === '' ||
			this.state.template.section4Content === '' || this.state.template.section5Content === '' || this.state.template.section6Content === '' ||
			this.state.template.section7Content === '' || this.state.template.section8Content === '' || this.state.template.section9Content === '') {
			swal('Shartnoma bandlarini matnini kiriting', '', 'error');
		}
		let data = {};
		data.agreementTitle=document.getElementById('agreementTitle').value;
		data.tendency=this.state.template.tendency;
		data.percent=this.state.template.percent;
		data.hours=Number(document.getElementById('hours').value);
		data.header=document.getElementById('header').value;
		data.section1Content=document.getElementById('section1').value;
		data.section2Content=document.getElementById('section2').value;
		data.section3Content=document.getElementById('section3').value;
		data.section4Content=document.getElementById('section4').value;
		data.section5Content=document.getElementById('section5').value;
		data.section6Content=document.getElementById('section6').value;
		data.section7Content=document.getElementById('section7').value;
		data.section8Content=document.getElementById('section8').value;
		data.section9Content=document.getElementById('section9').value;
		axios.post(apiHost + '/api/agreement/template/updateItem', data, authHeader())
			.then(result=>{
				if (result.status === 200) {
					swal(result.data.message, '', 'success');
				} else {
					swal(result.data.message, '', 'error');
				}
			}).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		})
	}

	onPercentChange(percent) {
		this.setState({
			percent: percent
		});
		this.getData(percent);
	}

	render() {
		pageNotFound();
		return(
			<div className="content">
				<div className="container card card-body mb-2 shadow-sm">
					<div className="form-row">
						<div className="col-md-12 text-center">
							<div className="form-check form-check-inline">
								<input className="form-check-input" type="radio" name="inlineRadioOptions"
									   id="inlineRadio1" value="percent100" defaultChecked={true} onClick={this.onPercentChange.bind(this, 100)}/>
									<label className="form-check-label" htmlFor="inlineRadio1">100%</label>
							</div>
							<div className="form-check form-check-inline">
								<input className="form-check-input" type="radio" name="inlineRadioOptions"
									   id="inlineRadio2" value="percent70" onClick={this.onPercentChange.bind(this, 30)}/>
									<label className="form-check-label" htmlFor="inlineRadio2">30%</label>
							</div>
						</div>
					</div>
					<div className="form-row">
						<div className="col-md-12">
							<label htmlFor="agreementTitle">Shartnoma sarlavhasi</label>
							<input id="agreementTitle" className="form-control" defaultValue={this.state.template.agreementTitle} onChange={this.setValue}/>
						</div>
					</div>
					<div className="form-row">
						<div className="col-md-6">
							<label htmlFor="tendency">Yo`nalish</label>
							<label id="tendency" className="form-control">{this.state.template.tendency.name}</label>
						</div>
						<div className="col-md-6">
							<label htmlFor="tendency">Dars soati</label>
							<input id="hours" className="form-control" defaultValue={this.state.template.hours} onChange={this.setValue}/>
						</div>
					</div>
					<div className="form-row">
						<div className="col-md-12">
							<label htmlFor="tendency">Bosh qismi</label>
							<textarea id="header" className="form-control" rows={5} defaultValue={this.state.template.header} onChange={this.setValue}/>
						</div>
					</div>
					<div className="form-row">
						<div className="col-md-12">
							<label htmlFor="section1">1. Shartnoma predmeti</label>
							<textarea id="section1" className="form-control" rows={5} defaultValue={this.state.template.section1Content} onChange={this.setValue}/>
						</div>
					</div>
					<div className="form-row">
						<div className="col-md-12">
							<label htmlFor="section2">2. O`quv kursi uchun belgilangan to`lov va hisob-kitob tartibi</label>
							<textarea id="section2" className="form-control" rows={5} defaultValue={this.state.template.section2Content} onChange={this.setValue}/>
						</div>
					</div>
					<div className="form-row">
						<div className="col-md-12">
							<label htmlFor="section3">3. Markazning huquq va majburiyatlari</label>
							<textarea id="section3" className="form-control" rows={5} defaultValue={this.state.template.section3Content} onChange={this.setValue}/>
						</div>
					</div>
					<div className="form-row">
						<div className="col-md-12">
							<label htmlFor="section4">4. Talabgorning majburiyati</label>
							<textarea id="section4" className="form-control" rows={5} defaultValue={this.state.template.section4Content} onChange={this.setValue}/>
						</div>
					</div>
					<div className="form-row">
						<div className="col-md-12">
							<label htmlFor="section5">5. Tomonlarning javobgarligi</label>
							<textarea id="section5" className="form-control" rows={5} defaultValue={this.state.template.section5Content} onChange={this.setValue}/>
						</div>
					</div>
					<div className="form-row">
						<div className="col-md-12">
							<label htmlFor="section6">6. Shartnomaga qo`shimcha va o`zgartirishlar kiritish tartibi hamda bekor qilish</label>
							<textarea id="section6" className="form-control" rows={5} defaultValue={this.state.template.section6Content} onChange={this.setValue}/>
						</div>
					</div>
					<div className="form-row">
						<div className="col-md-12">
							<label htmlFor="section7">7. Fors major holatlari</label>
							<textarea id="section7" className="form-control" rows={5} defaultValue={this.state.template.section7Content} onChange={this.setValue}/>
						</div>
					</div>
					<div className="form-row">
						<div className="col-md-12">
							<label htmlFor="section8">8. Boshqa shartlar</label>
							<textarea id="section8" className="form-control" rows={5} defaultValue={this.state.template.section8Content} onChange={this.setValue}/>
						</div>
					</div>
					<div className="form-row">
						<div className="col-md-12">
							<label htmlFor="section9">9. Alohida shartlar</label>
							<textarea id="section9" className="form-control" rows={5} defaultValue={this.state.template.section9Content} onChange={this.setValue}/>
						</div>
					</div>
					<div className="form-row mt-3">
						<div className="col-md-12 text-center">
							<button className="btn btn-success" onClick={this.save.bind(this)}>Saqlash</button>
						</div>
					</div>
				</div>
			</div>
		)
	}
}