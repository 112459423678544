import React, {Component} from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import {apiHost, logout, pageNotFound} from "./Utils";
import authHeader from "./services/auth-header";

class ListenerView extends Component {

	constructor(props) {
		super(props);
		this.state = {
			listenerId: props.listener.id || null,
			lastName: props.listener.lastName || '',
			firstName: props.listener.firstName || '',
			middleName: props.listener.middleName || '',
			passportNumber: props.listener.passportNumber || '',
			solution: true
		};
		this.setValue = this.setValue.bind(this);
	}

	componentDidMount() {

	}

	setValue(e) {
		let name = e.target.id;
		let value = e.target.value;
		this.setState({
			[name]: value,
		})
	}

	saveSolution() {
		let solution = document.getElementById('solution').value;
		let registerNumber = document.getElementById('registerNumber').value;
		axios.post(apiHost + '/api/listener/saveListenerResult',
			{
				"id": this.state.listenerId,
				"registerNumber": registerNumber,
				"solution": {
					"id": solution
				}
			},
			authHeader())
			.then(result => {
				if (result.status === 200) {
					this.props.reloadTable();
					this.props.closeModal();
					swal(result.data.message, "", "success");
				} else if (result.status === 500) {
					swal(result.data.message, "", "error");
				}
			}).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		})
	}

	changeSolution() {
		let solution = document.getElementById('solution').value;
		this.setState({
			solution: Number(solution) === 1
		})
	}

	render() {
		pageNotFound();
		return (
			<div style={{marginTop: "30px"}}>
				<div className="container card card-body my-3">
					<div className="form-row">
						<div className="col-md-12 text-center">
							<label><h4>Tinglovchi ma'lumotlari</h4></label>
						</div>
					</div>
					<div className="form-row">
						<div className="col-md-6">
							<label>Familiyasi</label>
							<label className="form-control">{this.state.lastName}</label>
						</div>
						<div className="col-md-6">
							<label>Ismi</label>
							<label className="form-control">{this.state.firstName}</label>
						</div>
					</div>
					<div className="form-row">
						<div className="col-md-4">
							<label>Sharifi</label>
							<label className="form-control">{this.state.middleName}</label>
						</div>
						<div className="col-md-4 mb-2">
							<label htmlFor="solution">Yakuniy xulosasi</label>
							<select id="solution" className="form-control" onChange={this.changeSolution.bind(this)}>
								<option value="1">O`tdi</option>
								<option value="2">O`tmadi</option>
								<option value="3">Uzrli sabablarga ko`ra kelmadi</option>
								<option value="4">Uzrsiz sabablarga ko`ra kelmadi</option>
							</select>
						</div>
						<div className="col-md-4 mb-2">
							<label htmlFor="registerNumber">Qayd raqami</label>
							<input id="registerNumber" className="form-control" disabled={!this.state.solution}/>
						</div>
					</div>
					<div className="form-row">
						<div className="col-md-12 mt-2 text-center">
							<label>&nbsp;</label>
							<button className="btn btn-success" onClick={this.saveSolution.bind(this)}>Saqlash</button>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default ListenerView;