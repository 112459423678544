import React, {Component} from 'react';
import axios from 'axios';
import {apiHost, logout, pageNotFound} from "./Utils";
import DatePicker, {registerLocale, setDefaultLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import uz from 'date-fns/locale/uz';
import getTime from 'date-fns/getTime';
import Modal from "react-responsive-modal";
import Cookies from 'universal-cookie';
import authHeader from "./services/auth-header";

const cookies = new Cookies();

registerLocale('uz', uz);
setDefaultLocale('uz');

export default class CertificatesByTendencyReportView extends Component {

    constructor() {
        super();
        this.state = {
            startDate: new Date(2024, 0, 1, 0, 0, 0, 0),
            endDate: new Date(),
            tableRows: [],
            modalOpen: false,
            tendency: {
                id: null,
                tendencyName: '',
                listenerCount: 0,
                studiedCount: 0,
                notStartedCount: 0,
                inStudyCount: 0,
                allCertCount: 0,
                notIncludedInExamCount: 0,
                failOnExamCount: 0,
                excludedCount: 0,
                indebtedCount: 0,
            }
        }
    }

    componentDidMount() {
        this.getData(this.state.startDate, this.state.endDate);
    }

    getData(startDate, endDate) {
        axios.get(apiHost + '/api/reports/byTendencyCertificates?startDate=' + getTime(startDate) +
            '&endDate=' + getTime(endDate), authHeader()).then(result=>{
            if (result.status === 200) {
                if (result.data !== undefined && result.data !== null) {
                    let count = 0, studiedCount = 0, inStudyCount = 0, notStartedCount = 0, allCertCount = 0, notIncludedInExamCount = 0, failOnExamCount = 0, excludedCount = 0, indebtedCount = 0;
                    let rows = result.data.map((row, index) => {
                        count = count + row.listenerCount;
                        studiedCount = count + row.studiedCount;
                        notStartedCount = count + row.notStartedCount;
                        inStudyCount = count + row.inStudyCount;
                        allCertCount = allCertCount + row.allCertCount;
                        notIncludedInExamCount = notIncludedInExamCount + row.notIncludedInExamCount;
                        failOnExamCount = failOnExamCount + row.failOnExamCount;
                        excludedCount = excludedCount + row.excludedCount;
                        indebtedCount = indebtedCount + row.indebtedCount;

                        return <tr key={'trep_' + index}>
                            <td className="text-center">{index + 1}</td>
                            <td><span className="linkStyle" onClick={this.openModal.bind(this, row)}>{row.tendencyName}</span></td>
                            <td className="text-center">{row.listenerCount}</td>
                            <td className="text-center">{row.studiedCount}</td>
                            <td className="text-center">{row.notStartedCount}</td>
                            <td className="text-center">{row.inStudyCount}</td>
                            <td className="text-center">{row.allCertCount}</td>
                            <td className="text-center">{row.notIncludedInExamCount}</td>
                            <td className="text-center">{row.failOnExamCount}</td>
                            <td className="text-center">{row.excludedCount}</td>
                            <td className="text-center">{row.indebtedCount}</td>
                        </tr>
                    });
                    rows.push(<tr key="cerrep_last">
                        <td colSpan={2} className="text-center"><b>JAMI:</b></td>
                        <td className="text-center"><b>{count}</b></td>
                        <td className="text-center"><b>{studiedCount}</b></td>
                        <td className="text-center"><b>{notStartedCount}</b></td>
                        <td className="text-center"><b>{inStudyCount}</b></td>
                        <td className="text-center"><b>{allCertCount}</b></td>
                        <td className="text-center"><b>{notIncludedInExamCount}</b></td>
                        <td className="text-center"><b>{failOnExamCount}</b></td>
                        <td className="text-center"><b>{excludedCount}</b></td>
                        <td className="text-center"><b>{indebtedCount}</b></td>
                    </tr>);
                    this.setState({
                        tableRows: rows
                    })
                }
            }
        }).catch(error=>{
            if (error !== undefined && error !== null && error.response.status === 401) {
                logout();
            }
        })
    }

    setStartDate(startDate) {
        this.setState({
            startDate: startDate
        });
        this.getData(startDate, this.state.endDate);
    }

    setEndDate(endDate) {
        this.setState({
            endDate: endDate
        });
        this.getData(this.state.startDate, endDate);
    }

    openModal(tendency) {
        this.setState({
            tendency: tendency,
            modalOpen: true
        })
    }

    closeModal() {
        this.setState({
            tendency: {
                id: null,
                tendencyName: '',
                listenerCount: 0,
                studiedCount: 0,
                notStartedCount: 0,
                inStudyCount: 0,
                allCertCount: 0,
                notIncludedInExamCount: 0,
                failOnExamCount: 0,
                excludedCount: 0,
                indebtedCount: 0,
            },
            modalOpen: false
        })
    }

    exportToExcel() {
        let form = document.getElementById('excelForm');
        cookies.set('tendencyId', this.state.tendency.id, {path: '/'});
        cookies.set('startDate', getTime(this.state.startDate), {path: '/'});
        cookies.set('endDate', getTime(this.state.endDate), {path: '/'});
        form.submit();
    }

    render() {
        pageNotFound();
        return (
            <div className="card card-body shadow-sm">
                <div className="form-row mt-2">
                    <div className="col-md-6 text-right">
                        <h3>Sertifikatlar hisoboti</h3>
                    </div>
                </div>
                <div className="form-row mt-2">
                    <div className="col-md-12">
                        <DatePicker className="form-control" id="startDate" locale="uz" dateFormat="dd.MM.yyyy"
                                    selected={this.state.startDate} onChange={this.setStartDate.bind(this)}/>
                        <span> dan </span>
                        <DatePicker className="form-control" id="endDate" locale="uz" dateFormat="dd.MM.yyyy"
                                    selected={this.state.endDate} onChange={this.setEndDate.bind(this)}/>
                        <span> gacha</span>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-12 order-md-1 pt-2">
                        <table className="table table-striped table-bordered shadow" style={{tableLayout: "fixed"}}>
                            <thead>
                            <tr>
                                <th scope="col" className="align-middle" style={{width: "5%"}}>№</th>
                                <th scope="col" className="align-middle">Yo`nalish nomi</th>
                                <th scope="col" className="align-middle">Tinglovchi soni</th>
                                <th scope="col" className="align-middle">O`qiganlar soni</th>
                                <th scope="col" className="align-middle">Hujjat topshirganlar soni</th>
                                <th scope="col" className="align-middle">O`quv jarayonidagilar soni</th>
                                <th scope="col" className="align-middle">Sertifikat olganlar</th>
                                <th scope="col" className="align-middle">Imtihonga kelmaganlar</th>
                                <th scope="col" className="align-middle">Imtihondan o`ta olmaganlar</th>
                                <th scope="col" className="align-middle">Safdan chiqarilganlar</th>
                                <th scope="col" className="align-middle">Qarzdorlar</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.tableRows}
                            </tbody>
                        </table>
                    </div>
                </div>

                <Modal open={this.state.modalOpen} onClose={this.closeModal.bind(this)} center>
                    <div style={{padding: "20px"}}>
                        <div className="form-row">
                            <div className="col-md-12"><h5><b><u>{this.state.tendency.tendencyName}</u></b></h5></div>
                        </div>
                        <div className="form-row">
                            <div className="col-md-12 mt-3">
                                <table width="100%">
                                    <tr>
                                        <td>Jami tinglovchi soni:</td>
                                        <td>{this.state.tendency.listenerCount}</td>
                                    </tr>
                                    <tr>
                                        <td>O`qiganlar soni:</td>
                                        <td>{this.state.tendency.studiedCount}</td>
                                    </tr>
                                    <tr>
                                        <td>Hujjat topshirganlar soni:</td>
                                        <td>{this.state.tendency.notStartedCount}</td>
                                    </tr>
                                    <tr>
                                        <td>O`quv jarayonidagilar soni:</td>
                                        <td>{this.state.tendency.inStudyCount}</td>
                                    </tr>
                                    <tr>
                                        <td>Sertifikat olganlar:</td>
                                        <td>{this.state.tendency.allCertCount}</td>
                                    </tr>
                                    <tr>
                                        <td>Imtihonga kelmaganlar:</td>
                                        <td>{this.state.tendency.notIncludedInExamCount}</td>
                                    </tr>
                                    <tr>
                                        <td>Imtihondan o`ta olmaganlar:</td>
                                        <td>{this.state.tendency.failOnExamCount}</td>
                                    </tr>
                                    <tr>
                                        <td>Safdan chiqarilganlar:</td>
                                        <td>{this.state.tendency.excludedCount}</td>
                                    </tr>
                                    <tr>
                                        <td>Qarzdorlar:</td>
                                        <td>{this.state.tendency.indebtedCount}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-center" colSpan={2}>
                                            <form id="excelForm" method="post" action={apiHost + "/common/certificateReportExcel"}>
                                                <button className="m-2 btn btn-info" onClick={this.exportToExcel.bind(this)}><i className="fa fa-file-excel-o"/></button>
                                            </form>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}