import React, {Component} from 'react';

export const liveHost = 'elektron.uzmarkaz.uz';
export const localHost = 'localhost:8090';
export const apiHost = 'http://' + liveHost;
export const wsHost = 'ws://' + liveHost;

export const topics = ["Mediatorlik kurslari", "Qayta tayyorlash kurslari", "Malaka oshirish kurslari", "Qisqa muddatli kurslar"];

export function getKey() {
	return Math.random() * 100000000000000000;
}

export function getRegions() {
	return [
		<option key={getKey()} value={0}>Tanlang</option>,
		<option key={getKey()} value={1}>Qoraqalpog`iston Respublikasi</option>,
		<option key={getKey()} value={2}>Andijon viloyati</option>,
		<option key={getKey()} value={3}>Buxoro viloyati</option>,
		<option key={getKey()} value={4}>Qashqadaryo viloyati</option>,
		<option key={getKey()} value={5}>Jizzax viloyati</option>,
		<option key={getKey()} value={6}>Navoiy viloyati</option>,
		<option key={getKey()} value={7}>Namangan viloyati</option>,
		<option key={getKey()} value={8}>Samarqand viloyati</option>,
		<option key={getKey()} value={9}>Sirdaryo viloyati</option>,
		<option key={getKey()} value={10}>Surxondaryo viloyati</option>,
		<option key={getKey()} value={11}>Toshkent viloyati</option>,
		<option key={getKey()} value={12}>Farg`ona viloyati</option>,
		<option key={getKey()} value={13}>Xorazm viloyati</option>,
		<option key={getKey()} value={14}>Toshkent shahri</option>
	];
}

export function validateOnlyTextField(e) {
	let str = e.target.value;
	if(!(/^[a-zA-Z`'", ]+$/.test(str))){
		str = str.substr(0,  str.length -1);
		e.target.value = str;
	}
}
export function validateOnlyNumberField(e) {
	let str = e.target.value;
	if(!(/^[0-9 ]+$/.test(str))){
		str = str.substr(0,  str.length -1);
		e.target.value = str;
	}
}
export function validateOnlyTextNumberField(e) {
	let str = e.target.value;
	if(!(/^[a-zA-Z,0-9 ]+$/.test(str))){
		str = str.substr(0,  str.length -1);
		e.target.value = str;
	}
}

export function checkValid(value) {
	return value !== undefined && value !== null && value !== '';
}

export function checkNumberValid(fieldValue) {
	return fieldValue !== undefined && fieldValue !== null && fieldValue !== '' && fieldValue > 0;
}

export function checkDateValid(fieldValue) {
	return fieldValue !== undefined && fieldValue !== null && fieldValue !== '' && fieldValue < new Date(2010, 0, 1, 0, 0, 0, 0).getTime();
}

export function validateField(fieldId, fieldValue, errors) {
	if (!checkValid(fieldValue)) {
		document.getElementById(fieldId).style.boxShadow = "0px 0px 5px 5px red";
		errors++;
	}
	return errors;
}

export function validateNumberField(fieldId, fieldValue, errors) {
	if (fieldValue !== undefined && fieldValue !== null && fieldValue !== '' && fieldValue !== 0) {
		document.getElementById(fieldId).style.boxShadow = "0px 0px 5px 5px red";
		errors++;
	}
	return errors;
}

export function validateDateField(fieldId, fieldValue, errors) {
	if (!checkDateValid(fieldValue)) {
		document.getElementById(fieldId).style.boxShadow = "0px 0px 5px 5px red";
		errors++;
	}
	return errors;
}

export function validateSelect(fieldId, errors) {
	let fieldVal = document.getElementById(fieldId).value;
	if (fieldVal === undefined || fieldVal === null || fieldVal === '' || fieldVal === '0') {
		document.getElementById(fieldId).style.boxShadow = "0px 0px 5px 5px red";
		errors++;
	}
	return errors;
}

export function inputValueChanged(e) {
	if (checkValid(e.target.value)) {
		e.target.style.boxShadow = "none";
	} else if (checkValid(e.target.value)) {
		e.target.style.boxShadow = "0px 0px 5px 5px red";
	}
}

export function selectValueChanged(e) {
	if (validateSelect(e.target.id, 0) === 0) {
		e.target.style.boxShadow = "none";
	}
}

export function logout() {
	localStorage.removeItem('user');
	localStorage.removeItem('agreement');
	localStorage.removeItem('agreementId');
	window.open('/login', '_self');
}

export function pageNotFound() {
	let user = localStorage.getItem('user');
	if (user !== undefined && user !== null) {
		user = JSON.parse(user);
	}
	if (user === undefined || user === null || !(user.roles.includes('ADMIN') || user.roles.includes('ACCOUNTANT') ||
		user.roles.includes('CONTROLLER') || user.roles.includes('MAIN_CONTROLLER') ||
		user.roles.includes('MARKETING') || user.roles.includes('MAIN_MARKETING'))) {
		let style = document.createElement("style");
		style.innerHTML="body { display:none !important; }";
		document.getElementsByTagName("HEAD")[0].appendChild(style);
		window.open('/404.html', '_self');
	}
}

export default class Utils extends Component {

}