import React, {Component} from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import {apiHost, logout, pageNotFound} from "./Utils";
import authHeader from "./services/auth-header";

export default class EditRequisitesView extends Component {

	constructor() {
		super();
		this.state = {
			tendencyList: [],

			accountNumber: '',
			mfo: '',
			inn: '',
			accountNumber2: '',
			inn2: '',
			oked: '',
			address: '',
			phoneNumber: '',
			director: '',
			yurist: ''
		};
		this.setValue = this.setValue.bind(this);
	}

	componentDidMount() {
		axios.get(apiHost + '/api/admin/requisites/list', authHeader())
			.then(result=>{
				if (result.status === 200) {
					let data = result.data;
					this.setState({
						accountNumber: data[0].code,
						mfo: data[1].code,
						inn: data[2].code,
						accountNumber2: data[3].code,
						inn2: data[4].code,
						oked: data[5].code,
						address: data[6].code,
						phoneNumber: data[7].code,
						director: data[8].code,
						yurist: data[9].code
					})
				}
			}).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		})
	}

	setValue(e) {
		let name = e.target.id;
		let value = e.target.value;
		this.setState({
			[name]: value
		})
	}

	save() {
		if (this.state.accountNumber === '') {
			swal('Hisob raqamni kiriting');
		}
		if (this.state.mfo === '') {
			swal('MFO raqamini kiriting');
		}
		if (this.state.inn === '') {
			swal('INN raqamni kiriting');
		}
		if (this.state.accountNumber2 === '') {
			swal('Shaxsiy hisob raqamni kiriting');
		}
		if (this.state.inn2 === '') {
			swal('INN raqamni kiriting');
		}
		if (this.state.oked === '') {
			swal('OKED raqamni kiriting');
		}
		if (this.state.address === '') {
			swal('Manzilni kiriting');
		}
		if (this.state.phoneNumber === '') {
			swal('Telefon raqamni kiriting');
		}
		if (this.state.director === '') {
			swal('Direktorning F.I.Shni kiriting');
		}
		if (this.state.yurist === '') {
			swal('Yuriskonsultning F.I.Shni kiriting');
		}
		let data = {};
		data.accountNumber = this.state.accountNumber;
		data.mfo = this.state.mfo;
		data.inn = this.state.inn;
		data.accountNumber2 = this.state.accountNumber2;
		data.inn2 = this.state.inn2;
		data.oked = this.state.oked;
		data.address = this.state.address;
		data.phoneNumber = this.state.phoneNumber;
		data.director = this.state.director;
		data.yurist = this.state.yurist;
		axios.post(apiHost + '/api/admin/requisites/edit', data, authHeader())
			.then(result=>{
				if (result.status === 200) {
					swal(result.data.message, '', 'success');
				} else {
					swal(result.data.message, '', 'error');
				}
		}).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		})
	}

	render() {
		pageNotFound();
		return(
			<div className="content">
				<div className="container card card-body mb-2 shadow-sm">
					<div className="form-row">
						<div className="col-md-6">
							<label htmlFor="accountNumber">Hisob raqami</label>
							<input id="accountNumber" type="number" className="form-control" defaultValue={this.state.accountNumber} onChange={this.setValue}/>
						</div>
						<div className="col-md-3">
							<label htmlFor="mfo">MFO</label>
							<input id="mfo" type="number" className="form-control" defaultValue={this.state.mfo} onChange={this.setValue}/>
						</div>
						<div className="col-md-3">
							<label htmlFor="inn">INN</label>
							<input id="inn" type="number" className="form-control" defaultValue={this.state.inn} onChange={this.setValue}/>
						</div>
					</div>
					<div className="form-row">
						<div className="col-md-6">
							<label htmlFor="accountNumber2">Shaxsiy hisob raqami</label>
							<input id="accountNumber2" type="number" className="form-control" defaultValue={this.state.accountNumber2} onChange={this.setValue}/>
						</div>
						<div className="col-md-3">
							<label htmlFor="inn2">INN</label>
							<input id="inn2" type="number" className="form-control" defaultValue={this.state.inn2} onChange={this.setValue}/>
						</div>
						<div className="col-md-3">
							<label htmlFor="oked">OKED</label>
							<input id="oked" type="number" className="form-control" defaultValue={this.state.oked} onChange={this.setValue}/>
						</div>
					</div>
					<div className="form-row">
						<div className="col-md-6">
							<label htmlFor="address">Manzil</label>
							<input id="address" className="form-control" defaultValue={this.state.address} onChange={this.setValue}/>
						</div>
						<div className="col-md-6">
							<label htmlFor="phoneNumber">Telefon raqami</label>
							<input id="phoneNumber" className="form-control" defaultValue={this.state.phoneNumber} onChange={this.setValue}/>
						</div>
					</div>
					<div className="form-row">
						<div className="col-md-6">
							<label htmlFor="director">Markaz direktori</label>
							<input id="director" className="form-control" defaultValue={this.state.director} onChange={this.setValue}/>
						</div>
						<div className="col-md-6">
							<label htmlFor="yurist">Yuriskonsult</label>
							<input id="yurist" className="form-control" defaultValue={this.state.yurist} onChange={this.setValue}/>
						</div>
					</div>
					<div className="form-row mt-3 text-center">
						<div className="col-md-12">
							<button className="btn btn-success" onClick={this.save.bind(this)}>Saqlash</button>
						</div>
					</div>
				</div>
			</div>
		)
	}
}