import React, {Component} from 'react';
import {Modal} from "react-responsive-modal";
import {NavLink} from "react-router-dom";
import {logout} from "./Utils";
import ChangeBHMView from "./ChangeBHMView";

export default class MainMenu extends Component {

	constructor() {
		super();
		this.state = {
			showAlert: true,
            modalOpen: false
		}
	}

	closeAlert() {
		this.setState({
			showAlert: false
		})
	}

    onOpenModal() {
        this.setState({
            modalOpen: true
        })
    }

    onCloseModal() {
        this.setState({
            modalOpen: false
        })
    }

	logout() {
		logout();
	}

	centerRequisites() {

	}

	render() {
		return (
			<div style={{height: this.props.user !== undefined && this.props.user !== null ? "55px" : "0"}}>
				{this.props.user !== undefined && this.props.user !== null &&
				<ul className="header fixed-top">
					<div className="form-row">
						{(this.props.user.roles[0] === 'CONTROLLER' || this.props.user.roles[0] === 'MAIN_CONTROLLER'
							|| this.props.user.roles[0] === 'MARKETING') &&
						<div className="col-md-10">
							<div style={{display: "flex"}}>
								{(this.props.user.roles[0] === 'CONTROLLER' || this.props.user.roles[0] === 'MAIN_CONTROLLER') &&
								<div className="btn-group p-2">
									<button type="button" className="btn btn-info dropdown-toggle"
											data-toggle="dropdown" aria-expanded="false">Shartnoma asosida
									</button>
									<div className="dropdown-menu">
										<a className="dropdown-item linkStyle" href="/agreements">Shartnomalar</a>
										<div className="dropdown-divider"/>
										<a className="dropdown-item linkStyle"
										   href="/tendencies?type=paid">Yo`nalishlar</a>
										<div className="dropdown-divider"/>
										<a className="dropdown-item linkStyle" href="/groups?type=paid">Guruhlar</a>
										<div className="dropdown-divider"/>
										<a className="dropdown-item linkStyle"
										   href="/listeners?type=paid">Tinglovchilar</a>
										<div className="dropdown-divider"/>
										<a className="dropdown-item linkStyle" href="/latePayments">Kechikib
											to`laganlar</a>
										<div className="dropdown-divider"/>
										<a className="dropdown-item linkStyle" href="/newPayments">Yangi to`laganlar</a>
									</div>
								</div>
								}

								{this.props.user.roles[0] === 'MARKETING' &&
									<>
										<li><NavLink to="/agreements">Shartnomalar</NavLink></li>
										<li><NavLink to="/tendencies?type=paid">Yo`nalishlar</NavLink></li>
										<li><NavLink to="/groups?type=paid">Guruhlar</NavLink></li>
										<li><NavLink to="/listeners?type=paid">Tinglovchilar</NavLink></li>
										<li><NavLink to="/latePayments">Kechikib to`laganlar</NavLink></li>
										<li><NavLink to="/newPayments">Yangi to`laganlar</NavLink></li>
									</>
								}
								<div className="btn-group p-2">
									<button type="button" className="btn btn-info dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
										Taqsimot asosida
									</button>
									<div className="dropdown-menu">
										<a className="dropdown-item linkStyle" href="/tendencies?type=free">Yo`nalishlar</a>
										<div className="dropdown-divider"/>
										<a className="dropdown-item linkStyle" href="/groups?type=free">Guruhlar</a>
										<div className="dropdown-divider"/>
										<a className="dropdown-item linkStyle" href="/listeners?type=free">Tinglovchilar</a>
									</div>
								</div>
								{this.props.user.roles[0] === 'MARKETING' &&
								<div className="btn-group p-2">
									<button type="button" className="btn btn-info dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
										Arxiv
									</button>
									<div className="dropdown-menu">
										<a className="dropdown-item linkStyle" href="/agreements2">Shartnomalar</a>
										<div className="dropdown-divider"/>
										<a className="dropdown-item linkStyle" href="/listeners2">Tinglovchilar</a>
									</div>
								</div>
								}
								<li><NavLink to="/deletedObjects">O`chirilganlar</NavLink></li>
								{(this.props.user.roles[0] === 'CONTROLLER' || this.props.user.roles[0] === 'MAIN_CONTROLLER') &&
								<>
									<div className="btn-group p-2">
										<button type="button" className="btn btn-info dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
											Sertifikatlar
										</button>
										<div className="dropdown-menu">
											<a className="dropdown-item linkStyle" href="/revertedCertificates">Bekor qilinganlar</a>
											<a className="dropdown-item linkStyle" href="/duplicateCertificates">Dublikatlar</a>
										</div>
									</div>
									<li><NavLink to="/creport">Hisobotlar</NavLink></li>
									<li><NavLink to="/settings">Sozlamalar</NavLink></li>
								</>
								}
							</div>
						</div>
						}

						{this.props.user.roles[0] === 'ACCOUNTANT' &&
						<div className="col-md-10">
							<div style={{display: "flex"}}>
								<div className="btn-group p-2">
									<button type="button" className="btn btn-info dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Sozlamalar</button>
									<div className="dropdown-menu">
										<a className="dropdown-item linkStyle" href="/editRequisites">Markaz rekvizitlari</a>
										<div className="dropdown-divider"/>
										<span className="dropdown-item linkStyle" onClick={this.onOpenModal.bind(this)}>BHM</span>
									</div>
								</div>
								<div>
									<li><NavLink to="/paidTendencies?type=paid">Yo`nalishlar</NavLink></li>
									<li><NavLink to="/agreements">Shartnomalar</NavLink></li>
									<li><NavLink to="/groups?type=paid">Guruhlar</NavLink></li>
									<li><NavLink to="/listeners?type=paid">Tinglovchilar</NavLink></li>
								</div>
							</div>
						</div>
						}

						{/*{(this.props.user.roles[0] === 'MARKETING') &&
						<div className="col-md-10">
							<div>
								<li><NavLink to="/agreements">Shartnomalar</NavLink></li>
								<li><NavLink to="/paidTendencies?type=paid">Yo`nalishlar</NavLink></li>
								<li><NavLink to="/listeners?type=paid">Tinglovchilar</NavLink></li>
								<li><NavLink to="/groups?type=paid">Guruhlar</NavLink></li>
								<li><NavLink to="/newPayments">Yangi to`laganlar</NavLink></li>
								<li><NavLink to="/deletedObjects">O`chirilganlar</NavLink></li>
							</div>
						</div>
						}*/}
						{this.props.user.roles[0] === 'ADMIN' &&
							<div>
								<li><NavLink to="/tendencies?type=all">Yo`nalishlar</NavLink></li>
								<li><NavLink to="/userList">Foydalanuvchilar</NavLink></li>
							</div>
						}
						{/*{this.props.user.roles[0] === 'ACCOUNTANT' &&
						<li><NavLink to="/accountantReports">Hisobotlar</NavLink></li>
						}*/}

						<div className="col-md-2 text-right">
							<div className="btn-group p-2">
								<button type="button" className="btn btn-info dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									{this.props.user.lastName + ' ' + this.props.user.firstName}
								</button>
								<div className="dropdown-menu">
									<span className="dropdown-item linkStyle" onClick={this.props.openModal}><i className="fa fa-edit"/>&nbsp;Parolni o`zgartirish</span>
									<div className="dropdown-divider"/>
									<span className="dropdown-item linkStyle" onClick={this.logout.bind(this)}><i className="fa fa-external-link"/>&nbsp;Chiqish</span>
								</div>
							</div>
						</div>
					</div>
				</ul>
				}
				<Modal open={this.state.modalOpen} onClose={this.onCloseModal.bind(this)} center>
					<ChangeBHMView/>
				</Modal>
			</div>
		)
	}
}