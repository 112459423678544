import React, {Component} from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import {apiHost, logout, pageNotFound} from "./Utils";
import authHeader from "./services/auth-header";

class TendencyView extends Component {

	constructor(props) {
		super(props);
		this.state = {
			tendency: {
				topic: {
					name: ''
				}
			},
			name: ''
		}
	}

	componentDidMount() {
		let urlParams = new URLSearchParams(window.location.search);
		axios.get(apiHost + '/api/admin/tendency/item?id=' + urlParams.get('id'), authHeader())
			.then(result=>{
				if (result.status === 200) {
					this.setState({
						tendency: result.data
					})
				} else {
					swal(result.data.message, '', 'error');
				}
			}).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		})
	}

	updateCertificateContent() {
		let urlParams = new URLSearchParams(window.location.search);
		let content = document.getElementById('certificateContent').value;
		let data = {
			"id": urlParams.get('id'),
			"certificateContent": content
		}
		axios.post(apiHost + '/api/admin/tendency/updateCertificateContent', data, authHeader())
			.then(result=>{
				if (result.status === 200) {
					swal(result.data.message, '', 'success');
				} else {
					swal(result.data.message, '', 'error');
				}
			}).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		})
	}

	render() {
		pageNotFound();
		let currentUser = JSON.parse(localStorage.getItem('user'));
		return(
			<div className="container card card-body my-4">
				<div className="form-row">
					<div className="col-md-12 text-center">
						<h5>{this.state.tendency.name}</h5>
					</div>
				</div>
				<div className="row">
					<div className="col-md-4">
						<label htmlFor="topic">Turi</label>
						<span id="topic" className="form-control">{this.state.tendency.topic.name}</span>
					</div>
					<div className="col-md-4">
						<label htmlFor="name">Nomi</label>
						<span id="name" className="form-control">{this.state.tendency.name}</span>
					</div>
					<div className="col-md-2">
						<label htmlFor="price">Akademik soat</label>
						<span id="hours" className="form-control">{this.state.tendency.hours}</span>
					</div>
					<div className="col-md-2">
						<label htmlFor="price">Narxi(1 kishiga)</label>
						<span id="price" className="form-control">{this.state.tendency.price}</span>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						<label htmlFor="certificateContent">Sertifikat matni</label>
						<textarea id="certificateContent" className="form-control" rows={5} defaultValue={this.state.tendency.certificateContent}/>
					</div>
				</div>
				{(currentUser.roles[0] === 'CONTROLLER' || currentUser.roles[0] === 'MAIN_CONTROLLER') &&
				<div className="row mt-2">
					<div className="col-md-12 text-center">
						<button className="btn btn-success" onClick={this.updateCertificateContent.bind(this)}>Saqlash</button>
					</div>
				</div>
				}
			</div>
		)
	}
}

export default TendencyView;