import React, {Component} from 'react';
import {apiHost, logout, pageNotFound} from "./Utils";
import axios from 'axios';
import swal from 'sweetalert';
import 'react-responsive-modal/styles.css';
import Modal from 'react-responsive-modal';
import authHeader from "./services/auth-header";

export default class RevertedCertificateNumbersView extends Component {

    constructor() {
        super();
        this.state = {
            modalOpen: false,
            tableRows: [],
            selectedRow: {},
            groups: [],
            listeners: [],
        }
    }

    componentDidMount() {
        this.loadData();
        this.getGroups();
    }

    loadData() {
        axios.get(apiHost + '/api/admin/revertedCertificate/list', authHeader())
            .then(result => {
            if (result.status === 200) {
                if (result.data !== null) {
                    let rows = [];
                    rows = result.data.map((row, index) => {
                        return <tr>
                            <td>{index + 1}</td>
                            <td>{row.code}</td>
                            <td>{row.name}</td>
                            <td className="text-center">
                                <button className="btn btn-success" onClick={this.onOpenModal.bind(this, row)}><i className="fa fa-credit-card"/></button>
                            </td>
                        </tr>
                    });
                    this.setState({
                        tableRows: rows
                    })
                }
            }
        }).catch(error=>{
            if (error !== undefined && error !== null && error.response.status === 401) {
                logout();
            }
        });
    }

    getGroups() {
        axios.get(apiHost + '/api/course/list?type=all', authHeader()).then(result => {
            if (result.status === 200) {
                let rows = result.data.map(row=> {
                    return <option key={'gr_' + row.id} value={row.id}>{row.name}</option>;
                });
                this.setState({
                    groups: rows
                })
            }
        }).catch(error=>{
            if (error !== undefined && error !== null && error.response.status === 401) {
                logout();
            }
        })
    }

    getListeners(e) {
        let groupId = e.target.value;
        axios.get(apiHost + '/api/listener/withoutCertificate?groupId=' + groupId, authHeader()).then(result => {
            if (result.status === 200) {
                let rows = result.data.map(row=> {
                    return <option key={'gr_' + row.id} value={row.id}>{row.name}</option>;
                });
                this.setState({
                    listeners: rows
                })
            }
        }).catch(error=>{
            if (error !== undefined && error !== null && error.response.status === 401) {
                logout();
            }
        })
    }

    onOpenModal(row) {
        this.setState({
            selectedRow: row,
            modalOpen: true
        })
    }

    onCloseModal() {
        this.setState({
            selectedRow: {},
            modalOpen: false
        })
    }

    generateCertificate(e) {
        let listenerId = document.getElementById('listener').value;
        axios.post(apiHost + '/api/listener/generateCertificate2?listenerId=' + listenerId + '&certificateId=' + this.state.selectedRow.id,
            null, authHeader()).then(result => {
            if (result.status === 200) {
                this.loadData();
                swal(result.data.message, "", "success");
                this.onCloseModal();
            } else if (result.status === 500) {
                swal(result.data.message, "", "error");
            }
        }).catch(error=>{
            if (error !== undefined && error !== null && error.response.status === 401) {
                logout();
            }
        })
    }

    render() {
        pageNotFound();
        return (
            <div className="content">
                <div className="container card card-body mt-1 shadow-sm">
                    <div className="form-row">
                        <div className="col-md-12">
                            <table className="table table-striped table-bordered shadow" style={{tableLayout: "fixed"}}>
                                <thead>
                                <tr>
                                    <th style={{width: "5%"}}>№</th>
                                    <th>Prefiks</th>
                                    <th>Sertifikat raqami</th>
                                    <th width="8%">Amal</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.tableRows}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <Modal open={this.state.modalOpen} onClose={this.onCloseModal.bind(this)} center>
                    <div style={{padding: "20px", minWidth: "200px"}}>
                        <div className="form-row">
                            <div className="col-md-12">
                                <label htmlFor="groups">Guruh</label>
                                <select className="form-control" id="groups" onChange={this.getListeners.bind(this)}>
                                    {this.state.groups}
                                </select>
                            </div>
                        </div>
                        <div className="form-row mt-2">
                            <div className="col-md-12">
                                <label htmlFor="listener">Tinglovchi</label>
                                <select className="form-control" id="listener">
                                    {this.state.listeners}
                                </select>
                            </div>
                        </div>
                        <div className="form-row mt-2">
                            <div className="col-md-12 text-center">
                                <button className="btn btn-success" onClick={this.generateCertificate.bind(this)}>Saqlash</button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}