import React, {Component} from 'react';
import {apiHost, getRegions, logout, pageNotFound} from "./Utils";
import axios from "axios";
import ReactPaginate from 'react-paginate';
import DatePicker, {registerLocale, setDefaultLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import uz from 'date-fns/locale/uz';
import getTime from 'date-fns/getTime';
import 'react-responsive-modal/styles.css';
import Modal from 'react-responsive-modal';
import Cookies from 'universal-cookie';
import authHeader from "./services/auth-header";

const cookies = new Cookies();

registerLocale('uz', uz);
setDefaultLocale('uz');

export default class CertificateListView extends Component {

    constructor() {
        super();
        let height = window.screen.height - 360;
        this.state = {
            showLoading: false,
            pageCount: 1,
            modalOpen: false,
            tableRows: [],
            limit: 50,
            start: 0,
            maxHeight: height,
            categories: [],
            groups: [],
            startDate: new Date(2024, 0, 1, 0, 0, 0, 0),
            endDate: new Date(),
            category: '',
            group: '',
            region: '',
            searchKey: ''
        };
        this.setValue = this.setValue.bind(this);
        this.onChangeValue = this.onChangeValue.bind(this);
    }

    componentDidMount() {
        axios.get(apiHost + '/api/agreement/topicTendencies', authHeader())
            .then(result => {
                let items = [];
                if (result.data !== null && result.data.length>0) {
                    items = result.data.map(item=>{
                        return <option key={'tt_' + item.id} value={item.id}>{item.name}</option>
                    });
                    items.unshift(<option key={'tt_' + 0} value="0">Hammasi</option>);
                }
                this.setState({
                    categories: items
                })
            }).catch(error=>{
            if (error !== undefined && error !== null && error.response.status === 401) {
                logout();
            }
        });
        axios.get(apiHost + '/api/course/list?type=all', authHeader())
            .then(result => {
                let items = [];
                if (result.data !== null && result.data.length>0) {
                    items = result.data.map(item=>{
                        return <option key={'t_' + item.id} value={item.id}>{item.name}</option>
                    });
                    items.unshift(<option key={'t_' + 0} value="0">Hammasi</option>);
                }
                this.setState({
                    groups: items
                })
            }).catch(error=>{
            if (error !== undefined && error !== null && error.response.status === 401) {
                logout();
            }
        });
        this.fetchData(this.state.startDate, this.state.endDate, this.state.category, this.state.group, this.state.start, this.state.limit);
    }

    handlePageClick = (data) => {
        let selected = data.selected;
        let start = selected === 0 ? 0 : selected * this.state.limit + 1;

        this.setState({
            start: start
        });
        this.fetchData(this.state.startDate, this.state.endDate, this.state.category, this.state.group, start, this.state.limit);
    };

    setValue(e) {
        let name = e.target.id;
        let value = e.target.value;

        this.setState({
            [name]: value,
        });
        if (name === 'category') {
            this.fetchData(this.state.startDate, this.state.endDate, value, this.state.group, this.state.region, this.state.start, this.state.limit);
        } else if (name === 'group') {
            this.fetchData(this.state.startDate, this.state.endDate, this.state.category, value, this.state.region, this.state.start, this.state.limit);
        } else if (name === 'region') {
            this.fetchData(this.state.startDate, this.state.endDate, this.state.category, this.state.group, value, this.state.start, this.state.limit);
        } else {
            this.fetchData(this.state.startDate, this.state.endDate, this.state.category, this.state.group, this.state.region, this.state.start, this.state.limit);
        }
    }

    fetchData(startDate, endDate, category, group, region, start, limit) {
        this.setState({
            showLoading: true
        });
        if (limit === undefined || limit === null) {
            limit = 100;
        }
        axios.get(apiHost + '/api/reports/certificate/list?startDate=' + getTime(startDate) + '&endDate=' + getTime(endDate) +
            '&category=' + category + '&group=' + group + '&region=' + region + '&start=' + start + '&limit=' + limit, authHeader())
            .then(result => {
                this.populateData(result);
            }).catch(error=>{
            if (error !== undefined && error !== null && error.response !== undefined && error.response !== null && error.response.status === 401) {
                logout();
            }
        })
    }

    populateData(result) {
        let rows = [];
        if (result.data.data !== undefined && result.data.data !== null) {
            rows = result.data.data.map((row, index) => {
                return <tr key={'cerrep_' + index}>
                    <td className="text-center">{index + 1}</td>
                    <td>{row.lastName + ' ' + row.firstName + ' ' + row.middleName}</td>
                    <td>{row.region.name}</td>
                    <td>{row.certificateNumber}</td>
                    <td>{row.passportNumber}</td>
                    <td>{row.phoneNumber}</td>
                    <td>{row.tendencyName}</td>
                    <td>{row.position}</td>
                    <td>{row.group.name}</td>
                    <td>{row.registerNumber}</td>
                </tr>
            });
            this.setState({
                tableRows: rows,
                pageCount: result.data.pageCount,
                showLoading: false
            })
        }
    }

    openModal() {
        this.setState({
            modalOpen: true
        });
    }

    closeModal() {
        this.setState({
            modalOpen: false
        })
    }

    setStartDate(startDate) {
        this.setState({
            startDate: startDate
        });
        this.fetchData(this.state.startDate, this.state.endDate, this.state.category, this.state.group, this.state.start, this.state.limit);
    }

    setEndDate(endDate) {
        this.setState({
            endDate: endDate
        });
        this.fetchData(this.state.startDate, this.state.endDate, this.state.category, this.state.group, this.state.start, this.state.limit);
    }

    onChangeValue(e) {
        let searchKey = e.target.value;
        this.setState({
            searchKey: searchKey
        })
    }

    search() {
        if (this.state.searchKey !== '') {
            this.setState({
                showLoading: true
            });
            axios.get(apiHost + '/api/listener/searchCertificate?keyword=' + this.state.searchKey, authHeader())
                .then(result => {
                    this.populateData(result);
                }).catch(error=>{
                if (error !== undefined && error !== null && error.response.status === 401) {
                    logout();
                }
            })
        }
    }

    exportToExcel() {
        let form = document.getElementById('excelForm');
        let category = document.getElementById('category').value;
        cookies.set('categoryId', category, {path: '/'});
        cookies.set('groupId', this.state.group, {path: '/'});
        cookies.set('regionId', this.state.region, {path: '/'});

        cookies.set('startDate', getTime(this.state.startDate), {path: '/'});
        cookies.set('endDate', getTime(this.state.endDate), {path: '/'});
        form.submit();
    }

    getData() {
        this.fetchData(this.state.startDate, this.state.endDate, this.state.category, this.state.group, this.state.region, this.state.start, this.state.limit);
    }

    render() {
        pageNotFound();
        return(
            <div className="content">
                {this.state.showLoading &&
                <div className="container">
                    <div className="loader">
                        <div className="loader--dot"/>
                        <div className="loader--dot"/>
                        <div className="loader--dot"/>
                        <div className="loader--dot"/>
                        <div className="loader--dot"/>
                        <div className="loader--dot"/>
                        <div className="loader--text"/>
                    </div>
                </div>
                }
                <div className="card card-body shadow-sm">
                    <div className="row">
                        <div className="col-md-1">
                            <button className="btn btn-info" onClick={this.openModal.bind(this)}><i className="fa fa-filter"/></button>
                        </div>
                        <div className="col-md-5 text-right">
                            <h3>Sertifikatlar ro`yxati</h3>
                        </div>
                        <div className="col-md-6">
                            <form className="form-inline float-right">
                                <div className="input-group">
                                    <input className="form-control" id="searchBox" placeholder="Qidiruv so`zi" aria-label="Qidirish" aria-describedby="basic-addon2" onChange={this.onChangeValue}/>
                                    <div className="input-group-append">
                                        <button className="btn btn-outline-info" type="button" onClick={this.search.bind(this)}><i className="fa fa-search"/></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 order-md-1 pt-2" style={{overflowY: "scroll", maxHeight: this.state.maxHeight + "px"}}>
                            <table className="table table-striped table-bordered shadow" style={{tableLayout: "fixed"}}>
                                <thead>
                                <tr>
                                    <th style={{width: "5%"}}>№</th>
                                    <th>F.I.Sh</th>
                                    <th>Hududi</th>
                                    <th>Sertifikat raqami</th>
                                    <th>Pasport raqami</th>
                                    <th>Telefon raqami</th>
                                    <th>Yo`nalishi</th>
                                    <th>Ish joyi</th>
                                    <th>Guruh raqami</th>
                                    <th>Qayd raqami</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.tableRows}
                                </tbody>
                            </table>
                        </div>
                        <div className="col-md-12 order-md-1 text-center pt-2">
                            <ReactPaginate
                                previousLabel={'Oldingi'}
                                nextLabel={'Keyingi'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={this.state.pageCount}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={5}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                subContainerClassName={'pagination'}
                                activeClassName={'active'}
                            />
                        </div>
                    </div>
                </div>
                <Modal open={this.state.modalOpen} onClose={this.closeModal.bind(this)} center>
                    <div className="row mt-1">
                        <div className="col-md-12">
                            <h5>Muddati</h5>
                            <div>
                                <DatePicker className="form-control" id="startDate" locale="uz" dateFormat="dd.MM.yyyy" selected={this.state.startDate} onChange={this.setStartDate.bind(this)}/>
                                <span className="ml-2 mr-2"> dan </span>
                                <DatePicker className="form-control" id="endDate" locale="uz" dateFormat="dd.MM.yyyy" selected={this.state.endDate} onChange={this.setEndDate.bind(this)}/>
                                <span className="ml-2"> gacha</span>
                                <span>&nbsp;</span>
                            </div>
                        </div>
                        <div className="col-md-12 mt-2">
                            <label htmlFor="region">Hudud</label>
                            <select id="region" className="form-control" onChange={this.setValue}>
                                {getRegions()}
                            </select>
                        </div>
                        <div className="col-md-12 mt-2">
                            <label htmlFor="category">Yo`nalishlar</label>
                            <select id="category" className="form-control" onChange={this.setValue}>
                                {this.state.categories}
                            </select>
                        </div>
                        <div className="col-md-12 mt-2">
                            <label htmlFor="category">Guruhlar</label>
                            <select id="group" className="form-control" onChange={this.setValue}>
                                {this.state.groups}
                            </select>
                        </div>
                        <div className="col-md-12 mt-2">
                            <label htmlFor="status">Guruhlar</label>
                            <div id="status">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="status5"/>
                                    <label className="form-check-label" htmlFor="status5">
                                        Kursni yakunladi
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 mt-2">
                            <div className="form-row">
                                <div className="col-md-6">
                                    <button className="ml-1 btn btn-success" onClick={this.getData.bind(this)}>OK</button>
                                </div>
                                <div className="col-md-6 text-right">
                                    <form id="excelForm" method="post" action={apiHost + "/common/certificateListExcel"}>
                                        <button className="ml-1 btn btn-info" onClick={this.exportToExcel.bind(this)}><i className="fa fa-file-excel-o"/></button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}