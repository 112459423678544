import React, {Component} from 'react';
import AgreementList from "./AgreementList";
import {logout, pageNotFound} from "./Utils";

class MarketingHomeView extends Component {

	render() {
		pageNotFound();
		let user = JSON.parse(localStorage.getItem('user'));
		if (!user.roles.includes('MARKETING')) {
			logout();
		}
		return (
			<AgreementList/>
		)
	}
}

export default MarketingHomeView;