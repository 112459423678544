import React, {Component} from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import {apiHost, logout, pageNotFound} from "./Utils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import uz from 'date-fns/locale/uz';
import {NavLink} from "react-router-dom";
import Modal from "react-responsive-modal";
import Cookies from 'universal-cookie';
import authHeader from "./services/auth-header";

const cookies = new Cookies();
registerLocale('uz', uz);
setDefaultLocale('uz');

export default class GroupView extends Component {

	constructor() {
		super();
		let courseId = localStorage.getItem('courseId');
		this.state = {
			course: {
				name: '',
				description: '',
				startDate: new Date(),
				endDate: new Date(),
				examDate: new Date(),
                listenerCount: '',
                tendency: {
					id: null,
				    code: 'paid'
                },
				studyType: {
					id: 1
				}
			},
			rows: [],
			peoples: [],
			peopleList: [],
			tendencies: [],
			modalOpen: false,
			amountModalOpen: false,
			importModalOpen: false,
			courseId: courseId,
			listenerIds: '',
            listenerId: null
		};
		this.setValue = this.setValue.bind(this);
		this.setStudyType = this.setStudyType.bind(this);
		this.setTendency = this.setTendency.bind(this);
	}

	openModal() {
		axios.get(apiHost + '/api/course/peoples', authHeader())
			.then(result=>{
				if (result.status === 200) {
					let rows = [], list = [];
					if (result.data !== undefined && result.data !== null) {
						rows = result.data.map((r, index)=>{
							return <tr><td><input id={'ch_' + r.id} type="checkbox"/></td><td>{index+1}</td><td>{r.name}</td><td>{r.code}</td></tr>
						});
						list = result.data;
					}
					this.setState({
						peoples: rows,
						peopleList: list
					})
				}
			}).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		});
		this.setState({
			modalOpen: true
		})
	}

	closeModal() {
		this.setState({
			modalOpen: false
		})
	}

	openImportModal() {
		this.setState({
			importModalOpen: true
		})
	}

	closeImportModal() {
		this.setState({
			importModalOpen: false
		})
	}

	courseStart() {
		axios.post(apiHost + '/api/course/start?id=' + this.state.courseId, null, authHeader())
			.then(result=>{
				if (result.status === 200) {
					swal(result.data.message, '', 'success');
				} else if (result.status === 500) {
					swal(result.data.message, '', 'error');
				}
			}).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		})
	}

	courseFinish() {
		axios.post(apiHost + '/api/course/finish?id=' + this.state.courseId, null, authHeader())
			.then(result=>{
				if (result.status === 200) {
					swal(result.data.message, '', 'success');
				} else if (result.status === 500) {
					swal(result.data.message, '', 'error');
				}
			}).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		})
	}

    edit(listenerId) {
        localStorage.setItem('listenerId', listenerId);
        window.open(window.location.protocol + '//' + window.location.host + '/addListener', '_self');
    }

	componentDidMount() {
        let user = localStorage.getItem('user');
        if (user !== undefined && user !== null) {
            user = JSON.parse(user);
        } else {
			logout();
		}
		axios.get(apiHost + '/api/course/item?id=' + this.state.courseId, authHeader()).then(result=>{
            if (result.status === 200) {
                axios.get(apiHost + '/api/agreement/topicTendencies?id=' +  result.data.topicId, authHeader()).then(result2 => {
                    if (result2.status === 200) {
                        if (result2.data !== undefined && result2.data !== null) {
                            this.setState({
                                tendencies: result2.data.map(item=>{
									return <option value={item.id}>{item.name}</option>
								})
                            });
                        }
                    }
                });
                let rows = [];
                if (result.data.listeners !== undefined && result.data.listeners !== null) {
                    rows = result.data.listeners.map((r, index)=>{
                        let cells = '', cells2 = '';
                        if (result.data.tendency.code === 'paid') {
                            cells = <td>{r.agreement.name}</td>;
                            cells2 = <td>{r.payedAmount}</td>;
                        }
                        return <tr><td>{index+1}</td><td>{r.lastName + ' ' + r.firstName + ' ' + r.middleName}</td>
                            <td>{r.passportNumber !== undefined && r.passportNumber !== null ? r.passportNumber : ''}</td>
                            <td>{r.certificateNumber !== undefined && r.certificateNumber !== null ? r.certificateNumber : ''}</td>
                            {cells}{cells2}
                            <td>
                                {user.roles[0] !== 'ACCOUNTANT' &&
                                <button className="btn btn-success" title="Tahrirlash"
                                        onClick={this.edit.bind(this, r.id)}><i className="fa fa-edit"/></button>
                                }
                                {user.roles[0] === 'ACCOUNTANT' &&
                                <button className="btn btn-info" title="Summani kiritish"
                                        onClick={this.inputAmount.bind(this, r.id)}><i className="fa fa-edit"/></button>
                                }
                            </td>
                        </tr>
                    });
                }
                this.setState({
                    course: result.data,
                    rows: rows
                })
            } else {
				swal(result.data.message, '', 'error');
			}
        }).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		})
	}

	addListener() {
		let ids = '';
		for (let i=0; i<this.state.peoples.length; i++) {
			let c = this.state.peoples[i].props.children;
			if(document.getElementById(c[0].props.children.props.id).checked) {
				ids = ids + c[0].props.children.props.id.replace('ch_', '') + ',';
			}
		}

		axios.post(apiHost + '/api/course/addListener?id=' + this.state.courseId + '&listenerIds=' + ids, null, authHeader())
			.then(result=>{
				if (result.status === 200) {
					let rows = this.state.rows;
					let newRows = result.data.map(r=>{
						return <tr><td>{r.id}</td><td>{r.name}</td><td>{r.code}</td></tr>
					});
					this.setState({
						rows: rows.concat(newRows),
						modalOpen: false
					});
					swal("Tanlanganlar guruhga qo`shildi", '', 'success');
				} else {
					swal("Tanlanganlarn guruhga qo`shishda xatolik", '', 'error');
				}
			}).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		})
	}

	importListener() {
		let files = document.getElementById('fileUpload').files;
		if (files === null || files.length === 0) {
			swal('Import faylini tanlang', '', 'error');
			return;
		}

		const user = JSON.parse(localStorage.getItem('user'));
		let token = '';
		if (user && user.accessToken) {
			token = 'Bearer ' + user.accessToken;
		}

		let file = files[0];
		let data = new FormData();
		data.append("id", Number(this.state.courseId));
		data.append("file", file);
		axios.post(apiHost + '/api/course/importListeners', data, {
			headers: {
				'Content-type': 'multipart/form-data',
				'Authorization': token,
				'Access-Control-Allow-Headers': '*',
				'Access-Control-Allow-Credentials': 'true',
				'Access-Control-Allow-Methods': 'GET, POST, PUT, PATCH, DELETE'
			}
		})
			.then(result => {
				if (result.status === 200) {
					swal(result.data.message, '', 'success');
				} else {
					swal(result.data.message, '', 'error');
				}
			}).catch(error => {
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		})
	}

	setStartDate(startDate) {
		this.setState({
			course: {
				...this.state.course,
				startDate: startDate
			}
		})
	}

	setEndDate(endDate) {
		this.setState({
			course: {
				...this.state.course,
				endDate: endDate
			}
		})
	}

	setExamDate(examDate) {
		this.setState({
			course: {
				...this.state.course,
				examDate: examDate
			}
		})
	}

	setValue(e) {
		let name = e.target.id;
		let value = e.target.value;
		this.setState({
			course: {
				...this.state.course,
				[name]: value
			}
		})
	}

	setStudyType(e) {
		let value = e.target.value;
		this.setState({
			course: {
				...this.state.course,
				studyType: {
					id: value
				}
			}
		})
	}

	setTendency(e) {
		let value = e.target.value;
		this.setState({
			course: {
				...this.state.course,
				tendency: {
					id: value
				}
			}
		})
	}

	updateGroup() {
		if (this.state.course.tendency === null || this.state.course.tendency.id === null) {
			swal('Yo`nalishni tanlang', '', 'error');
			return;
		}
		let data = {};
		data.id = Number(this.state.courseId);
		data.name = this.state.course.name;
		data.description = this.state.course.description;
		data.listenerCount = this.state.course.listenerCount;
		data.tendency = {
		    "id": Number(this.state.course.tendency.id)
        };
		data.startDate = this.state.course.startDate;
		data.endDate = this.state.course.endDate;
		data.examDate = this.state.course.examDate;
		data.studyType = {
			"id": this.state.course.studyType.id
		}
		axios.post(apiHost + '/api/course/update', data, authHeader())
			.then(result=>{
				if (result.status === 200) {
					swal(result.data.message, '', 'success');
				} else {
					swal(result.data.message, '', 'error');
				}
			}).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		})
	}

    inputAmount(listenerId) {
        this.setState({
            listenerId: listenerId,
            amountModalOpen: true
        });
    }

    onCloseAmountModal() {
        this.setState({
            amountModalOpen: false
        })
    }

    enterPayedAmount() {
		let payedAmount = document.getElementById('payedAmount').value;
		if (payedAmount === null || payedAmount === '' || Number(payedAmount) === 0) {
			swal('To`langan summani kiriting', '', 'error');
			return;
		}
        axios.post(apiHost + '/api/listener/enterPayedAmount?listenerId=' + this.state.listenerId + '&amount=' + payedAmount, null, authHeader())
            .then(result => {
                if (result.status === 200) {
                    swal(result.data.message, "", "success");
                    this.onCloseAmountModal();
                    setTimeout(function () {
                        window.location.reload();
                    }, 2000);
                } else if (result.status === 500) {
                    swal(result.data.message, "", "error");
                }
            }).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		})
    }

	exportListenerList() {
		cookies.set('groupId', this.state.courseId, {path: '/'});
		document.getElementById("listenerListForm").submit();
	}

	render() {
		pageNotFound();
	    let tableHeaders = '';
        if (this.state.course.tendency.code === 'free') {
            tableHeaders = <tr><th scope="col" className="align-middle">№</th><th scope="col" className="align-middle">Tinglovchining F.I.Sh</th><th scope="col" className="align-middle">Pasport raqami</th><th scope="col" className="align-middle">Sertifikat raqami</th><th scope="col" className="align-middle">Amal</th></tr>;
        } else {
            tableHeaders = <tr><th scope="col" className="align-middle">№</th><th scope="col" className="align-middle">Tinglovchining F.I.Sh</th><th scope="col" className="align-middle">Pasport raqami</th><th scope="col" className="align-middle">Sertifikat raqami</th><th scope="col" className="align-middle">Shartnoma raqami</th><th scope="col" className="align-middle">To`langan summa</th><th scope="col" className="align-middle">Amal</th></tr>;
        }

		let user = localStorage.getItem('user');
		if (user !== undefined && user !== null) {
			user = JSON.parse(user);
		}
		return (
			<div className="content">
				<div className="container card card-body mt-1 shadow-sm mb-5">
					<div className="form-row">
						<div className="col-md-6">
							<label htmlFor="groupName">Guruh nomi</label>
							<input className="form-control" id="name" defaultValue={this.state.course.name} onChange={this.setValue}/>
						</div>
						<div className="col-md-4">
							<label htmlFor="description">Izoh</label>
							<input className="form-control" id="description" defaultValue={this.state.course.description} onChange={this.setValue}/>
						</div>
						<div className="col-md-2">
							<label htmlFor="listenerCount">Tinglovchi soni</label>
							<input className="form-control" id="listenerCount" defaultValue={this.state.course.listenerCount} onChange={this.setValue}/>
						</div>
					</div>
					<div className="form-row mt-3">
						<div className="col-md-9">
							<label htmlFor="tendency">Yo`nalishi</label>
							<select className="form-control" id="tendency" value={this.state.course.tendency.id} onChange={this.setTendency}>
								{this.state.tendencies}
							</select>
						</div>
						<div className="col-md-3">
							<label htmlFor="studyType">Ta'lim shakli</label>
							<select className="form-control" id="studyType" value={this.state.course.studyType.id} onChange={this.setStudyType}>
								<option value={1}>An'anaviy</option>
								<option value={2}>Onlayn</option>
								<option value={3}>Offlayn</option>
								<option value={4}>Sayyor</option>
							</select>
						</div>
					</div>
					<div className="form-row mt-3">
						<div className="col-md-3">
							<label htmlFor="startDate">Boshlangan vaqti</label>
							<DatePicker className="form-control" id="startDate" locale="uz" dateFormat="dd.MM.yyyy" selected={this.state.course.startDate !== undefined && this.state.course.startDate !== null ? this.state.course.startDate : new Date()} onChange={this.setStartDate.bind(this)}/>
						</div>
						<div className="col-md-3">
							<label htmlFor="endDate">Tugagan vaqti</label>
							<DatePicker className="form-control" id="endDate" locale="uz" dateFormat="dd.MM.yyyy" selected={this.state.course.endDate !== undefined && this.state.course.endDate !== null ? this.state.course.endDate : new Date()} onChange={this.setEndDate.bind(this)}/>
						</div>
						<div className="col-md-3">
							<label htmlFor="examDate">Imtihon vaqti</label>
							<DatePicker className="form-control" id="examDate" locale="uz" dateFormat="dd.MM.yyyy" selected={this.state.course.examDate !== undefined && this.state.course.examDate !== null ? this.state.course.examDate : new Date()} onChange={this.setExamDate.bind(this)}/>
						</div>
						<div className="col-md-3">
							<button className="btn btn-success mt-4" onClick={this.updateGroup.bind(this)}>Saqlash</button>
						</div>
					</div>
					<div className="form-row mt-5">
						<div className="col-md-3 text-center">
							<span><b>Tinglovchilar ro`yxati</b></span>
						</div>
						<div className="col-md-2 text-center">
							<button className="btn btn-info" onClick={this.openModal.bind(this)}>Tinglovchi qo`shish</button>
						</div>
						<div className="col-md-1">
							<button className="btn btn-info" onClick={this.openImportModal.bind(this)} title="Импорт"><i className="fa fa-file-excel-o"/></button>
						</div>
						{user !== undefined && user !== null && (user.roles[0] === 'CONTROLLER' || user.roles[0] === 'MAIN_CONTROLLER') &&
						<div className="col-md-3 text-center">
							<button className="btn btn-info" onClick={this.courseStart.bind(this)}>O`quv jarayonini boshlash</button>
						</div>
						}
						{user !== undefined && user !== null && (user.roles[0] === 'CONTROLLER' || user.roles[0] === 'MAIN_CONTROLLER') &&
							<div className="col-md-3 text-center">
								<button className="btn btn-info" onClick={this.courseFinish.bind(this)}>O`quv jarayonini yakunlash</button>
							</div>
						}
						{user !== undefined && user !== null && user.roles[0] === 'ACCOUNTANT' &&
							<div className="col-md-6 text-right">
								<form id="listenerListForm" method="post" action={apiHost + "/common/listenerListExcel"}>
									<button className="btn btn-info" title="Excelga eksport qilish" onClick={this.exportListenerList.bind(this)}>
										Tinglovchilar ro`yxati
									</button>
								</form>
							</div>
						}
					</div>
					<div className="form-row mt-3">
						<div className="col-md-12">
							<table className="table table-striped table-bordered shadow">
								<thead>
                                    {tableHeaders}
								</thead>
								<tbody>
								{this.state.rows}
								</tbody>
							</table>
						</div>
					</div>
					<div className="form-row">
						<div className="col-md-12 text-center mt-3">
							<NavLink className="btn btn-success" to="/groups">Ro`yxatga qaytish</NavLink>
						</div>
					</div>
				</div>
				<Modal open={this.state.modalOpen} onClose={this.closeModal.bind(this)} center>
					<div style={{margin: "30px 0 0 0 "}}>
						<table className="table table-striped table-bordered shadow">
							<thead>
							<tr>
								<th scope="col" className="align-middle">&nbsp;</th>
								<th scope="col" className="align-middle">ID</th>
								<th scope="col" className="align-middle">Tinglovchining F.I.Sh</th>
								<th scope="col" className="align-middle">Yo`nalishi</th>
							</tr>
							</thead>
							<tbody>
							{this.state.peoples}
							</tbody>
						</table>
					</div>
					<div className="form-row">
						<div className="col-md-12">
							<button className="btn btn-info" onClick={this.addListener.bind(this)}>Saqlash</button>
						</div>
					</div>
				</Modal>
				<Modal open={this.state.importModalOpen} onClose={this.closeImportModal.bind(this)} center>
					<div style={{margin: "30px 0 0 0 "}}>
						<div className="form-row">
							<div className="col-md-12">
								<a href="/files/listeners.xlsx">Shablon fayl</a>
							</div>
						</div>
						<div className="form-row">
							<div className="col-md-8">
								<input type="file" id="fileUpload"/>
							</div>
							<div className="col-md-4">
								<button className="btn btn-info" onClick={this.importListener.bind(this)}>Import qilish</button>
							</div>
						</div>
					</div>
				</Modal>
				<Modal open={this.state.amountModalOpen} onClose={this.onCloseAmountModal.bind(this)} center>
					<div style={{padding: "20px", minWidth: "200px"}}>
						<div className="form-row">
							<div className="col-md-12">
								<label htmlFor="tendency">To`lov miqdori</label>
								<input type="number" className="form-control" id="payedAmount" onChange={this.setValue}/>
							</div>
						</div>
						<div className="form-row mt-2">
							<div className="col-md-12 text-center">
								<button className="btn btn-success" onClick={this.enterPayedAmount.bind(this)}>Saqlash</button>
							</div>
						</div>
					</div>
				</Modal>
			</div>
		)
	}
}