import React from "react";
import DatePicker from "react-datepicker/es";
import axios from "axios";
import swal from "sweetalert";
import {apiHost, logout, pageNotFound} from "./Utils";
import ListenerItem from "./ListenerItem";
import * as _ from "./Utils";
import authHeader from "./services/auth-header";

export default class AddOldAgreementView extends React.Component {

    constructor() {
        super();
        this.state = {
            agreementDate: null,
            listenerCount: 1,
            listeners: [],
            tendencies: []
        };
        this.addRow = this.addRow.bind(this);
    }

    componentDidMount() {
        this.addRow();
    }

    getTendencies(e) {
        let categoryId = e.target.value;
        axios.get(_.apiHost + '/api/agreement/topicTendencies?id=' + categoryId, authHeader()).then(result => {
            if (result.status === 200) {
                if (result.data !== undefined && result.data !== null) {
                    let items = result.data.map(item => {
                        return <option key={item.id} value={item.id}>{item.name}</option>
                    });
                    if (result.data.length > 1) {
                        items.unshift(<option key={'t_0'} value={0}>Tanlang</option>);
                    }
                    this.setState({
                        tendencies: items
                    });
                }
            }
        }).catch(error=>{
            if (error !== undefined && error !== null && error.response.status === 401) {
                logout();
            }
        });
    }

    setDate(date) {
        this.setState({
            agreementDate: date
        })
    }

    save() {
        let categoryId = document.getElementById('category').value;
        let tendencyId = document.getElementById('tendency').value;
        let regionId = document.getElementById('region').value;
        let agreementNumber = document.getElementById('agreementNumber').value;
        let agreementAmount = document.getElementById('agreementAmount').value;
        let organizationName = document.getElementById('organizationName').value;
        if (categoryId == null || categoryId === '' || Number(categoryId) === 0) {
            swal('Turini tanlang', '', 'error');
            return;
        }
        if (tendencyId == null || tendencyId === '') {
            swal('Yo`nalishni tanlang', '', 'error');
            return;
        }
        if (regionId == null || regionId === '') {
            swal('Hududni tanlang', '', 'error');
            return;
        }
        if (agreementNumber == null || agreementNumber === '') {
            swal('Shartnoma raqamini kiriting', '', 'error');
            return;
        }
        if (agreementAmount == null || agreementNumber === '' || Number(agreementNumber) <= 0) {
            swal('Shartnoma summasini kiriting', '', 'error');
            return;
        }
        if (organizationName == null || organizationName === '') {
            swal('Tashkilot nomini kiriting', '', 'error');
            return;
        }
        if (this.state.agreementDate === null) {
            swal('Shartnoma sanasini kiriting', '', 'error');
            return;
        }
        let phoneNumber = document.getElementById('phoneNumber').value;
        let data = new FormData();
        data.append("categoryId", categoryId);
        data.append("tendencyId", tendencyId);
        data.append("regionId", regionId);
        data.append("agreementNumber", agreementNumber);
        data.append("amount", Number(agreementAmount));
        data.append("agreementDate", this.state.agreementDate);
        data.append("organizationName", organizationName);
        data.append("phoneNumber", phoneNumber);

        let json = '[';
        let listeners = this.state.listeners;
        for (let i = 0; i < listeners.length; i++) {
            let item = listeners[i];
            let lastName = document.getElementById('lastName' + item.key).value;
            if (lastName === null || lastName.length === 0) {
                swal('Tinglovchining familiyasini kiriting', '', 'error');
                return;
            }
            let firstName = document.getElementById('firstName' + item.key).value;
            if (firstName === null || firstName.length === 0) {
                swal('Tinglovchining ismini kiriting', '', 'error');
                return;
            }
            let middleName = document.getElementById('middleName' + item.key).value;
            if (middleName === null || middleName.length === 0) {
                swal('Tinglovchining sharifini kiriting', '', 'error');
                return;
            }
            let position = document.getElementById('position' + item.key).value;
            if (position === null || position.length === 0) {
                swal('Tinglovchining lavozimini kiriting', '', 'error');
                return;
            }
            let phoneNumber = document.getElementById('phoneNumber' + item.key).value;
            if (phoneNumber === null || phoneNumber.length === 0) {
                swal('Tinglovchining telefon raqamini kiriting', '', 'error');
                return;
            }
            let passportNumber = document.getElementById('passportNumber' + item.key).value;
            if (passportNumber === null || passportNumber.length === 0) {
                swal('Tinglovchining pasport seriyasi va raqamini kiriting', '', 'error');
                return;
            } else if (passportNumber.length < 9 || passportNumber.length > 10) {
                swal('Tinglovchining pasport seriyasi va raqamini to`g`ri kiriting', '', 'error');
            }

            let l = '{"lastName":"' + lastName + '", "firstName":"' + firstName + '", "middleName":"' + middleName +
                '", "position":"' + position + '", "phoneNumber":"' + phoneNumber + '", "passportNumber":"' + passportNumber + '"},';
            json = json + l;
        }
        json = json.slice(0, -1) + ']';
        data.append('listeners', JSON.stringify(json));
        let passports = document.getElementById('passports').files;
        if (passports.length === this.state.listeners.length) {
            for (let i = 0; i < passports.length; i++) {
                data.append("passports", passports[i]);
            }
        } else {
            swal('Barcha tinglovchilarning pasport nusxasini yuklang', '', 'error');
            return;
        }

        let token = {};
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.token) {
            token = { Authorization: 'Bearer ' + user.token };
        }
        axios.post(apiHost + '/api/agreement/oldAgreement/add', data, {
            headers: {'Content-type': 'multipart/form-data', token},
        }).then(result=>{
            if (result.status === 200) {
                swal(result.data.message, '', 'success');
            } else {
                swal(result.data.message, '', 'error');
            }
        }).catch(error=>{
            if (error !== undefined && error !== null && error.response.status === 401) {
                logout();
            }
        })
    }

    addRow() {
        let listeners = this.state.listeners;
        let listenerCount = this.state.listenerCount + 1;
        listeners.push(<ListenerItem key={'lc_' + listenerCount} rowId={'lc_' + listenerCount} addRow={this.addRow}
                                     removeRow={this.removeRow.bind(this, 'lc_' + listenerCount)}/>);
        this.setState({
            listeners: listeners,
            listenerCount: listenerCount
        })
    }

    removeRow(key) {
        let listeners = this.state.listeners;
        this.state.listeners.forEach((l, index) => {
            if (l.key === key) {
                listeners.splice(index, 1);
            }
        });
        this.setState({
            listeners: listeners
        })
    }

    render() {
        pageNotFound();
        return (
            <div className="container mt-2">
                <div className="card card-body">
                    <div className="form-row">
                        <div className="col-md-12 text-center">
                            <h4>Shartnoma ma'lumotlarini kiritish</h4>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-2">
                            <label className="col-form-label" htmlFor="category">Turi</label>
                            <select className="form-control" id="category" onChange={this.getTendencies.bind(this)}>
                                <option value={0}>Tanlang</option>
                                <option value={1}>Mediatorlik kurslari</option>
                                <option value={2}>Qayta tayyorlash kurslari</option>
                                <option value={3}>Malaka oshirish kurslari</option>
                                <option value={4}>Qisqa muddatli kurslar</option>

                            </select>
                        </div>
                        <div className="col-md-10">
                            <label className="col-form-label" htmlFor="tendency">Yo`nalishi</label>
                            <select className="form-control" id="tendency">
                                {this.state.tendencies}
                            </select>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="col-md-2">
                            <label className="col-form-label" htmlFor="agreementNumber">Shartnoma raqami</label>
                            <input className="form-control" id="agreementNumber"/>
                        </div>
                        <div className="col-md-2">
                            <label className="col-form-label" htmlFor="agreementAmount">Shartnoma summasi</label>
                            <input type="number" className="form-control" id="agreementAmount"/>
                        </div>
                        <div className="col-md-3">
                            <label className="col-form-label" htmlFor="agreementDate">Shartnoma sanasi</label>
                            <br/>
                            <DatePicker className="form-control" id="agreementDate" locale="uz" dateFormat="dd.MM.yyyy"
                                        selected={this.state.agreementDate} onChange={this.setDate.bind(this)}/>
                        </div>
                        <div className="col-md-2">
                            <label className="col-form-label" htmlFor="phoneNumber">Telefon raqami</label>
                            <input className="form-control" id="phoneNumber"/>
                        </div>
                        <div className="col-md-3">
                            <label className="col-form-label" htmlFor="region">Hudud</label>
                            <select className="form-control" id="region">
                                {_.getRegions()}
                            </select>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-12">
                            <label className="col-form-label" htmlFor="organizationName">Tashkilot nomi</label>
                            <textarea className="form-control" id="organizationName" rows={3}/>
                        </div>
                    </div>
                    <hr/>
                    <div className="form-row mt-3">
                        <div className="col-md-12 text-center">
                            <h4>Tinglovchilar</h4>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-12">
                            {this.state.listeners}
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-12">
                            <h5><b>Tinglovchilar pasportlarini nusxasini yuklang</b></h5>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-7">
                            <input type="file" id="passports" multiple/>
                        </div>
                    </div>
                    <div className="form-row mt-3">
                        <div className="col-md-12 text-center">
                            <button className="btn btn-success" onClick={this.save.bind(this)}>Saqlash</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}