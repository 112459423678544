import React, {Component} from "react";
import axios from "axios";
import {apiHost, logout, pageNotFound} from "./Utils";
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import Cookies from 'universal-cookie';
import './styles/loading.sass';
import ReactPaginate from 'react-paginate';
import 'react-responsive-modal/styles.css';
import Modal from 'react-responsive-modal';
import DatePicker from "react-datepicker";
import getTime from 'date-fns/getTime';
import authHeader from "./services/auth-header";

const cookies = new Cookies();

export default class ListenersListView extends Component {

	constructor(props) {
		super(props);
        let urlParams = new URLSearchParams(window.location.search);
        let listenerType = urlParams.get('type');
        let height = window.screen.height - 360;
		this.state = {
            listenerType: listenerType,
			showLoading: true,
			tableRows: [],
			modalOpen: false,
			changeGroupModalOpen: false,
			infoDocModal: false,
			generateCertModal: false,
			listener: null,
			searchKey: '',
			category: 0,
			status: 0,
            group: 0,
			paymentStatus: 0,
			categories: [],
			groups: [],
            payedAmount: 0,
            pageCount: 1,
            perPage: 50,
            data: [],
            offset: 50,
            start: 0,
            maxHeight: height,
			selectedListener: {},
			docDate: new Date(),
			orderDate: new Date()
		};
		this.setValue = this.setValue.bind(this);
	}

	componentDidMount() {
		axios.get(apiHost + '/api/agreement/topicTendencies', authHeader())
			.then(result => {
				let items = [];
				if (result.data !== null && result.data.length>0) {
					items = result.data.map(item=>{
						return <option key={'tt_' + item.id} value={item.id}>{item.name}</option>
					});
					items.unshift(<option key={'tt_' + 0} value="0">Hammasi</option>);
				}
				this.setState({
					categories: items
				})
			}).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		});
		axios.get(apiHost + '/api/course/list?type=' + this.state.listenerType, authHeader())
			.then(result => {
				let items = [];
				if (result.data !== null && result.data.length>0) {
					items = result.data.map(item=>{
						return <option key={'t_' + item.id} value={item.id}>{item.name}</option>
					});
					items.unshift(<option key={'t_' + 0} value="0">Hammasi</option>);
				}
				this.setState({
					groups: items
				})
			}).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		});
		this.fetchData(this.state.status, this.state.paymentStatus, this.state.category, this.state.group, this.state.start, this.state.offset);
	}

    handlePageClick = (data) => {
        let selected = data.selected;
        let offset = Math.ceil((selected + 1) * this.state.perPage);
        let start = selected === 0 ? 0 : selected * this.state.perPage + 1;

        this.setState({
			offset: offset,
			start: start
		});
        this.fetchData(this.state.status, this.state.paymentStatus, this.state.category, this.state.group, start, offset);
    };

	setValue(e) {
		let name = e.target.id;
		let value = e.target.value;

        this.setState({
			[name]: value,
		});
        if (name !== 'payedAmount') {
            if (name === 'category') {
                this.fetchData(this.state.status, this.state.paymentStatus, value, this.state.group, 0, 1000000);
            } else if (name === 'status') {
                this.fetchData(value, this.state.paymentStatus, this.state.category, this.state.group, 0, 1000000);
            } else if (name === 'group') {
                this.fetchData(this.state.status, this.state.paymentStatus, this.state.category, value, 0, 1000000);
            } else {
                this.fetchData(this.state.status, value, this.state.category, this.state.group, 0, 1000000);
            }
        }
	}

	fetchData(status, paymentStatus, category, group, start, offset) {
		this.setState({
			showLoading: true
		});
		axios.get(apiHost + '/api/listener/list?type=' + this.state.listenerType + '&status=' + status + '&paymentStatus=' + paymentStatus +
			'&category=' + category + '&group=' + group + '&start=' + start + '&offset=' + offset, authHeader())
			.then(result => {
				this.populateData(result);
			}).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		})
	}

	addListener() {
		localStorage.removeItem('listenerId');
		window.open(window.location.protocol + '//' + window.location.host + '/addListener?type=' + this.state.listenerType, '_self');
	}

	edit(listenerId) {
		localStorage.setItem('listenerId', listenerId);
		window.open(window.location.protocol + '//' + window.location.host + '/addListener', '_self');
	}

	checkCertificate(row) {
		axios({
			url: apiHost + '/api/listener/downloadCertificate/' + row.certificateLink,
			method: "GET",
			headers: authHeader().headers,
			responseType: "blob"
		})
			.then(result => {
				const link = document.createElement("a");
				link.href = window.URL.createObjectURL(new Blob([result.data]));
				link.setAttribute(
					'download',
					row.certificateLink + '.pdf'
				);

				document.body.appendChild(link);

				link.click();
				link.parentNode.removeChild(link);
			})
	}

    enterPayedAmount() {
        axios.post(apiHost + '/api/listener/enterPayedAmount?listenerId=' + this.state.listener.id + '&amount=' +
			this.state.payedAmount, null, authHeader())
            .then(result => {
                if (result.status === 200) {
                    this.fetchData(this.state.status, this.state.paymentStatus, this.state.category, this.state.group, this.state.start, this.state.offset);
                    swal(result.data.message, "", "success");
                } else if (result.status === 500) {
                    swal(result.data.message, "", "error");
                }
            }).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		})
	}

    changeGroup() {
        axios.post(apiHost + '/api/listener/changeGroup?listenerId=' + this.state.listener.id + '&groupId=' +
			this.state.group, null, authHeader())
            .then(result => {
                if (result.status === 200) {
                    this.fetchData(this.state.status, this.state.paymentStatus, this.state.category, this.state.group, this.state.start, this.state.offset);
                    swal(result.data.message, "", "success");
                } else if (result.status === 500) {
                    swal(result.data.message, "", "error");
                }
            }).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		})
	}

    openGroupPage(courseId) {
        localStorage.setItem('courseId', courseId);
        window.open(window.location.protocol + '//' + window.location.host + '/groupView', '_blank');
    }

    openAgreementPage(agreementId) {
        localStorage.setItem('agreementId', agreementId);
        window.open(window.location.protocol + '//' + window.location.host + '/agreementView', '_blank');
    }

	showInfoDocModal(listener) {
		this.setState({
			infoDocModal: true,
			selectedListener: listener
		})
	}
	hideInfoDocModal() {
		this.setState({
			infoDocModal: false,
			selectedListener: {}
		})
	}

    getInfoDoc() {
		let docDate = getTime(this.state.docDate);
		let registerNumber = document.getElementById('registerNumber').value;
		let orderDate = getTime(this.state.orderDate);
		let orderNumber = document.getElementById('orderNumber').value;
        axios.get(apiHost + '/api/listener/infoDoc?listenerId=' + this.state.selectedListener.id + '&docDate=' + docDate + '&registerNumber=' +
			registerNumber + '&orderDate=' + orderDate + '&orderNumber=' + orderNumber, authHeader())
            .then(result => {
                if (result.status === 200) {
                    window.open(apiHost + result.data, "_blank");
                } else if (result.status === 500) {
                    swal(result.data.message, "", "error");
                }
            }).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		})
	}

	populateData(result) {
		const query = new URLSearchParams(window.location.search);
		const userType = query.get('type');
		let listeners = [];
		if (result.status === 200) {
			let user = JSON.parse(localStorage.getItem('user'));
			listeners = result.data.data.map((row, index)=> {
				return <tr key={'llv_' + index} style={{backgroundColor: row.status.id === 1 ? '#d6d6d6' :
					row.status.id === 3 ? '#00ffff' : row.status.id === 4 ? '#FFFC20' : (row.status.id === 5 || row.status.id === 6) ? '#40FF6B' : '#FFFFFF'}}>
					<td>{row.id}</td>
					<td>{row.lastName} {row.firstName} {row.middleName}</td>
					<td>{row.passportNumber}</td>
					<td>{row.phoneNumber}</td>
					<td>{row.tendencyName}</td>
					<td><span className="linkStyle" title={row.group.name} onClick={this.openGroupPage.bind(this, row.group.id)}>{row.group.name}</span></td>
					<td>{row.registerNumber}</td>
					<td>{row.certificateNumber}</td>
                    {this.state.listenerType === 'paid' ? <td><span className="linkStyle" title={row.agreement.name} onClick={this.openAgreementPage.bind(this, row.agreement.id)}>{row.agreement.name}</span></td> : ''}
					<td>{row.status.name}</td>
                    {this.state.listenerType === 'paid' ? <td style={{backgroundColor: row.paymentStatus === 2 ? "#ffee22" : "transparent"}}>{row.paymentStatus === 1 ? "To‘lanmagan" : row.paymentStatus === 2 ? "Qisman to‘langan" : row.paymentStatus === 3 ? "To‘langan" : ""}</td> : ''}
					<td>
                        {user.roles[0] !== 'ACCOUNTANT' &&
						<div>
                            {((row.tendencyName !== 'Mediator' && this.state.listenerType === 'paid' && row.status.id === 5
							&& user.roles[0] === 'MAIN_CONTROLLER' && (row.certificateNumber === null || row.certificateNumber === '')
							&& Number(row.paymentStatus) === 3) || (userType === 'free' && row.status.id === 5 && (row.certificateNumber === null || row.certificateNumber === ''))) &&
							<i className="fa fa-address-card-o linkStyle mr-1" title="Sertifikat berish"
							   onClick={this.generateCertificate.bind(this, row.id)}/>
                            }
                            {(row.tendencyName === 'Mediator' && row.status.id === 5 && user.roles[0] === 'MAIN_CONTROLLER' && (row.certificateNumber === null || row.certificateNumber === '')) &&
							<i className="fa fa-address-card-o linkStyle mr-1" title="Sertifikat berish"
							   onClick={this.openGenerateCertModal.bind(this, row)}/>
                            }
                            {((row.status.id === 6 && (user.roles[0] === 'CONTROLLER' || user.roles[0] === 'MAIN_CONTROLLER') &&
								(row.certificateNumber !== null && row.certificateNumber.length > 0)) ||
								(userType === 'free' && row.status.id === 5 && (row.certificateNumber !== null && row.certificateNumber !== ''))) &&
							<i className="fa fa-download linkStyle mr-1" title="Sertifikatni tekshirish"
							   onClick={this.checkCertificate.bind(this, row)}/>
                            }
                            {(user.roles[0] === 'MARKETING' || user.roles[0] === 'CONTROLLER' || user.roles[0] === 'MAIN_CONTROLLER') &&
							<i className="fa fa-edit linkStyle mr-1" title="Tahrirlash"
							   onClick={this.edit.bind(this, row.id)}/>
                            }
                            {(user.roles[0] === 'CONTROLLER' || user.roles[0] === 'MAIN_CONTROLLER') &&
							<i className="fa fa-exclamation-triangle linkStyle mr-1" title="O‘qishdan chetlatish"
							   onClick={this.changeStatus.bind(this, row)}/>
                            }
                            {(user.roles[0] === 'MAIN_CONTROLLER' && row.certificateNumber !== null && row.certificateNumber !== '') &&
							<i className="fa fa-recycle linkStyle mr-1" title="Sertifikatni bekor qilish"
							   onClick={this.revertCertificate.bind(this, row)}/>
                            }
                            {(user.roles[0] === 'MARKETING' || user.roles[0] === 'CONTROLLER' || user.roles[0] === 'MAIN_CONTROLLER') &&
							<i className="fa fa-remove linkStyle mr-1" title="O‘chirish"
							   onClick={this.removeListener.bind(this, row)}/>
                            }
                            {(user.roles[0] === 'MARKETING' || user.roles[0] === 'CONTROLLER' || user.roles[0] === 'MAIN_CONTROLLER') &&
							<>
							<i className="fa fa-refresh linkStyle mr-1" title="Guruhni o‘zgartirish"
							   onClick={this.openChangeGroupModal.bind(this, row)}/>
							<i className="fa fa-info linkStyle mr-1" title="Ma'lumotnoma"
							   onClick={this.showInfoDocModal.bind(this, row)}/>
							</>
                            }
						</div>
                        }
                        {(user.roles[0] === 'ACCOUNTANT') &&
						<i className="fa fa-edit linkStyle mr-1" title="Summani kiritish"
						   onClick={this.inputAmount.bind(this, row)}/>
                        }
					</td>
				</tr>});
		}
		this.setState({
			tableRows: listeners,
            pageCount: result.data.pageCount,
			showLoading: false
		})
	}

	openGenerateCertModal(listener) {
		this.setState({
			selectedListener: listener,
			generateCertModal: true
		})
	}

	closeGenerateCertModal() {
		this.setState({
			selectedListener: {},
			generateCertModal: false
		})
	}

	inputAmount(listener) {
		this.setState({
			listener: listener,
			modalOpen: true
		});
	}

	openChangeGroupModal(listener) {
		this.setState({
			listener: listener,
			changeGroupModalOpen: true
		});
	}

	revertCertificate(listener) {
		axios.post(apiHost + '/api/listener/revertCertificate?listenerId=' + listener.id, null, authHeader())
			.then(result => {
				if (result.status === 200) {
					this.fetchData(this.state.status, this.state.paymentStatus, this.state.category, this.state.group, this.state.start, this.state.offset);
					swal(result.data.message, "", "success");
				} else if (result.status === 500) {
					swal(result.data.message, "", "error");
				}
			}).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		})
	}

    removeListener(listener) {
        Swal.fire({
            title: 'Siz haqiqatdan ham "' + listener.lastName + ' ' + listener.firstName + ' ' + listener.middleName + "\" ma'lumotini o‘chirmoqchimisiz?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'O‘chirish',
            cancelButtonText: 'Bekor qilish'
        }).then((result1) => {
            if (result1.value) {
                axios.post(apiHost + '/api/listener/removeListener?listenerId=' + listener.id, null, authHeader())
                    .then(result => {
                        if (result.status === 200) {
                            Swal.fire(
                                'O‘chirildi!',
                                result.data.message,
                                'success'
                            );
                            this.fetchData(this.state.status, this.state.paymentStatus, this.state.category, this.state.group, this.state.start, this.state.offset);
                        } else {
                            Swal.fire(
                                'Xatolik',
                                result.data.message,
                                'error'
                            )
                        }
                    }).catch(error=>{
					if (error !== undefined && error !== null && error.response.status === 401) {
						logout();
					}
				});
            } else if (result1.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Bekor qilindi',
                    '',
                    'error'
                )
            }
        });
    }

	changeStatus(listener) {
		axios.post(apiHost + '/api/listener/leaveFromGroup?listenerId=' + listener.id, null, authHeader())
			.then(result => {
				if (result.status === 200) {
					this.fetchData(this.state.status, this.state.paymentStatus, this.state.category, this.state.group, this.state.start, this.state.offset);
					swal(result.data.message, "", "success");
				} else if (result.status === 500) {
					swal(result.data.message, "", "error");
				}
			}).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		})
	}

	generateCertificate(listenerId) {
		axios.post(apiHost + '/api/listener/generateCertificate?listenerId=' + listenerId, null, authHeader())
			.then(result => {
				if (result.status === 200) {
					this.fetchData(this.state.status, this.state.paymentStatus, this.state.category, this.state.group, this.state.start, this.state.offset);
					swal(result.data.message, "", "success");
				} else if (result.status === 500) {
					swal(result.data.message, "", "error");
				}
			}).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		})
	}

	generateMediatorCertificate() {
		let certificateNumber = document.getElementById('certificateNumber').value;
		let registerNumber = document.getElementById('registerNumber2').value;
		axios.post(apiHost + '/api/listener/generateMediatorCertificate?listenerId=' + this.state.selectedListener.id +
			'&certificateNumber=' + certificateNumber + '&registerNumber=' + registerNumber, null, authHeader()).then(result => {
			if (result.status === 200) {
				this.fetchData(this.state.status, this.state.paymentStatus, this.state.category, this.state.group, this.state.start, this.state.offset);
				swal(result.data.message, "", "success");
			} else if (result.status === 500) {
				swal(result.data.message, "", "error");
			}
		}).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		})
	}

	onCloseModal(modalName) {
		this.setState({
			[modalName]: false
		})
	}

	onChangeValue() {
		let searchKey = document.getElementById('searchBox').value;
		this.setState({
			searchKey: searchKey
		})
	}

	search() {
		if (this.state.searchKey !== '') {
			this.setState({
				showLoading: true
			});
			axios.get(apiHost + '/api/listener/search?keyword=' + this.state.searchKey, authHeader())
				.then(result => {
					this.populateData(result);
				}).catch(error=>{
				if (error !== undefined && error !== null && error.response.status === 401) {
					logout();
				}
			})
		}
	}

	exportToExcel() {
		let statusId = document.getElementById('status').value;
		let paymentStatusId = document.getElementById('paymentStatus').value;
		let categoryId = document.getElementById('category').value;
		let groupId = document.getElementById('group').value;
		cookies.set('paymentStatusId', paymentStatusId, {path: '/'});
		cookies.set('categoryId', categoryId, {path: '/'});
		cookies.set('statusId', statusId, {path: '/'});
		cookies.set('groupId', groupId, {path: '/'});
		cookies.set('listenerType', this.state.listenerType, {path: '/'});
		let form = document.getElementById('excelForm');
		form.submit();
	}

	setDocDate(docDate) {
		this.setState({
			docDate: docDate
		})
	}

	setOrderDate(orderDate) {
		this.setState({
			orderDate: orderDate
		})
	}

	render() {
		pageNotFound();
		return(
			<div className="content">
				{this.state.showLoading &&
				<div className="container">
					<div className="loader">
						<div className="loader--dot"/>
						<div className="loader--dot"/>
						<div className="loader--dot"/>
						<div className="loader--dot"/>
						<div className="loader--dot"/>
						<div className="loader--dot"/>
						<div className="loader--text"/>
					</div>
				</div>
				}
				<div className="p-0 card card-body shadow-sm">
					<div className="row">
						<div className="col-md-6 text-right">
							<h3>Tinglovchilar ro‘yxati</h3>
						</div>
						<div className="col-md-6">
							<form className="form-inline float-right">
								<div className="input-group">
									<input className="form-control" id="searchBox" placeholder="Qidiruv so‘zi" aria-label="Qidirish" aria-describedby="basic-addon2" onChange={this.onChangeValue.bind(this)}/>
									<div className="input-group-append">
										<button className="btn btn-outline-info" type="button" onClick={this.search.bind(this)}><i className="fa fa-search"/></button>
									</div>
								</div>
							</form>
						</div>
					</div>
					<div className="row mt-1">
						<div className="col-md-12 text-right">
							<div className="input-group shadow-sm">
								<button className="btn btn-info mr-1" onClick={this.addListener.bind(this)}>
									<i className="fa fa-plus-square-o"/></button>
								<select id="category" className="form-control" onChange={this.setValue}>
									{this.state.categories}
								</select>
								<select id="group" className="form-control" onChange={this.setValue}>
                                    {this.state.groups}
								</select>
								<select id="status" className="form-control" onChange={this.setValue}>
									<option value={0}>Hammasi</option>
									<option value={1}>Hujjat topshirgan</option>
									<option value={10}>Guruhga qo‘shildi</option>
									<option value={2}>O‘quv jarayonida</option>
									<option value={3}>Imtihon jarayonida</option>
									<option value={4}>Kutish jarayonida</option>
									<option value={5}>Kursni yakunladi</option>
									<option value={6}>Sertifikat olgan</option>
									<option value={7}>Uzrli sababga ko‘ra kelmadi</option>
									<option value={8}>Uzrsiz sababga ko‘ra kelmadi</option>
									<option value={9}>O‘qishdan chetlatildi</option>
									<option value={11}>Pul to`ladi</option>
									<option value={12}>Tinglovchilar safidan chiqarildi</option>
								</select>
								<select id="paymentStatus" className="form-control" onChange={this.setValue}>
									<option value={0}>Hammasi</option>
									<option value={1}>To‘lagan</option>
									<option value={2}>Qisman to‘lagan</option>
									<option value={3}>To‘lamagan</option>
								</select>
								<form id="excelForm" method="post" action={apiHost + "/common/listenerListReportExcel"}>
									<button className="ml-1 btn btn-info" onClick={this.exportToExcel.bind(this)}><i className="fa fa-file-excel-o"/></button>
								</form>
							</div>
						</div>
					</div>
					<div className="row pt-1">
						<div className="col-md-12 order-md-1" style={{overflowY: "scroll", maxHeight: this.state.maxHeight + "px"}}>
							<table className="table table-striped table-bordered shadow">
								<thead>
								<tr>
									<th style={{width: "5%"}}>№</th>
									<th>F.I.Sh</th>
									<th>Pasport raqami</th>
									<th>Telefon raqami</th>
									<th>Yo‘nalishi</th>
									<th>Guruh raqami</th>
									<th>Qayd raqami</th>
									<th>Sertifikat raqami</th>
                                    {this.state.listenerType === 'paid' ? <th>Shartnoma raqami</th> : ''}
									<th>Statusi</th>
                                    {this.state.listenerType === 'paid' ? <th>To‘lov statusi</th> : ''}
									<th>Amal</th>
								</tr>
								</thead>
								<tbody>
								{this.state.tableRows}
								</tbody>
							</table>
						</div>
						<div className="col-md-12 order-md-1 text-center pt-2">
							<ReactPaginate
								previousLabel={'<<'}
								nextLabel={'>>'}
								breakLabel={'...'}
								breakClassName={'break-me'}
								pageCount={this.state.pageCount}
								marginPagesDisplayed={1}
								pageRangeDisplayed={5}
								onPageChange={this.handlePageClick}
								containerClassName={'pagination'}
								subContainerClassName={'pagination'}
								activeClassName={'active'}
							/>
						</div>
					</div>
					<Modal open={this.state.generateCertModal} onClose={this.closeGenerateCertModal.bind(this)} center>
						<div style={{padding: "20px", minWidth: "200px"}}>
							<div className="form-row">
								<div className="col-md-12">
									<h4>Tinglovchi: {this.state.selectedListener.lastName + ' ' + this.state.selectedListener.firstName + ' ' + this.state.selectedListener.middleName}</h4>
								</div>
							</div>
							<div className="form-row">
								<div className="col-md-6">
									<label htmlFor="certificateNumber">Sertifikat raqami</label>
								</div>
								<div className="col-md-6">
									<input className="form-control" id="certificateNumber"/>
								</div>
							</div>
							<div className="form-row mt-2">
								<div className="col-md-6">
									<label htmlFor="registerNumber2">Qayd raqami</label>
								</div>
								<div className="col-md-6">
									<input className="form-control" id="registerNumber2"/>
								</div>
							</div>
							<div className="form-row mt-2">
								<div className="col-md-12 text-center">
									<button className="btn btn-success" onClick={this.generateMediatorCertificate.bind(this)}>Sertifikat berish</button>
								</div>
							</div>
						</div>
					</Modal>
					<Modal open={this.state.infoDocModal} onClose={this.hideInfoDocModal.bind(this)} center>
						<div style={{padding: "20px", minWidth: "200px"}}>
							<div className="form-row">
								<div className="col-md-6">
									<label htmlFor="docDate">Berilgan vaqti</label>
								</div>
								<div className="col-md-6">
									<DatePicker className="form-control" id="docDate" locale="uz" selected={this.state.docDate}
												dateFormat="dd.MM.yyyy" onChange={this.setDocDate.bind(this)}/>
								</div>
							</div>
							<div className="form-row">
								<div className="col-md-6">
									<label htmlFor="registerNumber">Qayd raqami</label>
								</div>
								<div className="col-md-6">
									<input className="form-control" id="registerNumber"/>
								</div>
							</div>
							<div className="form-row">
								<div className="col-md-6">
									<label htmlFor="orderDate">Buyruq sanasi</label>
								</div>
								<div className="col-md-6">
									<DatePicker className="form-control" id="orderDate" locale="uz" selected={this.state.orderDate}
												dateFormat="dd.MM.yyyy" onChange={this.setOrderDate.bind(this)}/>
								</div>
							</div>
							<div className="form-row">
								<div className="col-md-6">
									<label htmlFor="orderNumber">Buyruq raqami</label>
								</div>
								<div className="col-md-6">
									<input className="form-control" id="orderNumber"/>
								</div>
							</div>
							<div className="form-row mt-2">
								<div className="col-md-12 text-center">
									<button className="btn btn-success" onClick={this.getInfoDoc.bind(this)}>Saqlash</button>
								</div>
							</div>
						</div>
					</Modal>
					<Modal open={this.state.modalOpen} onClose={this.onCloseModal.bind(this, 'modalOpen')} center>
						<div style={{padding: "20px", minWidth: "200px"}}>
							<div className="form-row">
								<div className="col-md-12">
									<label htmlFor="tendency">To‘lov miqdori</label>
									<input type="number" className="form-control" id="payedAmount" onChange={this.setValue}/>
								</div>
							</div>
							<div className="form-row mt-2">
								<div className="col-md-12 text-center">
									<button className="btn btn-success" onClick={this.enterPayedAmount.bind(this)}>Saqlash</button>
								</div>
							</div>
						</div>
					</Modal>
					<Modal open={this.state.changeGroupModalOpen} onClose={this.onCloseModal.bind(this, 'changeGroupModalOpen')} center>
						<div style={{padding: "20px", minWidth: "200px"}}>
							<div className="form-row">
								<div className="col-md-12">
									<label htmlFor="listenerName">Tinglovchi</label>
									<input className="form-control" disabled={true} id="listenerName"
                                           value={this.state.listener !== null ? this.state.listener.lastName + ' ' + this.state.listener.firstName + ' ' +  this.state.listener.middleName : ''}/>
								</div>
							</div>
							<div className="form-row">
								<div className="col-md-12">
									<label htmlFor="oldGroup">Avvalgi guruhi</label>
									<input className="form-control" disabled={true} id="oldGroup" value={this.state.listener !== null ? this.state.listener.group.name : ''}/>
								</div>
							</div>
							<div className="form-row">
								<div className="col-md-12">
									<label htmlFor="group">Yangi guruhi</label>
									<select className="form-control" id="group" onChange={this.setValue}>
										{this.state.groups}
									</select>
								</div>
							</div>
							<div className="form-row mt-2">
								<div className="col-md-12 text-center">
									<button className="btn btn-success" onClick={this.changeGroup.bind(this)}>Saqlash</button>
								</div>
							</div>
						</div>
					</Modal>
				</div>
			</div>
		)
	}
}