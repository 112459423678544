import React, {Component} from "react";
import axios from "axios";
import * as _ from "./Utils";
import authHeader from "./services/auth-header";
import {logout, pageNotFound} from "./Utils";

export default class ReportsView extends Component {

	constructor() {
		super();
		this.state = {
			memberRows: []
		}
	}

	componentDidMount() {
		axios.get(_.apiHost + '/api/member/list', authHeader())
			.then(result => {
				if (result.status === 200) {
					let options = [];
					for (let i = 0; i < result.data.length; i++) {
						let row = result.data[i];
						options.push(<tr>
							<th scope="row">{row.id}</th>
							<td>{row.lastName + ' ' + row.firstName}</td>
							<td>{row.position.name}</td>
						</tr>);
					}
					this.setState({
						memberRows: options
					})
				} else {
					this.setState({
						memberRows: []
					})
				}
			}).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		})
	}

	render() {
		pageNotFound();
		return (
			<div className="content">
				<div className="container card card-body my-4">
					<div className="form-row">
						<div className="col-md-10 text-center">
							<h3>Hisobotlar</h3>
						</div>
					</div>
				</div>
			</div>
		)
	}
}