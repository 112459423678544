import React, {Component} from 'react';
import {Tab, Tabs} from "react-bootstrap";
import * as _ from './Utils';
import axios from 'axios';
import './styles/loading.sass';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import uz from 'date-fns/locale/uz';
import getTime from 'date-fns/getTime';
import authHeader from "./services/auth-header";
import {logout, pageNotFound} from "./Utils";
registerLocale('uz', uz);
setDefaultLocale('uz');

export default class AccountantReportsView extends Component {

    constructor() {
        super();
        this.state = {
            key: 'byRegion',
            byRegionRows: [],
            startDate: new Date(),
            endDate: new Date(),
            showLoading: false
        }
    }

    componentDidMount() {
        this.getData(this.state.startDate, this.state.endDate);
    }

    getData(startDate, endDate) {
        this.setState({
            showLoading: true
        });
        axios.get(_.apiHost + '/api/reports/byRegion?startDate=' + getTime(startDate) + '&endDate=' + getTime(endDate), authHeader())
            .then(result => {
                if (result.status === 200) {

                }
                this.setState({
                    showLoading: false
                });
            }).catch(error=>{
            if (error !== undefined && error !== null && error.response.status === 401) {
                logout();
            }
        });
    }

    setStartDate(startDate) {
        this.setState({
            startDate: startDate
        });
        this.getData(startDate, this.state.endDate);
    }

    setEndDate(endDate) {
        this.setState({
            endDate: endDate
        });
        this.getData(this.state.startDate, endDate);
    }

    render() {
        pageNotFound();
        return (
            <div className="content">
                <div className="card card-body shadow">
                    <Tabs activeKey={this.state.key} onSelect={key => this.setState({key})}>
                        <Tab eventKey="byRegion" title="Hududlar bo`yicha">
                            <div className="form-row mt-2">
                                <div className="col-md-12 text-center">
                                    <h4>Hududlar bo`yicha hisobot</h4>
                                </div>
                            </div>

                            {this.state.showLoading &&
                            <div className="container">
                                <div className="loader">
                                    <div className="loader--dot"/>
                                    <div className="loader--dot"/>
                                    <div className="loader--dot"/>
                                    <div className="loader--dot"/>
                                    <div className="loader--dot"/>
                                    <div className="loader--dot"/>
                                    <div className="loader--text"/>
                                </div>
                            </div>
                            }
                            <div className="form-row mt-3">
                                <div className="col-md-12">
                                    <DatePicker className="form-control" id="startDate" locale="uz" dateFormat="dd.MM.yyyy" selected={this.state.startDate} onChange={this.setStartDate.bind(this)}/>
                                    <DatePicker className="form-control" id="endDate" locale="uz" dateFormat="dd.MM.yyyy" selected={this.state.endDate} onChange={this.setEndDate.bind(this)}/>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12">
                                    <table className="table table-striped table-responsive">
                                        <thead>
                                            <tr>
                                                <th>T/R</th>
                                                <th>Hudud nomi</th>
                                                <th>Tinglovchi soni</th>
                                                <th>Tushgan pul</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.byRegionRows}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </div>
        )
    }
}