import React, {Component} from 'react';
import {logout, pageNotFound} from './Utils';
import GroupListView from "./GroupListView";

export default class ControllerHomeView extends Component {

	render() {
		pageNotFound();
		let user = JSON.parse(localStorage.getItem('user'));
		if (user.roles.includes('CONTROLLER')) {
			logout();
		}
		return (
			<GroupListView/>
		)
	}
}