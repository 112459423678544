import React, {Component} from 'react';
import UserList from "./UserList";
import {pageNotFound} from "./Utils";

export default class AdminHomeView extends Component {

	render() {
		pageNotFound();
		let currentUser = JSON.parse(localStorage.getItem('user'));
		return (
			<div>
				{currentUser.roles.includes('ADMIN') &&
					<div className="container card card-body my-4">
						<UserList/>
					</div>
				}
			</div>
		)
	}
}