import React, {Component} from "react";
import {apiHost} from "./Utils";
import axios from 'axios';
import swal from 'sweetalert';
import Modal from 'react-responsive-modal';

export default class HelpView extends Component {

	constructor() {
		super();
		this.state = {
			modalOpen: false,
			tableRows: []
		}
	}

	componentDidMount() {
		this.loadData();
	}

	loadData() {
		axios.get(apiHost + '/chat/list')
			.then(result=>{
				if (result.status === 200) {
					if (result.data !== undefined && result.data !== null && result.data.length > 0) {
						let rows = result.data.map(row=>{
							return <div className="row" style={{borderBottom: "1px solid black"}}>
								<div className="col-md-12">
									<div className="row">
										<div className="col-md-12">
											<span style={{fontSize: "12px"}}>{row.questionDate}</span>
											<br/>
											<span><b>Savol:&nbsp;</b></span>
											<span className="ml-2">{row.question}</span>
										</div>
										<div className="col-md-12">
											<span style={{fontSize: "12px"}}>{row.answerDate}</span>
											<br/>
											<span><b>Javob:&nbsp;</b>{row.answer}</span>
										</div>
									</div>
								</div>
							</div>
						});
						this.setState({
							tableRows: rows
						})
					}
				}
			})
	}

	openModal() {
		this.setState({
			modalOpen: true
		})
	}

	closeModal() {
		this.setState({
			modalOpen: false
		})
	}

	submitQuestion() {
		let data = new FormData();
		let question = document.getElementById('question').value;
		data.append('question', question);
		axios.post(apiHost + '/chat/submitQuestion', data)
			.then(result=> {
				if (result.status === 200) {
					this.loadData();
					swal(result.data.message, '', 'success');
					this.closeModal();
				} else {
					swal(result.data.message, '', 'error');
				}
			})
	}

	render() {
		return(
			<div className="mb-5">
				<div className="row">
					<div className="col-md-12 mb-3 text-right">
						<button className="btn btn-info" onClick={this.openModal.bind(this)}>Savol berish</button>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						{this.state.tableRows}
					</div>
				</div>
				<Modal open={this.state.modalOpen} onClose={this.closeModal.bind(this)} center>
					<div style={{padding: "20px"}}>
						<div className="form-row">
							<div className="col-md-12"><h5>Markaz mutaxassislariga savol yuborish</h5></div>
						</div>
						<div className="form-row">
							<div className="col-md-12 mt-3">
								<label htmlFor="question">Savol:</label>
								<textarea id="question" className="form-control"/>
							</div>
						</div>
						<div className="form-row">
							<div className="col-md-12 mt-3">
								<button className="btn btn-success ml-1" onClick={this.submitQuestion.bind(this)}>Savol berish</button>
							</div>
						</div>
					</div>
				</Modal>
			</div>
		)
	}
}