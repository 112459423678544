import React, {Component} from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import {apiHost, logout, pageNotFound} from "./Utils";
import authHeader from "./services/auth-header";

export default class EditAgreementPriceView extends Component {

	constructor() {
		super();
		this.state = {
			tendencies: [],
			tendencyList: [],
			tendencyId: 0,
			tendencyName: '',
			price: ''
		};
		this.onChange = this.onChange.bind(this);
	}

	componentDidMount() {
		let urlParams = new URLSearchParams(window.location.search);
		let id = urlParams.get('id');
		if (id !== undefined && id !== null && id !== '' && Number(id) > 0) {
			axios.get(apiHost + '/api/admin/tendency/item?id=' + id, authHeader())
				.then(result => {
					if (result.status === 200) {
						this.setState({
							price: result.data.price,
							tendencyName: result.data.name,
							tendencyId: id
						})
					}
				}).catch(error=>{
				if (error !== undefined && error !== null && error.response.status === 401) {
					logout();
				}
			})
		}
	}

	save() {
		let price = document.getElementById('price').value;
		if (price === null || price === '') {
			swal('Narxini kiriting', '', 'error');
		}
		let data = new FormData();
		data.append('id', this.state.tendencyId);
		data.append('price', price);
		axios.post(apiHost + '/api/admin/tendency/updatePrice', data, authHeader())
			.then(result=>{
				if (result.status === 200) {
					swal(result.data.message, '', 'success');
				} else {
					swal(result.data.message, '', 'error');
				}
			}).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		})
	}

	onChange(e) {
		let name = e.target.id;
		let value = e.target.value;
		this.setState({
			[name]: value
		})
	}

	render() {
		pageNotFound();
		return(
			<div className="content">
				<div className="container card card-body mb-2 shadow-sm">
					<div className="form-row">
						<div className="col-md-6">
							<label htmlFor="tendency">Yo`nalish</label>
							<input id="tendency" className="form-control" value={this.state.tendencyName}/>
						</div>
						<div className="col-md-6">
							<label htmlFor="price">Narxi (1 kishiga)</label>
							<input id="price" className="form-control" defaultValue={this.state.price} onChange={this.onChange}/>
						</div>
					</div>
					<div className="form-row mt-3">
						<div className="col-md-12 text-center">
							<button className="btn btn-success" onClick={this.save.bind(this)}>Saqlash</button>
						</div>
					</div>
				</div>
			</div>
		)
	}
}